import ResponseCodes from '../../common/constants/ResponseCodes';
import { Schema } from 'joi';
import { z } from 'zod';

export function createValidator(schema: Schema) {
  return async function (body: any) {
    try {
      await schema.validateAsync(body, {
        allowUnknown: true,
        abortEarly: false,
        stripUnknown: false
      });
      return { valid: true, error: null };
    } catch (e) {
      //@ts-ignore
      if (!e || !e.details) {
        return {
          valid: false,
          error: {
            code: ResponseCodes.INVALID_REQUEST,
            //@ts-ignore
            message: e && e.message ? e.message : 'Something went wrong'
          }
        };
      }
      //@ts-ignore
      const errorMap = e.details.reduce((eMap: any, detail: any) => {
        eMap[detail.path.join('_')] = detail.message;
        return eMap;
      }, {});

      return {
        valid: false,
        error: {
          code: ResponseCodes.VALIDATION_ERROR,
          validation: errorMap
        }
      };
    }
  };
}

export function createZodValidator<T extends z.ZodTypeAny>(schema: T) {
  return async function (body: any) {
    const validation =  await schema.safeParseAsync(body);
    if (validation.success) {
      return { valid: true, error: null, response: validation.data as z.infer<T> };
    }

    const errorMap = validation.error.errors.reduce((acc, curr) => {
      acc[curr.path.join('_')] = curr.message;
      return acc;
    }, {} as Record<string, string>);

    return {
      valid: false,
      response: null,
      error: {
        code: ResponseCodes.VALIDATION_ERROR,
        validation: errorMap
      }
    };
  };
}

import React from 'react';

interface Props {
  errors?: {
    validation?: {
      [key: string]: string;
    }
  }
  name: string;
  hide?: boolean;
}

export default function ValidationErrorComponent(props: Props) {
  const { errors, name, hide } = props;
  if (!errors || !errors.validation || !errors.validation[name] || hide) {
    return null;
  }
  const validationError = errors.validation[name];

  return (<>{ validationError }</>);
}

import QueryString from 'qs';
import { useLocation } from 'react-router';

export default function useGetLocationQueryParam(param: string, defaultValue: string) {
  const { search } = useLocation();
 
  const allParams = QueryString.parse(search ? search.substr(1): search);

  const value = allParams && allParams[param];

  return value === undefined ? defaultValue : value;
}
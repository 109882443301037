import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useGetAsyncPath from '../../../lib/hooks/useGetAsyncPath';
import { 
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  BarController,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  BarElement,
  BarController,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

function daysInMonth (month: number, year: number) {
  return new Date(year, month, 0).getDate();
}
function getDaysInPreviousMonth(month: number, year: number) {
  if (month == 1) {
    month = 12;
    year = year-1;
  } else {
    month = month -1;
  }
  return daysInMonth(month, year);
}

function AllTimeChart() {
  const pathAllTime = '/admin/workout-stats-all-time?';

  const { value, loading, error} = useGetAsyncPath<{ 
    month: number;
    year: number;
    workouts: number;
    users: number;
    signups: number;
  }[]>(pathAllTime);

  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  if (loading || !value) {
    return <div>loading</div>;
  }

  const yearsAvailable = [...(new Set([...(value.map(d => d.year))])).values()].sort();


  const firstYear = yearsAvailable[0];
  const lastYear = yearsAvailable[yearsAvailable.length - 1];
  const minMonth = Math.min(...value.filter(v => v.year === firstYear).map(v => v.month));
  const maxMonth = Math.max(...value.filter(v => v.year === lastYear).map(v => v.month));

  const chartData: { yearMonth: string, year: number, month: number, workouts: number, users: number, signups: number, totalSignups: number }[] = [];

  let totalSignups = 0;

  for (const year of yearsAvailable) {
    const startMonth = year === firstYear ? minMonth : 1;
    const lastMonth = year === lastYear ? maxMonth : 12;

    for (let month = startMonth; month <= lastMonth; month++) {
      const datapoint = value.find(v => v.month === month && v.year === year);
      const signups = datapoint?.signups || 0;
      totalSignups = totalSignups + signups;

      chartData.push({
        yearMonth: `${MONTHS[month - 1]} ${year}`,
        year: year,
        month: month,
        workouts: datapoint?.workouts || 0,
        users: datapoint?.users || 0,
        signups: signups,
        totalSignups: totalSignups,
      });
    }
  }

  function getPercentageChange(oldNumber: number, newNumber: number){
    const changeInValue = newNumber - oldNumber;
    const percentageChange = (changeInValue / oldNumber) * 100;

    if (percentageChange > 0) {
      return '+' + percentageChange.toFixed(2) + '%';
    } else {
      return percentageChange.toFixed(2) + '%';
    }
  }

  return (
    <>
      <Container>
        <Chart
          style={{ minHeight: 600 }}
          type='bar' 
          options={{
            interaction: {
              intersect: false,
              mode: 'index',
            },
            responsive: true,
            maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
            scales: {
              y: {
                min: 0,
                grid: {
                  color: '#F4F6F9',
                }
              },
              x: {
                grid: {
                  color: '#F4F6F9',
                }
              },
            },
            plugins: {
              legend: {
                position: 'top' as const,
              },
              title: {
                display: true,
                text: 'All time Data',
              },
              tooltip: {
                boxPadding: 6,
                padding: 15, 
                titleFont: {
                  weight: 'bold',
                  size: 14,
                },
                titleMarginBottom: 10, 
                bodySpacing: 6,
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
                    const dataIndex = context.parsed.x;

                    if (context.parsed.y !== null) {

                      if (label == 'Unique users working out') {
                        const signups = context.parsed.y;
                        const totalSignupsThatMonth = chartData[dataIndex].totalSignups;
                        const percentageOfTotalSignups = signups * 100 / totalSignupsThatMonth;
                        label += ': ' + context.parsed.y + ' (' + percentageOfTotalSignups.toFixed(2) + '% of total signups)';
                      } else {
                        label += ': ' + context.parsed.y;
                      }
                    }
                    return label;
                  },
                  afterBody: function(context) {
                    const dataIndex = context[0].parsed.x;

                    const month = chartData[dataIndex].month;
                    const year = chartData[dataIndex].year;
                    let daysInThisMonth = 0;

                    if (dataIndex == chartData.length -1) {
                      daysInThisMonth = new Date().getDate();
                    } else {
                      daysInThisMonth = daysInMonth(month, year);
                    }
                    const daysInPreviousMonth = getDaysInPreviousMonth(month, year);

                    const thisMonthWorkoutsDailyAverage = chartData[dataIndex].workouts / daysInThisMonth;
                    const thisMonthSignupsDailyAverage = chartData[dataIndex].signups / daysInThisMonth;

                    const previousMonthWorkouts = chartData[dataIndex-1]?.workouts || 0;
                    const previousMonthSignups = chartData[dataIndex-1]?.signups || 0;

                    const previousMonthWorkoutsDailyAverage = previousMonthWorkouts / daysInPreviousMonth;
                    const previousMonthSignupsDailyAverage = previousMonthSignups / daysInPreviousMonth;

                    const changeInWorkoutsDailyAverage = getPercentageChange(previousMonthWorkoutsDailyAverage, thisMonthWorkoutsDailyAverage);
                    const changeInSignupsDailyAverage = getPercentageChange(previousMonthSignupsDailyAverage, thisMonthSignupsDailyAverage);

                    const afterBodyText = 
                      'Daily av workouts: ' + thisMonthWorkoutsDailyAverage.toFixed(2) + ' (' + changeInWorkoutsDailyAverage + ' from prev month) \n' + 
                      'Daily av signups: ' + thisMonthSignupsDailyAverage.toFixed(2) + ' (' + changeInSignupsDailyAverage + ' from prev month)';
                    return afterBodyText;
                  }
                }
              }
            }            
          }}
          
          data={{
            
            labels: chartData.map(cd => cd.yearMonth),
            datasets: [
              {
                type: 'line' as const,
                label: 'Workouts',
                borderColor: '#df563d',
                data: chartData.map(cd => cd.workouts)
              },
              {
                type: 'line' as const,
                label: 'Unique users working out',
                borderColor: 'rgb(53, 162, 235)',
                data: chartData.map(cd => cd.users)
              },
              {
                type: 'line' as const,
                label: 'Signups',
                borderColor: '#6dcb0a',
                data: chartData.map(cd => cd.signups)
              },
              {
                type: 'bar' as const,
                label: 'Total signups',
                backgroundColor: 'rgba(109, 203, 10, 0.1)',
                borderRadius: 6,
                data: chartData.map(cd => cd.totalSignups),
              },
            ],
          }}
        />
      </Container>
    </>
  );
}

function YearChart ({ year }: { year: number }) {

  const pathYear = `/admin/workout-stats-year?year=${year}`;

  const { value, loading, error } = useGetAsyncPath<{ 
    month: number;
    workouts: number;
    users: number;
    signups: number;
  }[]>(pathYear);

  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  if (loading || !value) {
    return <div>loading</div>;
  }
  return (
    <Container>

      <Line
        options={{
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            yAxes: {
              min: 0,
            }
          },
          plugins: {
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: `${year} Data`,
            }
          }            
        }}
        data={{
          labels: MONTHS,
          datasets: [
            {
              label: 'Workouts',
              borderColor: '#df563d',
              data: MONTHS.map((month, index) => {
                const monthData = value.find(v => v.month === index + 1);
                return monthData?.workouts || 0;
              })
            },
            {
              label: 'Unique users working out',
              borderColor: 'rgb(53, 162, 235)',
              data: MONTHS.map((month, index) => {
                const monthData = value.find(v => v.month === index + 1);
                return monthData?.users || 0;
              })
            },
            {
              label: 'Signups',
              borderColor: '#6dcb0a',
              data: MONTHS.map((month, index) => {
                const monthData = value.find(v => v.month === index + 1);
                return monthData?.signups || 0;
              })
            },
          ],
        }}
      />
    </Container>
  );
}

function MonthChart ({ year, month }: { year: number; month: number; }) {
  const pathMonth = `/admin/workout-stats-month?year=${year}&month=${month}`;

  const { value, loading, error } = useGetAsyncPath<{ 
    day: number;
    workouts: number;
    users: number;
    signups: number;
  }[]>(pathMonth);

  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  if (loading || !value) {
    return <div>loading</div>;
  }

  const maxDays = month ? daysInMonth(month, year): 30;
  const days = Array.from({length: maxDays}, (_, i) => i + 1);

  return (
    <Container>
      <Line
        options={{
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            yAxes: {
              min: 0,
            }
          },
          plugins: {
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: `${year} ${MONTHS[month - 1]} Data`,
            }
          },
        }}
        data={{
          labels: days,
          datasets: [
            {
              label: 'Workouts',
              borderColor: '#df563d',
              data: days.map((day) => {
                const dayData = value.find(v => v.day === day);
                return dayData?.workouts || 0;
              })
            },
            {
              label: 'Unique users working out',
              borderColor: 'rgb(53, 162, 235)',
              data: days.map((day) => {
                const dayData = value.find(v => v.day === day);
                return dayData?.users || 0;
              })
            },
            {
              label: 'Signups',
              borderColor: '#6dcb0a',
              data: days.map((day) => {
                const dayData = value.find(v => v.day === day);
                return dayData?.signups || 0;
              })
            },
          ],
        }}
      />
    </Container>
  );
}

function WorkoutStatsPage () {
  const [year, setYear] = useState<number>();
  const [month, setMonth] = useState<number>(0);

  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  const previousYear = currentYear - 1;
  const currentMonth = currentTime.getMonth() + 1;
  const previousMonth = currentMonth - 1;

  return (
    <>
      <Container fluid className="hiw-content" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
        <Container className="">
          <h1>Statistics</h1>
          <div className="gray-box">
            <Row>
              <Col>
                <label htmlFor="year">Year</label>
                <input id="year" type="number" defaultValue={year} onBlur={(y) => setYear(+y.target.value)} className="form-control" />
              </Col>
              <Col>
                <label htmlFor="month">Month</label>
                <select id="month" value={month} onChange={(e) => setMonth(+e.target.value)} className="form-control">
                  <option value='0'>none</option>
                  {
                    MONTHS.map((m, index) => (<option key={index} value={index + 1}>{m}</option>))
                  }
                </select>
              </Col>
              <Col xs={12} sm={12} md={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around' }}>
                <a className="btn-text" onClick={() => { setYear(undefined); setMonth(0); }}>All</a>
                <a className="btn-text" onClick={() => { setYear(previousYear); setMonth(0); }}>Previous year</a>
                <a className="btn-text" onClick={() => { setYear(currentYear); setMonth(0); }}>Current year</a>
                <a className="btn-text" onClick={() => { setYear(currentYear); setMonth(previousMonth); } }>Previous month</a>
                <a className="btn-text" onClick={() => { setYear(currentYear); setMonth(currentMonth); } }>Current month</a>
              </Col>
            </Row>
          </div>
        </Container>
        { !year ? (<AllTimeChart />): null }
        { month && year ? (<MonthChart month={month} year={year} />) : null }
        { year ? (<YearChart year={year} />): null}
      </Container>
    </>
  );
}

export default WorkoutStatsPage;

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useBooleanCookie } from '../lib/hooks/useCookie';
import { useIsServer } from '../lib/hooks/useIsServer';

export default function CookieBanner() {
  const [accepted, setAccepted] = useBooleanCookie('accept-cookie', false);
  const isServer = useIsServer();

  if (isServer || accepted) {
    return null;
  }

  return (
    <div className="cookie-overlay">
      <div className="cookie-banner">
        <p className="mb-0">
          We use cookies or similar technologies for technical purposes, as specified in the <Link to="/privacypolicy">Privacy Policy</Link>.
          <br/>
          You can consent by using the “Accept” button.
        </p>
        <Button variant="outline-primary" className="mt-4 btn-block" tabIndex={0} onClick={() => {
          setAccepted(true);
        }}>Accept</Button>
      </div>
    </div>
  );
}
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListQuery } from '../../../common/types';
import { apiAction, buildListQuery, Methods } from '../middleware/api';

export default function useGetAsyncPath<T>(path: string, query?: Partial<ListQuery>): { loading: boolean; value?: T; error: any } | { loading: false; value: T; error: undefined } {
  const dispatch = useDispatch();
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  function getResource() {
    setLoading(true);
    const apiCall = apiAction<T>({
      path: query ? buildListQuery({path, query}): path,
      method: Methods.GET,
      onFailure: (e) => {
        setValue(undefined);
        setError(e);
        setLoading(false);
      },
      onSuccess: (resp) => {
        setValue(resp);
        setLoading(false);
      }
    });

    dispatch(apiCall);
  }

  useEffect(() => {
    getResource();
  }, [path, query]);

  return {
    value,
    error,
    loading
  };
}
import React from 'react';
import { ReactElement } from 'react';
import { Col, Container, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';

function createTooltip({ message }: { message: any; }) {
  return function renderTooltip(props: any) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <Container>
          <Row>
            <Col>{message}</Col>
          </Row>
        </Container>
      </Tooltip>
    );
  };
}

export default function HardyTooltip({ message, children }: { message: string; children: ReactElement; }) {
  return (<OverlayTrigger
    placement="top"
    overlay={createTooltip({ message })}
  >
    { children }
  </OverlayTrigger>);
}
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './fe/App';
import { Provider } from 'react-redux';
import configureStore from './fe/lib/store';

import TagManager from 'react-gtm-module';
import CookieBanner from './fe/components/CookieBanner';

const tagManagerArgs = {
  gtmId: 'GTM-55875B5'
};

TagManager.initialize(tagManagerArgs);

// Grab the state from a global variable injected into the server-generated HTML
// @ts-ignore
const preloadedState = window.__PRELOADED_STATE__ && JSON.parse(window.__PRELOADED_STATE__) || {};

// Allow the passed state to be garbage-collected
// @ts-ignore
delete window.__PRELOADED_STATE__;

const store = configureStore(preloadedState);

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <CookieBanner/>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}

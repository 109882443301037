import React, { SyntheticEvent } from 'react';
import { Button } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../lib/store';
import { SetAutoFocusInput } from '../row-edit/SetRowEditorSwitch';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  onClick: (autoFocus: SetAutoFocusInput) => void;
}

export default function BodyweightTimeSetRow({ workoutIndex, exerciseIndex, setIndex, onClick }: Props) {
  const set = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex]);

  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--clickable" onClick={() => onClick(SetAutoFocusInput.INTENSITY_TIME)}>
      <div className="woe-cus-row routine-woe-sets-row">
        <div className="woe-cus-col-1 text-muted">
          <span className="ml-1">{set.index + 1}</span>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-3-4 text-right" onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          onClick(SetAutoFocusInput.INTENSITY_TIME);
        }
        }>
          <span className="mr-3">
            <span className="clickable-area-indicator">
              <strong className="rep-text">
                {set.intensity_time} sec
              </strong>
            </span>
          </span>
        </div>
        <div className="woe-cus-col-5 text-right" onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          onClick(SetAutoFocusInput.REST_TIME);
        }
        }>
          <span className="mr-3"><span className="clickable-area-indicator">{set.rest_time} sec</span></span>
        </div>
        <div className="woe-cus-col-7 text-right">
          <Button className="btn-icon" variant="outline-secondary">
            <Pencil />
          </Button>
        </div>
      </div>
    </div>
  );
}
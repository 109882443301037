import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FooterComponent from '../footer/Footer';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { publicUrl } from '../../lib/config';
const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;


function AboutPageComponent () {
  const [showingEmail, setShowingEmail] = useState(false);

  return (
    <>
      <Helmet titleTemplate="Hardy - %s">
        <title>About</title>
        <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
        <meta property="og:title" content="Hardy - About" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hardy.app/about" />
        <meta property="og:image" content={socialMediaFullUrl} />
        <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
      </Helmet>

      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col>
              <h1>About Us</h1>
              <p>
                Many of us who have tried to follow gym workout routines with progression rules (like 5/3/1 workout programs) have
                faced the pain of having to manually edit the Excel sheet formulas to change our Training Max numbers.
                Hardy.app was created to make those calculations automatic by letting the app handle it. And much more!
              </p>
              <p>
                Hardy.app was created by <b>Endrik End</b> and <a href="https://www.matude.com" target="_blank" rel="noreferrer"><b>Peedu Tuisk</b></a>.
                If you see anything issues please let us know.
              </p>

              { showingEmail ? (
                <p><a href="mailto:hello@hardy.app">hello@hardy.app</a></p>
              ) : (
                <Button variant="link" className="pl-0 pr-0" onClick={() => setShowingEmail(true)}>
                  Contact email
                </Button>
              )}

              <p className="mt-4">
                <b>Hardy App OÜ</b> <br/>
                Estonia, <br/>
                European Union
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <FooterComponent/>

    </>
  );
}

export default AboutPageComponent;

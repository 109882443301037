import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import FooterComponent from '../footer/Footer';
import './DownloadPage.scss';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import videoHardyAppDemoFull from '../../assets/hardy-app-demo-full.mp4';
import { publicUrl } from '../../lib/config';
import appleStoreBadge from '../../assets/apple-appstore-badge.png';
import googleStoreBadge from '../../assets/google-play-badge.png';

const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

function DownloadPageComponent () {
  return (
    <>
      <Helmet titleTemplate="Hardy - %s">
        <title>Download App</title>
        <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
        <meta property="og:title" content="Hardy - Download App" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hardy.app/download" />
        <meta property="og:image" content={socialMediaFullUrl} />
        <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
      </Helmet>

      <Jumbotron className="download-hero" fluid>
        <Container className="">
          <Row>
            <Col xs={{ order: 2, span: 6, offset: 3}} sm={{ span: 6, offset: 3, order: 2 }} md={{ span: 4, offset: 0, order: 2 }} lg={{ span: 4, offset: 1 }} className="text-center">
              <div className="phone-holder-angled">
                <div className="phone-angled">
                  <div className="phone-shape phone-shape--primary">
                    <div className="video-holder">
                      <video width="728" height="1500" loop autoPlay muted playsInline>
                        <source src={videoHardyAppDemoFull} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={{ order: 1, span: 10, offset: 1}} sm={{ span: 10, offset: 1, order: 1 }} md={{ span: 8, offset: 0, order: 1 }} lg={{ span: 6, offset: 0 }} className="align-self-center">
              <h1 className="content-title mt-4">Download Hardy app & start working out now</h1>
              <p className="lead">
                All who join now get a free lifetime membership.<br/>
                Available for Apple and Android.<br/>
                Please click below to download on your platform.
              </p>

              <div className="d-inline-block mr-3 mt-5">
                <a className="appstore-img appstore-img--apple mr-3 mt-2 mb-3" href="https://apps.apple.com/app/hardy-smart-workout-routines/id1554129445" target="_blank" rel="noreferrer"><img src={appleStoreBadge} alt="Download Hardy weight lifting app for iPhone on Apple App Store" /></a>
              </div>
              <div className="d-inline-block mt-5">
                <a className="appstore-img appstore-img--google mt-2 mb-2" href="https://play.google.com/store/apps/details?id=com.hardyapp.hardy" target="_blank" rel="noreferrer"><img src={googleStoreBadge} alt="Download Hardy weight lifting app for iPhone on Google App Store" /></a>
              </div>

            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <FooterComponent/>
    </>
  );
}

export default DownloadPageComponent;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import WorkoutList from './workout/WorkoutList';
import RoutineBasicInfoEditor from './RoutineBasicInfoEditor';

export default function RoutineEditor() {
  return (
    <div style={{ minHeight: 'calc(100vh - 302.54px)' }}>
      <Container className="content-area mt-5">
        <RoutineBasicInfoEditor />
      </Container>

      <Container className="mb-5">
        <Row>
          <Col md={12} lg={8}>
            <WorkoutList />
          </Col>
        </Row>
      </Container>
    </div>);
}

import React, { useContext, createContext, ReactNode } from 'react';

interface Exercise1Rms {
  [key: string]: number;
}
export const PreviewExercise1RmContext = createContext<Exercise1Rms>({});

export function usePreviewExercise1Rms () {
  return useContext(PreviewExercise1RmContext);
}

export function PreviewExercise1RmContextProvider ({children, exercise1Rms }: { children: ReactNode, exercise1Rms: Exercise1Rms }) {
  return (
    <PreviewExercise1RmContext.Provider value={exercise1Rms}>
      {children}
    </PreviewExercise1RmContext.Provider>
  );
}
import { RouteRoutinePostBody, RoutineModel } from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import RoutineService from '../services/routine.service';
import editorSlice from './routine-editor';
export interface RoutineCreateState {
    data?: RoutineModel;
    loading: boolean;
    error?: any;
}

const initialState: RoutineCreateState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'routineCreate',
  initialState,
  reducers: {
    createRoutinePending (state) {
      state.loading = true;
    },
    createRoutineFulfilled (state, action: PayloadAction<RoutineModel>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    createRoutineRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreateRoutine (state) {
      state.data = undefined;
      state.error = undefined;
    }
  },
});

export function createRoutine(body: RouteRoutinePostBody) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.createRoutinePending());
    const action = RoutineService.createAction({ body }, {
      onSuccess: (r) => {
        dispatch(slice.actions.createRoutineFulfilled(r));
        dispatch(editorSlice.actions.resetEditor());
      },
      onFailure: (e) => {dispatch(slice.actions.createRoutineRejected(e));}
    });

    dispatch(action);
  };
}

export default slice;

import React from 'react';
import iconApple from '../../assets/icon-apple-primary.svg';
import iconGooglePlay from '../../assets/icon-google-play-primary.svg';
import iconReddit from '../../assets/icon-reddit-primary.svg';
import { StarFill, StarHalf } from 'react-bootstrap-icons';

export default function TestimonialBox({ stars, title, text, name, platform, date }: { 
  stars?: number;
  title?: string;
  text?: string;
  name?: string;
  platform?: string;
  date?: string;
}) {
  
  return (
    <div className="testimonial-box">
      { stars && (
        <div className="testimonial-stars">
          { stars >= 4 && (
            <>
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
            </>
          )}
          { stars === 4.5 && (
            <StarHalf />
          )}
          { stars === 5 && (
            <StarFill />
          )}
        </div>
      )}
      { title && (
        <h4>{title}</h4>
      )}
      { text && (
        <p className="testimonial-text">
          {text}
        </p>
      )}
      { name && (
        <p className="testimonial-name">
          {name}
          {platform && ` on ${platform}`}
          { platform === 'Apple App Store' ? (<img src={iconApple} alt="" />) :
            platform === 'Reddit' ? (<img src={iconReddit} alt="" />) :
              platform === 'Google Play Store' ? (<img src={iconGooglePlay} alt="" />) : null
          }
        </p>
      )}
      { date && (
        <p className="testimonial-date">{date}</p>
      )}
    </div>
  );
}
import React from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap';

type Props = {
  startCustomizeRoutine: () => void;
  handleDuplicateRoutineModalClose: () => void;
  showDuplicateRoutineModal: boolean;
};

export default function DuplicateRoutineModal({ startCustomizeRoutine, handleDuplicateRoutineModalClose, showDuplicateRoutineModal }: Props) {
  return (
    <Modal className="duplicateroutine-modal" show={showDuplicateRoutineModal} onHide={handleDuplicateRoutineModalClose} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title>Make a private copy & edit this routine</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pl-3 pr-3 pt-2 pb-1">
          <p>
            You are about to make a private copy of this routine that is only usable by you.
            You will be able to edit that new routine as you wish, but to share with others you need to change the routine as public.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleDuplicateRoutineModalClose} style={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <Button variant="primary" onClick={startCustomizeRoutine}>
          Yes, make a copy
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

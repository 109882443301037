import React from 'react';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { WeightSetType, WorkoutExerciseSetCreateData, WorkoutExerciseType } from '../../../../common/types';
import slice from '../../../lib/slices/routine-editor';
import { RootState } from '../../../lib/store';
import { SetAutoFocusInput } from './row-edit/SetRowEditorSwitch';


interface AddSetButtonsProps {
  workoutIndex: number;
  exerciseIndex: number;  
  setEditIndex: (v: number) => void;
  setEditAutoFocus: (v: SetAutoFocusInput) => void;
}

interface AddSetButtonProps {
  onClick: () => void;
  isNotAllowed: boolean;
  label: string;
}

function AddSetButton({onClick, isNotAllowed, label}: AddSetButtonProps) {
  return (
    <div className="routine-woe-sets-row-holder">
      <div className="woe-cus-row routine-woe-sets-row ml-0 mr-0">
        <Button className="btn-add-row btn-add-sm"
          disabled={isNotAllowed}
          onClick={onClick}
        >
          <span className="add-plus"><Plus /></span> {label}
        </Button>
      </div>
    </div>
  );
}


export default function AddSetButtons({ workoutIndex, exerciseIndex, setEditIndex, setEditAutoFocus }: AddSetButtonsProps) {
  const dispatch = useDispatch();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const isNotAllowed = !workoutExercise.exercise_id;

  function onAddExerciseSet(setData: Partial<WorkoutExerciseSetCreateData>) {
    dispatch(slice.actions.addSet({ workoutIndex, exerciseIndex, setData }));
    setEditIndex(workoutExercise.sets.length);
  }

  const buttonProps = {
    bodyweightTime: {
      isNotAllowed,
      onClick: () => {
        onAddExerciseSet({
          intensity_time: 30,
          intensity_weight_tm_percentage: undefined,
          intensity_weight_1rm_percentage: undefined,
          weight_set_type: undefined,
          intensity_rpe: undefined,
          amrap_overload_rules: undefined
        });
        setEditAutoFocus(SetAutoFocusInput.INTENSITY_TIME);
      },
      label: 'Add Set'
    },
    bodyweightRep: {
      isNotAllowed,
      onClick: () => {
        onAddExerciseSet({
          intensity_time: undefined,
          intensity_weight_tm_percentage: undefined,
          intensity_weight_1rm_percentage: undefined,
          intensity_rpe: undefined,
          weight_set_type: undefined,
          amrap_overload_rules: undefined
        });
        setEditAutoFocus(SetAutoFocusInput.REPETITIONS);
      },
      label: 'Add Set'
    },
    weightNoSuggestions: {
      isNotAllowed,
      onClick: () => {
        onAddExerciseSet({
          weight_set_type: WeightSetType.NO_SUGGESTIONS,
          intensity_time: undefined,
          intensity_weight_tm_percentage: undefined,
          intensity_weight_1rm_percentage: undefined,
          intensity_rpe: undefined,
          amrap_overload_rules: undefined
        });
        setEditAutoFocus(SetAutoFocusInput.REPETITIONS);
      },
      label: 'Add Set'
    },
    weightTm: {
      isNotAllowed,
      onClick: () => {
        onAddExerciseSet({
          intensity_weight_tm_percentage: 65,
          weight_set_type: WeightSetType.TM,
          intensity_time: undefined,
          intensity_weight_1rm_percentage: undefined,
          intensity_rpe: undefined,
          amrap_overload_rules: undefined
        });
        setEditAutoFocus(SetAutoFocusInput.TM_PERCENTAGE);
      },
      label: 'Add TM% intensity Set'
    },
    weight1Rm: {
      isNotAllowed,
      onClick: () => {
        onAddExerciseSet({
          intensity_weight_1rm_percentage: 55,
          weight_set_type: WeightSetType._1RM,
          intensity_time: undefined,
          intensity_weight_tm_percentage: undefined,
          intensity_rpe: undefined,
          amrap_overload_rules: undefined
        });
        setEditAutoFocus(SetAutoFocusInput._1RM_PERCENTAGE);
      },
      label: 'Add 1RM% intensity Set'
    },
    weightRpe: {
      isNotAllowed,
      onClick: () => {
        onAddExerciseSet({
          intensity_rpe: 7,
          weight_set_type: WeightSetType.RPE,
          intensity_time: undefined,
          intensity_weight_tm_percentage: undefined,
          intensity_weight_1rm_percentage: undefined,
          amrap_overload_rules: undefined
        });
        setEditAutoFocus(SetAutoFocusInput.RPE);
      },
      label: 'Add RPE intensity Set'
    }
  };

  if (workoutExercise.workout_exercise_type === WorkoutExerciseType.BODYWEIGHT_TIME) {
    return (<AddSetButton {...buttonProps.bodyweightTime} />);
  }

  if (workoutExercise.workout_exercise_type === WorkoutExerciseType.BODYWEIGHT_REP) {
    return (<AddSetButton {...buttonProps.bodyweightRep} />);
  }

  return (
    <>
      <AddSetButton {...buttonProps.weightNoSuggestions} />
      <AddSetButton {...buttonProps.weightTm} />
      <AddSetButton {...buttonProps.weight1Rm} />
      <AddSetButton {...buttonProps.weightRpe} />
    </>
  );
}
import React, { useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import useGetAsyncPath from '../../../lib/hooks/useGetAsyncPath';
import DatePicker from 'react-datepicker';

function WorkoutArchivePage () {
  const _7DaysAgo = new Date();
  _7DaysAgo.setDate(_7DaysAgo.getDate() - 7);
  const [startDate, setStartDate] = useState<Date>(_7DaysAgo);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const onChange = (dates: [Date, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  let path = '/admin/growth?';

  if (startDate) {
    path += `start=${startDate.getTime()}&`;
  }

  if (endDate) {
    path += `end=${endDate.getTime()}`;
  }

  const { value, loading, error } = useGetAsyncPath<{ 
    signups: number;
    workoutsForDistinctUsers: number;
    workoutsForSignups: number;
  }>(path);

  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  if (loading || !value) {
    return <div>loading</div>;
  }

  return (
    <>
      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>New signups</th>
                <th>New signups who worked out</th>
                <th>Distinct users who worked out</th>
              </tr>

              <tr>
                <td>{value.signups}</td>
                <td>{value.workoutsForSignups}</td>
                <td>{value.workoutsForDistinctUsers}</td>
              </tr>
            </thead>
          </Table>
        </Container>
      </Container>
    </>
  );
}

export default WorkoutArchivePage;

import React, { useState } from 'react';
import EditableText from '../../../form/EditableText';
import HardyExerciseDropdownComponent from '../../../form/HardyExerciseDropdown';
import { Col, Form, Row, Button } from 'react-bootstrap';

import { Pencil, GraphDown, GraphUp } from 'react-bootstrap-icons';
import slice from '../../../../lib/slices/routine-editor';
import { doesExerciseUseWeight, getWeightStep } from '../../../weight/Weight';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../lib/store';
import useRoutineEditorError from '../../../../lib/hooks/useRoutineEditorError';
import useUnits from '../../../../lib/hooks/useUnits';
import { hasValidationError } from '../../../../helpers/form';
import DeloadRule from '../DeloadRule';
import OverloadRule from '../OverloadRule';
import SetsEditor from '../../set/SetsEditor';
import { DeloadType, ExerciseData, OverloadType, WorkoutExerciseDeloadRule, WorkoutExerciseOverloadRule } from '../../../../../common/types';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

const labelCol = {
  xs: 12,
  sm: 3,
  md: 2
};

function OverLoadDeloadButtons({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);
  const tmExercise = useSelector((state: RootState) => workoutExercise.intensity_weight_tm_percentage_exercise_id ? state.exerciseCacheState.cacheMap[workoutExercise.intensity_weight_tm_percentage_exercise_id] : undefined);
  const units = useUnits();
  const anyTmSet = workoutExercise.sets.some((s) => typeof s.intensity_weight_tm_percentage === 'number');
  const any1RmSet = workoutExercise.sets.some((s) => typeof s.intensity_weight_1rm_percentage === 'number');

  const newDeloadTm = {
    deload_type: DeloadType.TM,
    tm_deload_percentage: 10,
    deload_percentage_1rm: undefined,
    failed_sessions_trigger_number: 1
  };

  const newDeload1Rm = {
    deload_type: DeloadType._1RM,
    tm_deload_percentage: undefined,
    deload_percentage_1rm: 10,
    failed_sessions_trigger_number: 1
  };
  //@ts-ignore
  const newDeload: WorkoutExerciseDeloadRule = anyTmSet ? newDeloadTm : newDeload1Rm;

  const initialOverloadAmount = tmExercise && anyTmSet
    ? getWeightStep(units, tmExercise.type)
    : any1RmSet
      ? getWeightStep(units, exercise.type)
      : 2500;

  const newOverloadTm = {
    overload_type: OverloadType.TM,
    successful_sessions_trigger_number: 1,
    tm_overload_amount: initialOverloadAmount,
    overload_amount_1rm: undefined
  };

  const newOverload1Rm = {
    overload_type: OverloadType._1RM,
    successful_sessions_trigger_number: 1,
    tm_overload_amount: undefined,
    overload_amount_1rm: initialOverloadAmount
  };
  //@ts-ignore
  const newOverload: WorkoutExerciseOverloadRule = anyTmSet ? newOverloadTm : newOverload1Rm;

  return (<>
    {workoutExercise.deload_rule ? (
      <DeloadRule workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
    ) : (
      <Row className="routine-woe-sets" noGutters>
        <Col>
          <div className="routine-woe-sets-row-holder">
            <div className="woe-cus-row routine-woe-sets-row ml-0 mr-0">
              <Button className="btn-add-row btn-add-sm" onClick={() => dispatch(slice.actions.updateExercise({
                workoutIndex, exerciseIndex, data: { deload_rule: newDeload }
              }))}>
                <div className="icon-deload"><GraphDown /></div> Add Deload
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    )}

    {workoutExercise.overload_rule ? (
      <OverloadRule workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
    ) : (
      <Row className="routine-woe-sets" noGutters>
        <Col>
          <div className="routine-woe-sets-row-holder">
            <div className="woe-cus-row routine-woe-sets-row ml-0 mr-0">
              <Button className="btn-add-row btn-add-sm" onClick={() => dispatch(slice.actions.updateExercise({
                workoutIndex, exerciseIndex, data: {
                  overload_rule: newOverload
                }
              }))}>
                <div className="icon-overload"><GraphUp /></div> Add Overload
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    )}
  </>);
}

export default function WeightBody({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}`;
  const [exerciseTypeCancelEdit, setExerciseTypeCancelEdit] = useState(false);

  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);
  const tmExercise = useSelector((state: RootState) => workoutExercise.intensity_weight_tm_percentage_exercise_id ? state.exerciseCacheState.cacheMap[workoutExercise.intensity_weight_tm_percentage_exercise_id] : undefined);

  if (!exercise) {
    return null;
  }

  const noTmSets = !workoutExercise.sets.some((s) => !!s.intensity_weight_tm_percentage);

  return (
    <>
      <SetsEditor workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
      {(noTmSets || !tmExercise) ? null : (
        <div>
          <EditableText forceCancel={exerciseTypeCancelEdit} edittingChanged={() => setExerciseTypeCancelEdit(false)} >
            <Row className="routine-woe-sets" noGutters>
              <Col>
                <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--edit">
                  <Row className="routine-woe-sets-row" noGutters>
                    <Form.Label column {...labelCol}>
                      TM basis:
                    </Form.Label>
                    <Col xs={12} sm={9} md={10}>
                      <HardyExerciseDropdownComponent
                        name={`${validationPrefix}_intensity_weight_tm_percentage_exercise_id`}
                        errors={errors}
                        formCtrl={{
                          name: `${validationPrefix}_intensity_weight_tm_percentage_exercise_id`,
                          defaultValue: {
                            value: workoutExercise.intensity_weight_tm_percentage_exercise_id || workoutExercise.exercise_id,
                            label: tmExercise?.name || exercise.name
                          },
                          isError: hasValidationError(`${validationPrefix}_intensity_weight_tm_percentage_exercise_id`, errors),
                          onSelected: (st: ExerciseData) => {
                            dispatch(slice.actions.updateExercise({
                              workoutIndex,
                              exerciseIndex,
                              data: {
                                intensity_weight_tm_percentage_exercise_id: st.id
                              }
                            }));
                          },
                          autoFocus: true,
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="routine-woe-sets" noGutters>
              <Col>
                <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--clickable">
                  <Row className="routine-woe-sets-row" noGutters>
                    <Col xs={11} className="align-self-center">
                      <p className="mb-0">* Training Max (TM) is based on <b className="">{tmExercise?.name}</b>.</p>
                    </Col>
                    <Col xs={1} className="text-right">
                      <Button className="btn-icon" variant="outline-secondary">
                        <Pencil />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </EditableText>
        </div>
      )}
      {doesExerciseUseWeight(exercise.type) ? (<OverLoadDeloadButtons workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />) : null}
    </>
  );
}
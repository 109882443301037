import {
  RouteAuthCompleteSignupInput,
  RouteAuthCompleteSignupOutput,
  UserData
} from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import AuthService from '../services/auth.service';
import authSlice from './auth';

export interface CompleteSignupState {
    data?: UserData;
    loading: boolean;
    error?: any;
}

const initialState: CompleteSignupState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'completeSignup',
  initialState,
  reducers: {
    completeSignupPending (state) {
      state.loading = true;
    },
    completeSignupFulfilled (state, action: PayloadAction<RouteAuthCompleteSignupOutput>) {
      state.loading = false;
      state.data = action.payload.data;
      state.error = undefined;
    },
    completeSignupRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.data = undefined;
      state.error = action.payload;
    },
    resetCompleteSignup (state) {
      state.data = undefined;
      state.error = undefined;
    }
  },
});

export function completeSignup(body: RouteAuthCompleteSignupInput) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.completeSignupPending());
    const action = AuthService.completeSignupAction({ body }, {
      onSuccess: (r) => {
        dispatch(authSlice.actions.setUser(r));
        dispatch(slice.actions.completeSignupFulfilled(r));
      },
      onFailure: (e) => {dispatch(slice.actions.completeSignupRejected(e));}
    });
    dispatch(action);
  };
}

export default slice;

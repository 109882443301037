import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { updateFullRoutine } from '../../lib/slices/routine.update-full';
import { Helmet } from 'react-helmet';
import RoutinePreviewComponent from '../preview/RoutinePreviewComponent';
import RoutineEditorComponent from '../workout-editor/RoutineEditor';
import { RootState } from '../../lib/store';
import routineOneSlice, { getFullRoutine } from '../../lib/slices/routine.one-full';
import routineUpdateSlice from '../../lib/slices/routine.update-full';
import slice from '../../lib/slices/routine-editor';
import useRoutineEditorError from '../../lib/hooks/useRoutineEditorError';
import { stripHtml } from '../../helpers/form';
import Loading from '../Loading';
import useListenEditorError from '../../lib/hooks/useListenEditorError';
import useEditorLocalStorage from '../../lib/hooks/useEditorLocalStorage';

function RoutineEditPageComponent () {
  const routeParams = useRouteMatch<{id: string;}>();
  const routineId = routeParams.params.id;
  const dispatch = useDispatch();
  const updateRequestState = useSelector((state: RootState) => state.routineUpdateFullState);
  const oneRoutineState = useSelector((state: RootState) => state.routineOneFullState);
  const editor = useSelector((state: RootState) => state.routineEditorState);
  const user = useSelector((state: RootState) => state.authState.data);
  const errors = useRoutineEditorError();
  console.log('editor errors', errors);
  const history = useHistory();
  useListenEditorError();
  useEditorLocalStorage();
  const numericRoutineId = parseInt(routineId.split('-').pop() || '');
  useEffect(() => {
    if (!oneRoutineState.data || oneRoutineState.data.id !== numericRoutineId) {
      dispatch(getFullRoutine(routineId));
    }

    return () => {
      dispatch(routineOneSlice.actions.resetRoutineOne());
      dispatch(routineUpdateSlice.actions.resetUpdateFullRoutine());
    };
  }, [numericRoutineId]);

  useEffect(() => {
    if (updateRequestState.data) {
      dispatch(slice.actions.resetEditor());
      history.push(`/routine/${routineId}`);
    }
  }, [updateRequestState]);

  useEffect(() => {
    if (oneRoutineState.data) {
      //@ts-ignore
      dispatch(slice.actions.setEditorState(oneRoutineState.data));
    }
  }, [oneRoutineState.data]);

  if (oneRoutineState.loading) {
    return <div className="page-loading"><Spinner animation="grow" /></div>;
  }

  function dispatchEditRoutine() {
    const description = editor.createData.description;
    dispatch(updateFullRoutine(
      routineId,
      {
        ...editor.createData,
        description: description ? stripHtml(description): description
      }
    ));
  }

  const resetEditor = () => {
    dispatch(slice.actions.resetEditor());
    history.push(`/routine/${routineId}`);
  };

  if (updateRequestState.loading) {
    return <Loading />;
  }

  if (editor.previewMode) {
    return (
      <>
        <RoutinePreviewComponent
          //@ts-ignore
          routineData={editor.createData} user={user} onSave={() => dispatchEditRoutine()} editting={true}/>

        <Container fluid className="routine-header">
          <Container>
            <Row>
              <Col className="text-left">
                <Button variant="outline-danger" onClick={() => resetEditor()} className="mr-3">Discard</Button>
                <Button variant="primary" onClick={() => dispatch(slice.actions.togglePreviewMode())}>Continue editing</Button>
              </Col>

              <Col className="text-right">
                <Button variant="primary" onClick={() => dispatchEditRoutine()}>Save</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }

  return (
    <div>
      <Helmet
        titleTemplate="Hardy - %s"
      >
        <title>Create new Routine</title>
        <meta name={'description'} content={'Create new Routine'} />
      </Helmet>

      <Container fluid className="routine-header">
        <Container>
          <Row>
            <Col>
              <h2 className="caps-title mb-0">Edit a Routine</h2>
            </Col>

            <Col className="text-right">
              <Button variant="outline-danger" onClick={() => resetEditor()} className="mr-3">Discard</Button>
              <Button variant="primary" disabled={!!errors} onClick={() => dispatch(slice.actions.togglePreviewMode())}>Preview</Button>
            </Col>
          </Row>
        </Container>
      </Container>

      <RoutineEditorComponent />

      <Container fluid className="routine-header">
        <Container>
          <Row>
            <Col>
            </Col>

            <Col className="text-right">
              <Button variant="outline-danger" onClick={() => resetEditor()} className="mr-3">Discard</Button>
              <Button variant="primary" disabled={!!errors} onClick={() => dispatch(slice.actions.togglePreviewMode())}>Preview</Button>
            </Col>
          </Row>
        </Container>
      </Container>

    </div>
  );
}

export default RoutineEditPageComponent;

import React, { useState } from 'react';
import { Col, FormControlProps, Row } from 'react-bootstrap';
import ValidationErrorComponent from './ValidationError';
import ValidationFormControlComponent from './ValidationFormControl';

interface Props {
  errors?: { validation?: { [key: string]: string; } }
  name: string;
  formCtrl: FormControlProps & { selectOnFocus?: boolean } & any
  hideError?: boolean;
}

export default function HardyInputComponent({ errors, name, hideError, formCtrl }: Props) {
  const [touched, setTouched] = useState(false);
  const { selectOnFocus, ...localFormCtrl } = formCtrl;

  if (selectOnFocus) {
    localFormCtrl.onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
    };
  }

  const changeFunc = localFormCtrl.onChange;
  localFormCtrl.onChange = (input: any) => {
    setTouched(true);
    changeFunc(input);
  };

  return (<>
    <ValidationFormControlComponent name={name} formCtrl={localFormCtrl} errors={errors} hideError={!touched} />

    {
      touched && !hideError ?
        (<Row>
          <Col xs={12} className={'validation-error-message'}>
            <ValidationErrorComponent name={name} errors={errors} />
          </Col>
        </Row>)
        : null
    }
  </>);
}

import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ChevronDown, Trash } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ExerciseData, WorkoutExerciseType } from '../../../../../common/types';
import { hasValidationError } from '../../../../helpers/form';
import { determineWorkoutExerciseType } from '../../../../helpers/workout-exercise-type';
import useRoutineEditorError from '../../../../lib/hooks/useRoutineEditorError';
import slice from '../../../../lib/slices/routine-editor';
import { RootState } from '../../../../lib/store';
import HardyExerciseDropdownComponent from '../../../form/HardyExerciseDropdown';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setExerciseCancelEdit: (cancelEdit: boolean) => void
}

export default function WorkoutExerciseSelectExpanded({ workoutIndex, exerciseIndex, setExerciseCancelEdit }: Props) {
  const dispatch = useDispatch();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}`;
  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);

  if (!exercise) {
    return null;
  }

  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--edit">
      <Row className="routine-woe-sets-row" noGutters>
        <Col xs={10} className="align-items-center d-flex">
          <ChevronDown className="woe-chevron" />
          <div className={'icon-exercise icon-exercise--' + exercise.type}></div>

          <HardyExerciseDropdownComponent
            name={`${validationPrefix}_exercise_id`}
            errors={errors}
            formCtrl={{
              defaultValue: {
                value: workoutExercise.exercise_id,
                label: exercise.name
              },
              name: `${validationPrefix}_exercise_id`,
              isError: hasValidationError(`${validationPrefix}_exercise_id`, errors),
              onSelected: (exerciseResult: ExerciseData) => {
                const newExerciseType = determineWorkoutExerciseType({[exerciseResult.id]: exerciseResult}, exerciseResult.id);
                dispatch(slice.actions.updateExercise({
                  workoutIndex,
                  exerciseIndex,
                  data: {
                    exercise_id: exerciseResult.id,
                    workout_exercise_type: newExerciseType,
                    intensity_weight_tm_percentage_exercise_id: newExerciseType === WorkoutExerciseType.WEIGHT ? exerciseResult.id : undefined
                  }
                }));
                setExerciseCancelEdit(true);
              },
              autoFocus: true,
            }}
          />
        </Col>

        <Col xs={'auto'} className="text-right ml-auto align-items-center d-flex justify-content-end">
          <Button variant="primary" className="btn-icon" onClick={() => dispatch(slice.actions.removeExercise({ workoutIndex, exerciseIndex }))} title="Remove Workout Exercise"><Trash /></Button>
        </Col>
      </Row>
    </div>
  );
}
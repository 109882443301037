import { AmrapOverLoadRuleSchema, RoutineFullUpdateData, RoutineUpdateData, WeightSetType, WorkoutExerciseSetCreateData, WorkoutExerciseType, WorkoutExerciseUpdateRequestData, WorkoutUpdateRequestData } from '../../../common/types/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import routine3x3 from '../routine-templates/3x3.json';
import routine5x5 from '../routine-templates/5x5.json';
import routinePpl from '../routine-templates/ppl.json';
import routine531 from '../routine-templates/531.json';
import { resetEditorLocalStorage } from '../hooks/useEditorLocalStorage';

export enum RoutineTemplate {
    BLANK = 'blank',
    _3X3 = '3x3',
    _5X5 = '5x5',
    PPL = 'ppl',
    _531 = '531',
}
interface RoutineEditorData {
    previewMode: boolean;
    template?: RoutineTemplate,
    errors: {[key: string]: string};
    createData: RoutineFullUpdateData & { id?: number; };
}

const initialState: RoutineEditorData = {
  errors: {},
  previewMode: false,
  template: undefined,
  createData: {
    name: '',
    description: '',
    tm_percent_from_1rm: 100,
    max_weeks: 12,
    workouts: []
  }
};

const defaultWeightNoSuggestionsSet: Omit<WorkoutExerciseSetCreateData, 'index'> = {
  weight_set_type: WeightSetType.NO_SUGGESTIONS,
  intensity_repetitions: 5,
  amrap: false,
  rest_time: 100
};

const defaultBodyweightTimeSet: Omit<WorkoutExerciseSetCreateData, 'index'> = {
  intensity_time: 30,
  amrap: false,
  rest_time: 100
};

const defaultBodyweightRepSet: Omit<WorkoutExerciseSetCreateData, 'index'> = {
  intensity_repetitions: 10,
  amrap: false,
  rest_time: 100
};

const defaultExercise: WorkoutExerciseUpdateRequestData = {
  exercise_id: 1,
  index: 0,
  intensity_weight_tm_percentage_exercise_id: 1,
  workout_exercise_type: WorkoutExerciseType.WEIGHT,
  deload_rule: undefined,
  overload_rule: undefined,
  sets: [
    { ...defaultWeightNoSuggestionsSet, index: 0 }
  ]
};

const defaultSetMap = {
  [WorkoutExerciseType.WEIGHT]: defaultWeightNoSuggestionsSet,
  [WorkoutExerciseType.BODYWEIGHT_TIME]: defaultBodyweightTimeSet,
  [WorkoutExerciseType.BODYWEIGHT_REP]: defaultBodyweightRepSet,
};

const slice = createSlice({
  name: 'routineEditor',
  initialState,
  reducers: {
    updateBasicInfo(state, action: PayloadAction<Partial<RoutineUpdateData>>) {
      state.createData = {
        ...state.createData,
        ...action.payload
      };
    },
    addWorkout(state) {
      state.createData.workouts.push({
        name: `Workout ${state.createData.workouts.length + 1}`,
        workoutExercises: [defaultExercise],
        index: state.createData.workouts.length
      });
    },
    updateWorkout(state, action: PayloadAction<{workoutIndex: number; data: Partial<WorkoutUpdateRequestData>}>) {
      state.createData.workouts[action.payload.workoutIndex] = {
        ...state.createData.workouts[action.payload.workoutIndex],
        ...action.payload.data
      };
    },
    moveWorkout(state, action: PayloadAction<{
      workoutIndex: number;
      newIndex: number;
    }>) {

      const workouts = state.createData.workouts;
      const workout = workouts[action.payload.workoutIndex];
      workouts.splice(action.payload.workoutIndex, 1);
      workouts.splice(action.payload.newIndex, 0, workout);

      workouts.forEach((workout, index) => {
        workout.index = index;
      });
    },
    removeWorkout(state, action: PayloadAction<{workoutIndex: number;}>) {
      state.createData.workouts = state.createData.workouts.filter((_, index) => action.payload.workoutIndex !== index );
      state.createData.workouts.forEach((w, index) => {
        w.index = index;
      });
    },

    addExercise(state, action: PayloadAction<{workoutIndex: number;}>) {
      state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises.push({
          ...defaultExercise,
          index: state.createData.workouts[action.payload.workoutIndex].workoutExercises.length
        });
    },
    updateExercise(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; data: Partial<WorkoutExerciseUpdateRequestData>}>) {
      const woe = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex];

      if (action.payload.data.workout_exercise_type && action.payload.data.workout_exercise_type !== woe.workout_exercise_type) {
        woe.sets = [{ ...defaultSetMap[action.payload.data.workout_exercise_type], index: 0 }];
      }

      state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex] = {
          ...woe,
          ...action.payload.data
        };
    },
    removeExercise(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number;}>) {
      const workout = state.createData.workouts[action.payload.workoutIndex];
      workout.workoutExercises = workout.workoutExercises.filter((_, index) => index !== action.payload.exerciseIndex);
      workout.workoutExercises.forEach((woe, index) => woe.index = index);
    },
    moveExercise(state, action: PayloadAction<{
      workoutIndex: number;
      exerciseIndex: number;
      newIndex: number;
    }>) {
      const exercises = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises;
      
      const exercise = exercises[action.payload.exerciseIndex];
      exercises.splice(action.payload.exerciseIndex, 1);
      exercises.splice(action.payload.newIndex, 0, exercise);

      exercises.forEach((exercise, index) => {
        exercise.index = index;
      });
    },
    addSet(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; setData: Partial<WorkoutExerciseSetCreateData>}>) {
      const setsArr = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex]
        .sets;

      if (setsArr.length) {
        const {id, ...data} = setsArr[setsArr.length - 1];
        setsArr.push({...data, ...action.payload.setData, index: setsArr.length});
        return;
      }

      setsArr.push({ ...defaultWeightNoSuggestionsSet, index: setsArr.length });
    },
    updateSet(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; setIndex: number; data: Partial<WorkoutExerciseSetCreateData>}>) {
      const exercise = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex];

      exercise.sets[action.payload.setIndex] = {
        ...exercise.sets[action.payload.setIndex],
        ...action.payload.data,
      };

    },
    removeSet(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; setIndex: number;}>) {
      const exercise = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex];

      exercise.sets = exercise.sets.filter((_, index) => index !== action.payload.setIndex);
      exercise.sets.forEach((set, index) => {
        set.index = index;
      });
    },

    moveSet(state, action: PayloadAction<{
      workoutIndex: number;
      exerciseIndex: number;
      setIndex: number;
      newIndex: number;
    }>) {
      const exercise = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex];

      const set = exercise.sets[action.payload.setIndex];
      exercise.sets.splice(action.payload.setIndex, 1);
      exercise.sets.splice(action.payload.newIndex, 0, set);

      exercise.sets.forEach((set, index) => {
        set.index = index;
      });
    },

    addAmrapRule(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; setIndex: number; data: AmrapOverLoadRuleSchema}>) {
      const set = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex]
        .sets[action.payload.setIndex];

      if (set.amrap_overload_rules) {
        set.amrap_overload_rules.push(action.payload.data);
      } else {
        set.amrap_overload_rules = [action.payload.data];
      }
    },
    updateAmrapRule(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; setIndex: number; amrapRuleIndex: number; data: Partial<AmrapOverLoadRuleSchema>}>) {
      const set = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex]
        .sets[action.payload.setIndex];

      if (set.amrap_overload_rules) {
        set.amrap_overload_rules[action.payload.amrapRuleIndex] = {
          ...set.amrap_overload_rules[action.payload.amrapRuleIndex],
          ...action.payload.data
        };
      }
    },
    removeAmrapRule(state, action: PayloadAction<{workoutIndex: number; exerciseIndex: number; setIndex: number; amrapRuleIndex: number;}>) {
      const set = state.createData
        .workouts[action.payload.workoutIndex]
        .workoutExercises[action.payload.exerciseIndex]
        .sets[action.payload.setIndex];

      if (set.amrap_overload_rules) {
        const newRules = set.amrap_overload_rules.filter((_, index) => index !== action.payload.amrapRuleIndex);
        set.amrap_overload_rules = newRules.length ? newRules : undefined;
      }
    },
    setErrors(state, action: PayloadAction<any>) {
      state.errors = action.payload;
    },
    togglePreviewMode(state) {
      state.previewMode = !state.previewMode;
    },
    setEditorState(state, action: PayloadAction<RoutineFullUpdateData>) {
      state.createData = action.payload;
    },
    resetEditor(state) {
      state.createData = {...initialState.createData};
      state.previewMode = false;
      state.template = undefined;
      state.errors = {};
      resetEditorLocalStorage();
    },
    setTemplate(state, action: PayloadAction<RoutineTemplate>) {
      switch(action.payload) {
      case RoutineTemplate.BLANK:
        state.createData = {
          name: '',
          description: '',
          tm_percent_from_1rm: 100,
          max_weeks: 12,
          workouts: []
        };
        state.template = RoutineTemplate.BLANK;
        break;
      case RoutineTemplate._3X3:
        //@ts-ignore
        state.createData = {...routine3x3};
        state.template = RoutineTemplate._3X3;
        break;
      case RoutineTemplate._5X5:
        //@ts-ignore
        state.createData = {...routine5x5};
        state.template = RoutineTemplate._5X5;
        break;
      case RoutineTemplate.PPL:
        //@ts-ignore
        state.createData = {...routinePpl};
        state.template = RoutineTemplate.PPL;
        break;
      case RoutineTemplate._531:
        //@ts-ignore
        state.createData = {...routine531};
        state.template = RoutineTemplate._531;
        break;
      }
    },
    startAgain(state) {
      state.createData = {
        name: '',
        description: '',
        tm_percent_from_1rm: 100,
        max_weeks: 12,
        workouts: []
      };
      state.template = undefined;
      state.previewMode = false;
    }
  },
});

export default slice;

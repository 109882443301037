import { useSelector } from 'react-redux';
import { WorkoutExerciseType } from '../../../common/types';
import { determineWorkoutExerciseType } from '../../helpers/workout-exercise-type';
import { RootState } from '../store';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

export default function useEditorWorkoutExerciseType({ workoutIndex, exerciseIndex }: Props): WorkoutExerciseType {
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exerciseCache = useSelector((state: RootState) => state.exerciseCacheState.cacheMap);

  return determineWorkoutExerciseType(exerciseCache, workoutExercise.exercise_id);
}

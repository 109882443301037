
const canUseDOM: boolean = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export function useIsServer() {
  const isServer = !canUseDOM;

  return isServer;
}
import React, { useRef, useState } from 'react';
import { Button, Col, Row, Collapse } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import AmrapOverloadRuleEditor from '../row-edit/AmrapOverloadRuleEditor';
import AmrapOverloadRule from './AmrapOverloadRule';
import useClickOutsideHandler from '../../../../lib/hooks/useClickOutsideHandler';
import { getWeightStep } from '../../../weight/Weight';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../lib/store';
import slice from '../../../../lib/slices/routine-editor';
import usePublicPrivateExercise from '../../../../lib/hooks/usePublicPrivateExercise';
import useUnits from '../../../../lib/hooks/useUnits';
import { WeightSetType } from '../../../../../common/types';

interface AmrapOverloadRulesProps {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
}

function AmrapOverloadRules ({workoutIndex, exerciseIndex, setIndex}: AmrapOverloadRulesProps) {
  const dispatch = useDispatch();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const workoutExerciseSet = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex]);
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);
  const units = useUnits();
  const rules = workoutExerciseSet.amrap_overload_rules;
  const [editorIndex, setEditorIndex] = useState(rules && rules.length === 1 ? 0 : -1);
  const type = workoutExerciseSet.weight_set_type === WeightSetType.TM ? 'Training Max' : '1 Rep Max';

  const aolEditorRef = useRef(null);

  useClickOutsideHandler(aolEditorRef, () => {
    setEditorIndex(-1);
  });

  function addRule() {
    const r = rules;
    const lastRule = r && r.length ? r[r.length - 1] : { min: 0, max: 0, amount: 0};

    dispatch(slice.actions.addAmrapRule({workoutIndex, exerciseIndex, setIndex, data: {
      min: lastRule.max ? Number(lastRule.max) + 1 : lastRule.min ? Number(lastRule.min) + 1 : 1,
      max: lastRule.max ? Number(lastRule.max) + 2: undefined,
      amount: Number(lastRule.amount) + getWeightStep(units, targetExercise?.type)
    }}));
    setEditorIndex(r ? r.length : -1);
  }

  function deleteRule (i: number) {
    dispatch(slice.actions.removeAmrapRule({
      workoutIndex, exerciseIndex, setIndex,
      amrapRuleIndex: i
    }));

    setEditorIndex(-1);
  }

  if (!workoutExerciseSet.amrap_overload_rules?.length) {
    return null;
  }

  return (
    <>
      {rules ? (
        <Collapse appear={true} in={rules ? true : false}>
          <div className="po-box">
            <p>
              This AMRAP set is also a target for Progressive Overload rules.
              If you do more reps than {workoutExerciseSet.intensity_repetitions}, the {type} will be suggested to increase next session.
            </p>

            <Row className="routine-woe-sets-header">
              <Col className="pl-0 pr-0">
                <h5>Progressive Overload</h5>
              </Col>
            </Row>

            {rules.map((aol, i) => {
              if (i != editorIndex) {
                return (
                  <AmrapOverloadRule
                    workoutIndex={workoutIndex}
                    exerciseIndex={exerciseIndex}
                    setIndex={setIndex}
                    key={`${workoutIndex}_${exerciseIndex}_${setIndex}_${i}`}
                    amrapRuleIndex={i}
                    onClick={() => setEditorIndex(i)}
                  />
                );
              }

              return (
                <AmrapOverloadRuleEditor
                  workoutIndex={workoutIndex}
                  exerciseIndex={exerciseIndex}
                  setIndex={setIndex}
                  ref={aolEditorRef}
                  key={`${workoutIndex}_${exerciseIndex}_${setIndex}_${i}`}
                  amrapRuleIndex={i}
                  onDelete={() => deleteRule(i)}
                />
              );
            })}
            <Row>
              <Col xs={12}>
                <Button className="btn-add-row btn-add-sm" onClick={() => addRule()}><span className="add-plus"><Plus /></span> Add rule</Button>
              </Col>
            </Row>
          </div>
        </Collapse>
      ): null }
    </>
  );
}

export default AmrapOverloadRules;

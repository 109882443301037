import React, { useState } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { WorkoutExerciseType } from '../../../../../common/types';
import { RootState } from '../../../../lib/store';
import EditableText from '../../../form/EditableText';
import WorkoutExerciseSelectCollapsed from './SelectExerciseCollapsed';
import WorkoutExerciseSelectExpanded from './SelectExerciseExpanded';
import BodyweightRepBody from './BodyweightRepBody';
import BodyweightTimeBody from './BodyweightTimeBody';
import WeightBody from './WeightBody';


interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setExpanded: (isExpanded: boolean) => void;
  expanded: boolean
}

function CardSwitch({ workoutIndex, exerciseIndex }: { workoutIndex: number; exerciseIndex: number; }) {
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);

  if (workoutExercise.workout_exercise_type === WorkoutExerciseType.BODYWEIGHT_TIME) {
    return (<BodyweightTimeBody workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />);
  }

  if (workoutExercise.workout_exercise_type === WorkoutExerciseType.BODYWEIGHT_REP) {
    return (<BodyweightRepBody workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />);
  }

  if (workoutExercise.workout_exercise_type === WorkoutExerciseType.WEIGHT) {
    return (<WeightBody workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />);
  }

  return null;
}

export default function WorkoutExerciseExpandedCardBody({ workoutIndex, exerciseIndex, setExpanded, expanded }: Props) {
  const [exerciseCancelEdit, setExerciseCancelEdit] = useState(false);

  return (
    <>
      <Row className="routine-woe-sets" noGutters>
        <Col>
          <EditableText forceCancel={exerciseCancelEdit} edittingChanged={() => setExerciseCancelEdit(false)}>
            <WorkoutExerciseSelectExpanded workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setExerciseCancelEdit={(cancelled: boolean) => setExerciseCancelEdit(cancelled)} />
            <WorkoutExerciseSelectCollapsed workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setExpanded={(isExpanded) => setExpanded(isExpanded)} />
          </EditableText>
        </Col>
      </Row>
      <div className="routine-wow-collapse-holder">
        <Collapse appear={true} in={expanded}>
          <div className="routine-wow-collapse-inner">
            <CardSwitch workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
          </div>
        </Collapse>
      </div>
    </>
  );
}
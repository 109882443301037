import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoutineCreateSchema } from '../../../common/validators/routine';
import { createZodValidator } from '../core';
import editorSlice from '../slices/routine-editor';
import { RootState } from '../store';

const createRoutineValidator = createZodValidator(RoutineCreateSchema);

export default function useListenEditorError() {
  const dispatch = useDispatch();

  const createData = useSelector((state: RootState) => state.routineEditorState.createData);

  useEffect(() => {
    const validate = async () => {
      const { valid, error } = await createRoutineValidator(createData);

      dispatch(editorSlice.actions.setErrors(!valid ? error : null));
    };

    validate();
  }, [createData]);

  return;
}

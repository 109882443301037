import { ExerciseData } from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import ExerciseService from '../services/exercise.service';
import { ApiError } from '../middleware/api';

export interface ExerciseCacheState {
    list: ExerciseData[];
    count: number;
    cacheMap: {[key:string]: ExerciseData};
    loading: boolean;
    error?: any;
}

const initialState: ExerciseCacheState = {
  list: [],
  count: 0,
  cacheMap: {},
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'exerciseCache',
  initialState,
  reducers: {
    cacheExercisePending (state) {
      state.loading = true;
    },
    cacheExerciseFulfilled (state, action: PayloadAction<{ items: ExerciseData[]; count: number; }>) {
      state.loading = false;
      state.list = action.payload.items;
      state.cacheMap = action.payload.items.reduce((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {} as Record<string, ExerciseData>);
      state.count = action.payload.count;
      state.error = undefined;
    },
    cacheExerciseRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export function cacheAllExercises() {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.cacheExercisePending());
    const action = ExerciseService.listAllAction({
      onSuccess: (r) => {dispatch(slice.actions.cacheExerciseFulfilled(r));},
      onFailure: (e) => {dispatch(slice.actions.cacheExerciseRejected(e));}
    });

    dispatch(action);
  };
}

export default slice;

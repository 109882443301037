import rootReducer from './slices/index';
import apiMiddleware from './middleware/api';
import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { isBrowser } from 'browser-or-node';
export type RootState = ReturnType<typeof rootReducer>;

const middleware = [
  ...getDefaultMiddleware<RootState>({
    //@ts-ignore
    serializableCheck: {
      ignoredActions: [ 'API' ],
    },
  }),
  apiMiddleware,
];

if (process.env.NODE_ENV !== 'production') {
  middleware.unshift(logger);
}

export default (preloadedState: Partial<RootState> = {}) => {
  if (isBrowser) {
    try {
      const localState = localStorage.getItem('routineEditorState');
      if (localState) {
        preloadedState.routineEditorState = JSON.parse(localState);
      }
    } catch (e) {
      console.error(e, 'getting local state fails');
    }
  }

  return configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState
  });
};

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { RootState } from '../../lib/store';
import { useDispatch, useSelector } from 'react-redux';
import routineCreateSlice, {createRoutine} from '../../lib/slices/routine.create';
import RoutineEditorComponent from '../workout-editor/RoutineEditor';
import RoutinePreviewComponent from '../preview/RoutinePreviewComponent';
import useRoutineEditorError from '../../lib/hooks/useRoutineEditorError';
import routineEditorSlice, { RoutineTemplate } from '../../lib/slices/routine-editor';
import { stripHtml } from '../../helpers/form';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { publicUrl } from '../../lib/config';
import Loading from '../Loading';
import useListenEditorError from '../../lib/hooks/useListenEditorError';
import useEditorLocalStorage from '../../lib/hooks/useEditorLocalStorage';
const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

function RoutineCreatePageComponent () {
  const dispatch = useDispatch();
  const routineCreateState = useSelector((state: RootState) => state.routineCreateState);
  const routineEditorState = useSelector((state: RootState) => state.routineEditorState);
  const user = useSelector((state: RootState) => state.authState.data);
  const errors = useRoutineEditorError();
  const history = useHistory();
  useListenEditorError();
  useEditorLocalStorage();
  useEffect(() => {
    if (!routineCreateState.data) {
      return;
    }

    dispatch(routineCreateSlice.actions.resetCreateRoutine());
    dispatch(routineEditorSlice.actions.resetEditor());
    history.push(`/routine/${routineCreateState.data.slug}-${routineCreateState.data.id}`);

    return () => {
      dispatch(routineCreateSlice.actions.resetCreateRoutine());
    };
  }, [routineCreateState]);

  useEffect(() => {
    if (routineEditorState.createData?.id) {
      history.push(`/routine/update/${routineEditorState.createData?.id}`);
    }
  }, [routineEditorState.createData?.id]);

  function dispatchCreateRoutine() {
    dispatch(createRoutine({
      ...routineEditorState.createData,
      description: routineEditorState.createData.description ? stripHtml(routineEditorState.createData.description) : routineEditorState.createData.description
    }));
  }

  if (routineCreateState.loading) {
    return <Loading />;
  }

  if (!routineEditorState.template) {
    return (<>

      <Container>
        <h2 className='caps-condensed text-center pt-5'>
          Choose a routine template
        </h2>
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={4}>
            <a role="button" tabIndex={0} className='template-select-box' onClick={() => dispatch(routineEditorSlice.actions.setTemplate(RoutineTemplate.BLANK))}>
              Blank
            </a>
            <a role="button" tabIndex={0} className='template-select-box' onClick={() => dispatch(routineEditorSlice.actions.setTemplate(RoutineTemplate._3X3))}>
              3x3
            </a>
            <a role="button" tabIndex={0} className='template-select-box' onClick={() => dispatch(routineEditorSlice.actions.setTemplate(RoutineTemplate._5X5))}>
              5x5
            </a>
            <a role="button" tabIndex={0} className='template-select-box' onClick={() => dispatch(routineEditorSlice.actions.setTemplate(RoutineTemplate.PPL))}>
              Push Pull Legs
            </a>
            <a role="button" tabIndex={0} className='template-select-box' onClick={() => dispatch(routineEditorSlice.actions.setTemplate(RoutineTemplate._531))}>
              5/3/1
            </a>

            <a role="button" tabIndex={0} className='template-select-box-skip' onClick={() => dispatch(routineEditorSlice.actions.setTemplate(RoutineTemplate.BLANK))}>
              Skip
            </a>
          </Col>
        </Row>
      </Container>
    </>);
  }
  if (routineEditorState.previewMode) {
    return (
      <>
        <RoutinePreviewComponent 
          //@ts-ignore
          routineData={routineEditorState.createData} user={user} onSave={() => dispatchCreateRoutine() } editting={true}/>

        <Container fluid className="routine-header">
          <Container>
            <Row>
              <Col className="text-left">
                <Button variant="outline-primary" onClick={() => dispatch(routineEditorSlice.actions.togglePreviewMode()) }>Continue editing</Button>
              </Col>
              <Col className="text-right">
                <Button variant="primary" onClick={() => dispatchCreateRoutine() }>Create</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet titleTemplate="Hardy - %s">
        <title>Create new routine</title>
        <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
        <meta property="og:title" content="Hardy - Create new routine" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hardy.app/create" />
        <meta property="og:image" content={socialMediaFullUrl} />
        <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
      </Helmet>

      <Container fluid className="routine-header">
        <Container>
          <Row>
            <Col>
              <h2 className="caps-title mb-0">Create a Routine</h2>
            </Col>
            <Col className="text-right">
              <Button variant="outline-primary" className="mr-4" onClick={() => dispatch(routineEditorSlice.actions.startAgain())}>Reset</Button>
              <Button variant="primary" disabled={!!errors || !!routineCreateState.error} onClick={() => dispatch(routineEditorSlice.actions.togglePreviewMode())}>Preview</Button>
            </Col>
          </Row>
        </Container>
      </Container>

      <RoutineEditorComponent />

      <Container fluid className="routine-header">
        <Container>
          <Row>
            <Col className="text-left">
              <Button variant="outline-primary" className="mr-4" onClick={() => dispatch(routineEditorSlice.actions.startAgain())}>Reset</Button>
            </Col>
            <Col className="text-right">
              <Button variant="primary" disabled={!!errors || !!routineCreateState.error} onClick={() => dispatch(routineEditorSlice.actions.togglePreviewMode())}>Preview</Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}


export default RoutineCreatePageComponent;

import React from 'react';
import { Check2 } from 'react-bootstrap-icons';
import { WorkoutExerciseSetData } from '../../../../common/types';
import { usePreviewSet } from '../../context/PreviewSetContext';
import HardyTooltip from '../../ui/HardyTooltip';

export default function BodyweightRepSet(props: { index: number; }) {
  const set = usePreviewSet() as WorkoutExerciseSetData;

  return (
    <>
      <div className="woe-cus-row routine-woe-sets-row routine-woe-sets-row--preview">

        <div className="woe-cus-col-1">
          <span className="text-muted">{props.index + 1}</span>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-3">
        </div>
        <div className="woe-cus-col-4">
          <div className={'ml-2'}>
            <span className={set.amrap ? 'amrap-highlight pl-2' : 'ml-2'}>
              <strong className="rep-text">
                <span className="rep-number">
                  {set.intensity_repetitions}
                </span>
                {
                  set.amrap ? (
                    <span className="rep-plus"> +</span>
                  ) : null
                }
              </strong>
              {
                set.amrap ? (
                  <HardyTooltip message='As Many Reps As Possible' >
                    <div className="amrap-tag ml-3"><Check2 /> amrap</div>
                  </HardyTooltip>
                ) : null
              }
            </span>
          </div>
        </div>
        <div className="woe-cus-col-5 text-right">
          {set.rest_time} sec
        </div>
      </div>
    </>
  );
}

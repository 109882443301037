import React from 'react';

function BodyWeightTimeSetHeader () {
  return (
    <div className="routine-woe-sets-row-holder">
      <div className="woe-cus-row routine-woe-sets-header mt-3">
        <div className="woe-cus-col-1">
          <h5>Set</h5>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-3-4 text-right">
          <h5 className="mr-3">Time</h5>
        </div>
        <div className="woe-cus-col-5 text-right">
          <h5 className="mr-3">Rest time</h5>
        </div>
        <div className="woe-cus-col-7">
        </div>
      </div>
    </div>
  );
}

export default BodyWeightTimeSetHeader;

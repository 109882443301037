import { useSelector } from 'react-redux';
import { ExerciseData, WorkoutExerciseData } from '../../../common/types';
import { RootState } from '../store';

export default function usePublicPrivateExercise(exerciseId: number): Omit<ExerciseData, 'alternative_names'> | undefined {
  const exerciseFromCache = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[exerciseId]);
  const exerciseFromRoutineState = useSelector((state: RootState) => {
    const oneRoutine = state.routineOneFullState.data;

    if (!oneRoutine) {
      return undefined;
    }

    const woes = oneRoutine.workouts.reduce((woes, workout) => {
      return [...woes, ...workout.workoutExercises];
    }, [] as WorkoutExerciseData[]);

    const woe = woes.find(we => we.exercise_id === exerciseId);
    const woeTm = woes.find(we => we.intensity_weight_tm_percentage_exercise_id === exerciseId);

    if (woe) {
      return {
        id: woe.exercise_id,
        name: woe.exercise_label,
        // @ts-ignore
        type: woe.exercise_type
      };
    }

    if (woeTm) {
      return {
        id: woeTm.intensity_weight_tm_percentage_exercise_id,
        name: woeTm.intensity_weight_tm_percentage_exercise_label,
        // @ts-ignore
        type: woeTm.intensity_weight_tm_percentage_exercise_type
      };
    }

    return;
  });

  // @ts-ignore
  return exerciseFromCache ? exerciseFromCache : exerciseFromRoutineState;
}
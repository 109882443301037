import { isBrowser } from 'browser-or-node';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export default function useEditorLocalStorage() {
  const routineEditorState = useSelector((state: RootState) => state.routineEditorState);

  if (routineEditorState && isBrowser) {
    try {
      localStorage.setItem('routineEditorState', JSON.stringify(routineEditorState));
    } catch (e) {
      console.error(e, 'setting local state fails');
    }
  }
}

export function resetEditorLocalStorage() {
  localStorage.removeItem('routineEditorState');
}
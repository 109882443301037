import React from 'react';
import { WorkoutExerciseType } from '../../../../common/types';
import usePreviewWorkoutExerciseType from '../../../lib/hooks/usePreviewWorkoutExerciseType';
import { usePreviewWorkoutExercise } from '../../context/PreviewWorkoutExerciseContext';
import BodyweightRepExercise from './BodyweightRepExercise';
import BodyweightTimeExercise from './BodyweightTimeExercise';
import WeightExercise from './WeightExercise';

export default function WorkoutExerciseSwitch() {
  const woe = usePreviewWorkoutExercise();
  const workoutExerciseType = usePreviewWorkoutExerciseType();

  if (!woe) {
    return null;
  }

  if (workoutExerciseType === WorkoutExerciseType.BODYWEIGHT_TIME) {
    return (<BodyweightTimeExercise />);
  }

  if (workoutExerciseType === WorkoutExerciseType.BODYWEIGHT_REP) {
    return (<BodyweightRepExercise />);
  }

  return (<WeightExercise />);
}
import React, { useState } from 'react';
import './FAQ.scss';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';

function FaqComponent () {
  const [enabledFaqItem, setEnabledFaqItem] = useState('');
  const handleClick = (eventKey: string) => {
    if (eventKey == enabledFaqItem) {
      setEnabledFaqItem('');
    } else {
      setEnabledFaqItem(eventKey);
    }
  };

  const testimonials = [
    {
      question: 'How can it be free, where’s the catch?',
      answer: 'No catch. We plan to add some premium features with a monthly subscription soon, but everybody who joins before that happens will get a lifetime free access and receive the status of OG.',
    },
    {
      question: 'How does it differ from other gym apps on the market?',
      answer: 'Most weight training apps are for passive tracking/logging - you lift a weight, write it down in the app, and see analytics of how much you’ve lifted over time. Hardy allows to create or use routines that actively suggest the weight you should be lifting, based on the routine’s progression rules. If you lift as much as the routine requires, the suggested weight gets increased. If you lift less, and the routine suggest lowering the weight in such a case, the app will propose lowering the weight you lift next time. And of course, there’s also analytics.',
    },
    {
      question: 'Can I create my own custom training routines / programs?',
      answer: 'Yes, you can create your own routines, workouts, and your own custom exercises. You can add rules for increasing weights (progressions) and also decreasing them (deloads). You can use dynamic weights where rep weights are based on percentages of user 1 Rep Max and Training Max, and RPE.',
    },
    {
      question: 'Can I make a copy of some routine and edit that?',
      answer: 'Yes, you can make a copy of a routine and edit it in any way you wish.',
    },
    {
      question: 'I don’t know some of the terms on this page, can I still use the app?',
      answer: 'We try to include a tooltip for less known terms and there is also a FAQ section inside the app that explains everything. If anything feels confusing you can always ask us on our subreddit or Instagram.',
    },
    {
      question: 'What kind of progression rules are currently supported?',
      answer: 'Currently progression rules can be created for intensity calculated from user&apos;s Training Max, RPE, e1RM. We will add progression by volume soon. If you would like this feature, let us know.',
    },
    {
      question: 'What is Training Max? How is it different from 1 Rep Max?',
      answer: '1RM is the amount you can lift on maximum effort. Training Max reflects the current planned effort of your workouts. For example on a deload week your Training max might be 70% of your 1RM.',
    },
    {
      question: 'What is RPE and RIR?',
      answer: 'RPE means Rate of Perceived Exertion, and RIR means Reps in Reserve. Both are a way to measure the intensity of your training subjectively. Hardy app supports using RPE both in routine sets (meaning you can create a routine by setting the intensity of your sets using only RPE), as well as tracking RPE (meaning you can log your RPE/RIR on lifts in any routine).',
    }
  ];

  return (
    <>
      <Container id="faq" fluid className="hiw-content bg-light mt-5">
        <Container className="hiw-faq">
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <h2 className="content-title text-center">FAQ</h2>
              <p className="lead text-center mb-5">
                If you have any more questions please contact us on <a href="https://www.instagram.com/hardy.app" target="_blank" rel="noreferrer">@HardyApp</a> or post on <a href="https://www.reddit.com/r/hardyapp" target="_blank" rel="noreferrer">/r/HardyApp</a>.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} className="mt-3">
              <Accordion>
                {testimonials.map((faqItem, i) => {
                  const eventKey = (i+1).toString();
                  return (
                    <Card key={i}>
                      <Accordion.Toggle as={Card.Header} eventKey={eventKey} onClick={() => handleClick(eventKey)}>
                        {faqItem.question}
                        <PlusCircle className={enabledFaqItem == eventKey ? 'accordion-close-icon accordion-close-icon--opened' : 'accordion-close-icon'} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={eventKey}>
                        <Card.Body>
                          {faqItem.answer}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default FaqComponent;


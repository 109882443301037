import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MenuComponent from './menu/Menu';
import LoginComponent from './pages/LoginPage';
import RoutineCreatePage from './pages/RoutineCreatePage';
import RoutineListPage from './pages/RoutineListPage';
import RoutineOnePage from './pages/RoutineOnePage';
import RoutineEditPage from './pages/RoutineEditPage';
import UpdateUserPage from './pages/UpdateUserPage';
import PrivateRoute from './auth/PrivateRoute';
import ForgotPasswordComponent from './pages/ForgotPasswordPage';
import RecoverAccountComponent from './pages/RecoverAccountPage';
import RegisterComponent from './pages/RegisterPage';
import HowItWorksComponent from './pages/HowItWorksPage';
import DevTestComponent from './pages/DevTestPage';
import ScrollToTop from './ScrollToTop';
import TermsAndConditionsComponent from './pages/TermsAndConditionsPage';
import PrivacyPolicyComponent from './pages/PrivacyPolicyPage';
import AboutComponent from './pages/AboutPage';
import PrivateAdminRoute from './auth/PrivateAdminRoute';
import AdminStatsPage from './pages/admin/Stats';
import AdminWorkoutPage from './pages/admin/WorkoutArchive';
import AdminWorkoutStatsPage from './pages/admin/WorkoutStats';
import UserRemoveFacebookPage from './pages/UserRemoveFacebookPage';
import DownloadPage from './pages/DownloadPage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteUserPage from './pages/DeleteUserPage';

function Root() {
  return (
    <DndProvider backend={HTML5Backend}>
      <ScrollToTop />
      <Switch>
        <MenuComponent>
          <Route path="/" component={HowItWorksComponent} exact={true} />
          <Route path="/routines" component={RoutineListPage} exact={true} />
          <Route path="/login" component={LoginComponent} exact={true} />
          <Route path="/test" component={DevTestComponent} exact={true} />
          <Route path="/register" component={RegisterComponent} exact={true} />
          <Route path="/forgot-password" component={ForgotPasswordComponent} exact={true} />
          <Route path="/recover/:token" component={RecoverAccountComponent} exact={true} />
          <Route path="/create" component={RoutineCreatePage} exact={true} />
          <PrivateRoute path="/account" component={UpdateUserPage} exact={true} />
          <PrivateRoute path="/delete-account" component={DeleteUserPage} exact={true} />
          <PrivateRoute path="/facebook-delete" component={UserRemoveFacebookPage} exact={true} />
          <Route path="/routine/update/:id" component={RoutineEditPage} exact={true} />
          <Route path="/routine/:id" component={RoutineOnePage} exact={true} />
          <Route path="/termsandconditions" component={TermsAndConditionsComponent} exact={true} />
          <Route path="/privacypolicy" component={PrivacyPolicyComponent} exact={true} />
          <Route path="/about" component={AboutComponent} exact={true} />
          <PrivateAdminRoute path="/admin/workout/stats" component={AdminWorkoutStatsPage} exact={true} />
          <PrivateAdminRoute path="/admin/workouts" component={AdminWorkoutPage} exact={true} />
          <PrivateAdminRoute path="/admin" component={AdminStatsPage} exact={true} />
          <Route path="/download" component={DownloadPage} exact={true} />

        </MenuComponent>
      </Switch>
    </DndProvider>
  );
}

export default Root;

import React, { forwardRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ExerciseType, WeightSetType } from '../../../../../common/types';
import usePublicPrivateExercise from '../../../../lib/hooks/usePublicPrivateExercise';
import useRoutineEditorError from '../../../../lib/hooks/useRoutineEditorError';
import useUnits from '../../../../lib/hooks/useUnits';
import slice from '../../../../lib/slices/routine-editor';
import { RootState } from '../../../../lib/store';
import HardyInputComponent from '../../../form/HardyInput';
import WeightPicker from '../../../weight/WeightPicker';

interface AmrapOverloadRuleEditor {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  amrapRuleIndex: number;
  onDelete: () => void;
}

function AmrapOverloadRuleEditor({ workoutIndex, exerciseIndex, setIndex, amrapRuleIndex, onDelete }: AmrapOverloadRuleEditor, ref: any) {
  const dispatch = useDispatch();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}_amrap_overload_rules_${amrapRuleIndex}`;
  const units = useUnits();
  const errors = useRoutineEditorError();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const workoutExerciseSet = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex]);
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex].amrap_overload_rules?.[amrapRuleIndex]);
  const exercise = usePublicPrivateExercise(workoutExercise.exercise_id || 1);
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);

  const tmRuleValid = targetExercise && workoutExerciseSet.weight_set_type === WeightSetType.TM;
  const _1RmRuleValid = exercise && workoutExerciseSet.weight_set_type === WeightSetType._1RM;
  const type = workoutExerciseSet.weight_set_type === WeightSetType.TM ? 'TM' : '1RM';

  if (!rule || (!tmRuleValid && !_1RmRuleValid)) {
    return null;
  }

  const exerciseType = (workoutExerciseSet.weight_set_type === WeightSetType.TM ? targetExercise?.type : exercise?.type) as ExerciseType;
  return (
    <Row className="routine-woe-sets-row routine-woe-sets-row--amrap align-items-center" ref={ref}>
      <Col xs={11} className="pl-0 pr-0">
        <div className="inputs-inline">
          <p className="mb-0 p-0">
            Extra
            <HardyInputComponent
              hideError={true}
              name={`${validationPrefix}_${amrapRuleIndex}_min`}
              errors={errors}
              formCtrl={{
                type: 'number',
                onChange: (e: any) => {
                  dispatch(slice.actions.updateAmrapRule({
                    workoutIndex, exerciseIndex, setIndex, amrapRuleIndex,
                    data: {
                      min: e.target.value ? Number(e.target.value) : undefined
                    }
                  }));
                },
                value: rule.min
              }}
            />
            -
            <HardyInputComponent
              hideError={true}
              name={`${validationPrefix}_${amrapRuleIndex}_max`}
              errors={errors}
              formCtrl={{
                type: 'number',
                onChange: (e: any) => {
                  dispatch(slice.actions.updateAmrapRule({
                    workoutIndex, exerciseIndex, setIndex, amrapRuleIndex,
                    data: {
                      max: e.target.value ? Number(e.target.value) : undefined
                    }
                  }));
                },
                value: rule.max
              }}
            />
            reps, increase {type} by
            <span>
              <WeightPicker
                units={units}
                type={exerciseType}
                defaultValue={rule.amount}
                onChange={(grams: number) => {
                  dispatch(slice.actions.updateAmrapRule({
                    workoutIndex, exerciseIndex, setIndex, amrapRuleIndex,
                    data: {
                      amount: grams
                    }
                  }));
                }}
              />
            </span>
          </p>
        </div>
      </Col>
      <Col xs={1} className="text-right pl-0 pr-0">
        <Button className="btn-icon btn-delete" variant="outline-secondary" onClick={() => onDelete()}>
          <Trash />
        </Button>
      </Col>
    </Row>
  );
}

export default forwardRef(AmrapOverloadRuleEditor);

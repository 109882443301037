import {
  RouteAuthUpdateBody,
  RouteAuthUpdateOutput,
  UserData
} from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import AuthService from '../services/auth.service';
import authSlice from './auth';

export interface UpdateUserState {
    data?: UserData;
    loading: boolean;
    error?: any;
}

const initialState: UpdateUserState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'updateUser',
  initialState,
  reducers: {
    updateUserPending (state) {
      state.loading = true;
    },
    updateUserFulfilled (state, action: PayloadAction<RouteAuthUpdateOutput>) {
      state.loading = false;
      state.data = action.payload.data;
      state.error = undefined;
    },
    updateUserRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.data = undefined;
      state.error = action.payload;
    },
    resetUpdateUser (state) {
      state.data = undefined;
      state.error = undefined;
    }
  },
});

export function updateUser(body: RouteAuthUpdateBody) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.updateUserPending());
    const action = AuthService.updateUserAction({ body }, {
      onSuccess: (r) => {
        dispatch(authSlice.actions.setUser(r));
        dispatch(slice.actions.updateUserFulfilled(r));
      },
      onFailure: (e) => {dispatch(slice.actions.updateUserRejected(e));}
    });
    dispatch(action);
  };
}

export default slice;

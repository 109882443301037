import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ChevronDown, Pencil, Trash } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../lib/store';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setExpanded: (expanded: boolean) => void
}

export default function WorkoutExerciseSelectCollapsed({ workoutIndex, exerciseIndex, setExpanded }: Props) {
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);

  if (!exercise) {
    return null;
  }

  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--clickable">
      <Row className="routine-woe-sets-row" noGutters>
        <Col xs={10} className="align-items-center d-flex">
          <ChevronDown className="woe-chevron" onClick={() => setExpanded(false)} />
          <div className={'icon-exercise icon-exercise--' + exercise.type} onClick={() => setExpanded(false)}></div>
          <div>
            <h4 className="mb-0">{exercise.name}</h4>
          </div>
        </Col>
        <Col xs={2} className="text-right align-items-center d-flex justify-content-end">
          <Button className="btn-icon mr-2 disabled" disabled variant="outline-secondary">
            <Trash />
          </Button>
          <Button className="btn-icon" variant="outline-secondary">
            <Pencil />
          </Button>
        </Col>
      </Row>
    </div>
  );
}
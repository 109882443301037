import MDEditor from '@uiw/react-md-editor';
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Container, Dropdown, Row,
  Spinner
} from 'react-bootstrap';
import { BoxArrowUp, Check2, EyeFill, EyeSlash, Files, Pencil, Trash, X } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ExerciseType, RoutineFullData, UserUnit, WeightSetType, RoutineStatus } from '../../../common/types';
import { UserData } from '../../../common/types/model/user';
// import useMobileDetect from '../../lib/hooks/useMobileDetectHook';
import useUnits from '../../lib/hooks/useUnits';
import { copyRoutineRequest, subscribeRequest, unsubscribeRequest } from '../../lib/services/routine.service';
import authSlice from '../../lib/slices/auth';
import slice from '../../lib/slices/routine-editor';
import { listSubscribedRoutine } from '../../lib/slices/routine.subscribed-list';
import { RootState } from '../../lib/store';
import { PreviewExercise1RmContextProvider } from '../context/PreviewExercise1Rms';
import { PreviewRoutineContextProvider } from '../context/PreviewRoutineContext';
import { PreviewWorkoutContextProvider } from '../context/PreviewWorkoutContext';
import { DumbbellIcon, WeightIcon } from '../icon';
import DuplicateRoutineModal from '../modal/DuplicateRoutineModal';
import ShareRoutineModal from '../modal/ShareRoutineModal';
import DeleteRoutineModal from '../modal/DeleteRoutineModal';
import HardyTooltip from '../ui/HardyTooltip';
import './RoutinePreviewComponent.scss';
import _1RMList from './_1RMList';
import Workout from './Workout';

const ExerciseTypeDefault1RMMap = {
  [ExerciseType.BARBELL]: 75000,
  [ExerciseType.DUMBBELL]: 10000,
  [ExerciseType.KETTLEBELL]: 16000,
  [ExerciseType.MACHINE]: 20000,
  [ExerciseType.PLATE]: 50000,
};

type Props = {
  routineData: RoutineFullData;
  changeStatus?: Function;
  deleteRoutine?: Function;
  user?: UserData;
  editting: boolean;
  onSave: () => void;
};

export default function RoutinePreviewComponent(props: Props) {
  // const platformCheck = useMobileDetect();
  const [exercise1Rms, setExercise1Rms] = useState<{ [key: string]: number }>({});
  const exerciseCache = useSelector((state: RootState) => state.exerciseCacheState.cacheMap);
  const [exerciseIds, setExerciseIds] = useState<number[]>([]);
  const unit = useUnits();
  const [isLoading, setIsLoading] = useState(false);
  const editor = useSelector((state: RootState) => state.routineEditorState);

  const history = useHistory();
  const dispatch = useDispatch();

  const isNewRoutineDraft = !props.routineData.id;
  const isMyRoutine = props.user?.id === props.routineData.user_id;

  const routineSubscribedListState = useSelector((state: RootState) => state.routineSubscribedListState);
  const isSubscribedRoutine = routineSubscribedListState.list.some((sr) => sr.id === props.routineData.id);

  const [showShareModal, setShowShareModal] = useState(false);
  const handleShareModalClose = () => setShowShareModal(false);
  const handleShowShareModal = () => setShowShareModal(true);

  const [showDuplicateRoutineModal, setShowDuplicateRoutineModal] = useState(false);
  const handleDuplicateRoutineModalClose = () => setShowDuplicateRoutineModal(false);
  const handleShowDuplicateRoutineModal = () => setShowDuplicateRoutineModal(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const isRoutineUpdate = props.editting && editor.createData.id;

  function loadSubscribedList() {
    dispatch(listSubscribedRoutine({}));
  }

  async function subscribeToRoutine() {
    await subscribeRequest({ id: props.routineData.id });
    loadSubscribedList();
  }

  async function unsubscribeFromRoutine() {
    await unsubscribeRequest({ id: props.routineData.id });
    loadSubscribedList();
  }

  useEffect(() => {
    loadSubscribedList();
  }, []);

  useEffect(() => {
    const exercises = props.routineData.workouts.reduce((idMap: any, wo) => {
      wo.workoutExercises.forEach((woe) => {
        const tmExerciseId = woe.intensity_weight_tm_percentage_exercise_id;

        if (!tmExerciseId) {
          return;
        }

        const exType = exerciseCache[tmExerciseId]?.type;

        if (!exType || [ExerciseType.BODYWEIGHT_TIME, ExerciseType.BODYWEIGHT_REP].includes(exType)) {
          return;
        }

        if (woe.intensity_weight_tm_percentage_exercise_id) {
          if (woe.sets.some((s) => typeof s.intensity_weight_tm_percentage === 'number')) {
            // @ts-ignore
            idMap[woe.intensity_weight_tm_percentage_exercise_id] = ExerciseTypeDefault1RMMap[exType];
          }
          if (woe.sets.some((s) => s.weight_set_type && [WeightSetType.RPE, WeightSetType.TM, WeightSetType._1RM].includes(s.weight_set_type))) {
            // @ts-ignore
            idMap[woe.exercise_id] = ExerciseTypeDefault1RMMap[exType];
          }
        }
      });
      return idMap;
    }, {});

    setExercise1Rms(exercises);
    setExerciseIds(Object.keys(exercises).map(i => +i));

    return () => {
      setExercise1Rms({});
      setExerciseIds([]);
    };
  }, [props.routineData]);

  function changeGuestUnits(u: UserUnit) {
    dispatch(authSlice.actions.setGuestUnit(u));
  }

  async function startCustomizeRoutine() {
    setIsLoading(true);

    const { response, error, meta } = await copyRoutineRequest({ id: props.routineData.id });
    if (!error && response) {
      if (meta?.setUser) {
        dispatch(authSlice.actions.setUser(meta.setUser));
      }
      history.push(`/routine/update/${response.slug}-${response.id}`);
    }
    setIsLoading(false);
  }

  const startDeleteRoutine = () => {
    // @ts-ignore
    props.deleteRoutine();
    history.push('/routines');
  };


  const resetEditor = () => {
    dispatch(slice.actions.resetEditor());
    if (isRoutineUpdate) {
      history.push(`/routine/${editor.createData.id}`);
    }
  };

  if (!props.routineData || isLoading) {
    return (<div className="page-loading"><Spinner animation="grow" /></div>);
  }

  return (
    <PreviewRoutineContextProvider routine={props.routineData}>
      <div id="routine-preview">
        <Container fluid className="routine-header">
          <Container>
            <Row>
              <Col className="">
                <Row className="align-items-center d-flex">
                  <Col className="">
                    <h2 className="caps-title mb-0">{props.editting ? 'Routine Preview' : 'Workout Routine'}</h2>
                  </Col>
                  {props.routineData.user_id === props.user?.id ? (
                    <Col xs={'auto'}>
                      {props.changeStatus !== undefined ? (
                        <p className="caps-title mb-0 mr-3">
                          {props.routineData.deleted_at ? 'Deleted' : props.routineData.status}
                        </p>
                      ) : null}
                    </Col>
                  ) : null}
                  {props.editting && isNewRoutineDraft ? (
                    <Col xs={'auto'}>
                      <p className="caps-title mb-0 mr-3">
                        Draft
                      </p>
                    </Col>
                  ) : null}
                  <Col xs={'auto'}>
                    <Dropdown alignRight>
                      <Dropdown.Toggle variant="outline-secondary" id="routine-options">
                        Options
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {props.routineData.user_id === props.user?.id ? (
                          <>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            {props.changeStatus !== undefined ? (
                              <>
                                {props.routineData.id ? (
                                  <Dropdown.Item onClick={() => {
                                    history.push(`/routine/update/${props.routineData.slug}-${props.routineData.id}`);
                                  }}><Pencil size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} /> Edit routine</Dropdown.Item>
                                ) : null}
                              </>
                            ) : null}
                            {props.deleteRoutine !== undefined ? (
                              <>
                                {props.routineData.deleted_at ? (
                                  <Dropdown.Item disabled>Deleted</Dropdown.Item>
                                ) : (
                                  <Dropdown.Item onClick={handleShowDeleteModal}><Trash size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} /> Delete routine</Dropdown.Item>
                                )}
                              </>
                            ) : null}
                            {props.changeStatus !== undefined ? (
                              <>
                                <Dropdown.Divider />
                                <Dropdown.Header>Routine privacy</Dropdown.Header>
                                <Dropdown.Item onClick={() => {
                                  // @ts-ignore
                                  props.changeStatus('private');
                                }
                                }><EyeSlash size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} /> Private {props.routineData.status === 'private' ? (<Check2 size={25} color={'#df563d'} style={{ float: 'right', marginRight: -4 }} />) : null}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                  // @ts-ignore
                                  props.changeStatus('public');
                                }
                                }><EyeFill size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} /> Public {props.routineData.status === 'public' ? (<Check2 size={25} color={'#df563d'} style={{ float: 'right', marginRight: -4 }} />) : null}</Dropdown.Item>
                              </>
                            ) : null}
                            <Dropdown.Divider />
                          </>
                        ) : null}
                        <Dropdown.Header>Options</Dropdown.Header>
                        {props.user ? (
                          <Dropdown.Item onClick={() => history.push('/account')}><span style={{ marginLeft: -3, marginRight: 7 }}><WeightIcon /></span> Change units (kg / lbs)</Dropdown.Item>
                        ) : (
                          <>
                            <Dropdown.Item onClick={() => changeGuestUnits(UserUnit.METRIC)}>Metric (kg) {unit === UserUnit.METRIC ? (<Check2 size={25} color={'#df563d'} style={{ float: 'right', marginRight: -4 }} />) : null}</Dropdown.Item>
                            <Dropdown.Item onClick={() => changeGuestUnits(UserUnit.IMPERIAL)}>Imperial (lbs) {unit === UserUnit.IMPERIAL ? (<Check2 size={25} color={'#df563d'} style={{ float: 'right', marginRight: -4 }} />) : null}</Dropdown.Item>
                          </>
                        )}
                        {props.editting ? null : (
                          <>
                            {props.routineData.status === 'private' ? null : (
                              <Dropdown.Item onClick={handleShowShareModal}><BoxArrowUp size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} /> Share routine</Dropdown.Item>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleShowDuplicateRoutineModal}><Files size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} /> Make a private copy & edit</Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 'auto' }} className="text-right">
                <Row className="align-items-center">
                  {props.editting ? (
                    <>
                      <Col className="mt-3 mt-sm-0">
                        <Button variant="outline-danger" onClick={() => resetEditor()} className="mr-3">Discard</Button>
                        <Button variant="outline-primary" onClick={() => dispatch(slice.actions.togglePreviewMode())} style={{ whiteSpace: 'nowrap' }}>
                          Continue Editing
                        </Button>
                      </Col>
                      <Col className="mt-3 mt-sm-0" xs={'auto'}>
                        <Button variant="primary" onClick={() => {
                          dispatch(slice.actions.togglePreviewMode());
                          props.onSave();
                        }}>{isNewRoutineDraft ? 'Create' : 'Save'}</Button>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="content-area mb-5 mt-5">
          <Row className="routine-holder">
            <Col xs={{ span: 12, order: 1 }} md={12} lg={8}>
              <Row>
                <Col className="d-none d-md-block" md={'auto'}>
                  <div className={`routine-img-holder routine-img-holder--lg mr-4 ${isSubscribedRoutine ? 'routine-img-holder--subscribed' : ''} ${isMyRoutine ? 'routine-img-holder--own' : ''}`}>
                    <DumbbellIcon />
                    {isMyRoutine && props.routineData.status === RoutineStatus.PRIVATE ? (
                      <HardyTooltip message='Private routine'>
                        <div className="routine-private-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" /></svg>
                        </div>
                      </HardyTooltip>
                    ) : null}
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col xs={'auto'} className="d-md-none">
                      <div className={`routine-img-holder routine-img-holder--lg mr-4 ${isSubscribedRoutine ? 'routine-img-holder--subscribed' : ''} ${isMyRoutine ? 'routine-img-holder--own' : ''}`}>
                        <DumbbellIcon />
                        {isMyRoutine && props.routineData.status === RoutineStatus.PRIVATE ? (
                          <HardyTooltip message='Private routine'>
                            <div className="routine-private-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" /></svg>
                            </div>
                          </HardyTooltip>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <h1 className="mb-1">{props.routineData.name}</h1>
                      {props.routineData.username || props.user?.username ? (
                        <p className="text-muted text-large mb-0">
                          by {props.routineData.user_id ? props.routineData.username : props.user?.username}
                        </p>
                      ) : (
                        <p className="text-muted text-large mb-0">
                          by Anonymous
                        </p>
                      )}
                    </Col>
                    <Col xs={'12'}>
                      {!isNewRoutineDraft ? (
                        <div className="routine-stats pt-1">
                          {!isMyRoutine ? (
                            <>
                              {isSubscribedRoutine ? (
                                <Button variant="outline-primary" className="btn-subscribe btn-with-icon mr-3" onClick={() => unsubscribeFromRoutine()}>
                                  <div className="show-on-hover"><X /> Remove from library?</div>
                                  <div className="show-as-default"><Check2 /> Added to library</div>
                                </Button>
                              ) : (
                                <>
                                  {props.editting || !props.user ? (
                                    <Button variant="primary" className="mr-3 btn-shadow disabled" disabled>Add to library</Button>
                                  ) : (
                                    <Button variant="primary" className="mr-3 btn-shadow" onClick={() => subscribeToRoutine()}>Add to library</Button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <HardyTooltip message='Your routine'>
                              <Button variant="outline-secondary" className="disabled btn-subscribe btn-with-icon mr-3" disabled>
                                <Check2 /> Your routine
                              </Button>
                            </HardyTooltip>
                          )}

                          {/* {platformCheck.isIos() ? <>
                            <a href={`hardy://r/${isSubscribedRoutine || isMyRoutine ? 'Routine' : 'PublicRoutine'}/{"id": ${props.routineData.id}}/${user.token ? JSON.stringify({ token: user.token, data: user.data }) : ''}`}><Button variant="outline-secondary" className="btn-with-icon pl-3 pr-3"><Link45deg /> Open in App</Button></a>
                          </> : null} */}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5 mb-5">
                <Col>
                  <div className="routine-description">
                    <MDEditor.Markdown source={props.routineData.description || ''} />

                    {!isNewRoutineDraft && !isMyRoutine && !isSubscribedRoutine && !props.editting && props.routineData.tm_percent_from_1rm && props.routineData.tm_percent_from_1rm !== 100 ? (
                      <p className="text-muted mt-4">
                        Starting TM is {props.routineData.tm_percent_from_1rm}% of 1RM.
                      </p>
                    ) : null}
                  </div>
                </Col>
              </Row>

            </Col>

            <_1RMList
              exerciseIds={exerciseIds}
              setExercise1Rms={setExercise1Rms}
              exercise1Rms={exercise1Rms}
              unit={unit}
            />

            <Col xs={{ span: 12, order: 3 }} md={12} lg={8}>
              <h3 className="caps-condensed mb-3">Workouts</h3>
              {props.routineData.workouts.map((wo, index) => (
                <PreviewExercise1RmContextProvider exercise1Rms={exercise1Rms} key={index}>
                  <PreviewWorkoutContextProvider workout={wo}>
                    <Workout />
                  </PreviewWorkoutContextProvider>
                </PreviewExercise1RmContextProvider>
              ))}
            </Col>
          </Row>

          <ShareRoutineModal showShareModal={showShareModal} handleShareModalClose={() => handleShareModalClose()} />
          <DuplicateRoutineModal showDuplicateRoutineModal={showDuplicateRoutineModal} handleDuplicateRoutineModalClose={() => handleDuplicateRoutineModalClose()} startCustomizeRoutine={() => startCustomizeRoutine()} />
          <DeleteRoutineModal showDeleteModal={showDeleteModal} handleDeleteModalClose={() => handleDeleteModalClose()} startDeleteRoutine={() => startDeleteRoutine()} />
        </Container>

      </div>
    </PreviewRoutineContextProvider>
  );
}

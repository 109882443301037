import React from 'react';

export function inputStateHandlerForArray<T>(e: React.ChangeEvent<HTMLInputElement>, formKey: string, list: T[], index: number): T[] {
  if (!e || !e.target) {
    return list;
  }

  const currentItem = {
    ...list[index],
    [formKey]: e.target.value
  };

  const newRules = [...list];
  newRules[index] = currentItem;
  return newRules;
}

export function hasValidationError(name: string, errors: any): boolean {
  return errors && errors.validation && errors.validation[name];
}

export function stripHtml(html: string) {
  if (!DOMParser) {
    return html;
  }
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}
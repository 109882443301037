import {
  RoutineFullData,
  RoutineFullUpdateData,
} from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import RoutineService from '../services/routine.service';
import editorSlice from './routine-editor';
export interface RoutineUpdateFullState {
    data?: RoutineFullData;
    loading: boolean;
    error?: any;
}

const initialState: RoutineUpdateFullState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'routineUpdateFull',
  initialState,
  reducers: {
    updateFullRoutinePending (state) {
      state.loading = true;
    },
    updateFullRoutineFulfilled (state, action: PayloadAction<RoutineFullData>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    updateFullRoutineRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetUpdateFullRoutine (state) {
      state.data = undefined;
      state.loading = false;
      state.error = undefined;
    }
  },
});

export function updateFullRoutine(id: string, body: RoutineFullUpdateData) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.updateFullRoutinePending());
    const action = RoutineService.updateFullAction({ id, body }, {
      onSuccess: (r) => {
        dispatch(slice.actions.updateFullRoutineFulfilled(r));
        dispatch(editorSlice.actions.resetEditor());
      },
      onFailure: (e) => {dispatch(slice.actions.updateFullRoutineRejected(e));}
    });
    dispatch(action);
  };
}

export default slice;

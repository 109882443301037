import { isBrowser } from 'browser-or-node';
import React, { useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import { Check2, Envelope, Link45deg, X } from 'react-bootstrap-icons';

type Props = {
  showShareModal: boolean;
  handleShareModalClose: () => void;
};


export default function ShareRoutineModal({ showShareModal, handleShareModalClose }: Props) {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Success');
    } catch (err) {
      setCopySuccess('Fail');
    }
  };

  return (
    <Modal className="shareroutine-modal" show={showShareModal} onHide={handleShareModalClose} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title>Share routine</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Nav className="flex-column">
          {isBrowser && document.queryCommandSupported('copy') ? (
            <Nav.Link onClick={() => copyToClipBoard(window.location.href)}><Link45deg size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} />Copy link {copySuccess === 'Success' ? (<div className="text-success" style={{ float: 'right' }}><Check2 size={25} /> Copied!</div>) : (copySuccess === 'Fail' ? (<div style={{ float: 'right' }}><X size={25} color={'#df563d'} /> Failed</div>) : null)}</Nav.Link>
          ) : null}
          <Nav.Link onClick={() => { window.open('mailto:?subject=Hardy.app smart workout routines&body=' + window.location.href); }}><Envelope size={20} color={'#C7BCB7'} style={{ marginLeft: -3, marginRight: 7 }} />Send email</Nav.Link>
        </Nav>
      </Modal.Body>
    </Modal>
  );
}

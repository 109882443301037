import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ChevronRight, GraphDown, GraphUp, Pencil } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { DeloadType, OverloadType } from '../../../../../common/types';
import useUnits from '../../../../lib/hooks/useUnits';
import { RootState } from '../../../../lib/store';
import HardyTooltip from '../../../ui/HardyTooltip';
import { formatWeight } from '../../../weight/Weight';


interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setExpanded: (isExpanded: boolean) => void;
}

export default function WorkoutExerciseCollapsedCardBody({ workoutIndex, exerciseIndex, setExpanded }: Props) {
  const units = useUnits();
  
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);

  if (!exercise) {
    return null;
  }

  return (
    <div onClick={() => setExpanded(true)}>
      <div className="d-block woe-minimized">
        <Row className="routine-woe-header">
          <Col className="routine-woe-header-col-clickable">
            <Row className="align-items-center">
              <Col xs={12} sm={6} md={7} className="align-items-center d-flex mb-2 mb-sm-0">
                <ChevronRight className="woe-chevron"/>
                <div className={'icon-exercise icon-exercise--'+exercise.type}></div>
                <div>
                  <h4 className="mb-0">{exercise.name}</h4>
                </div>
              </Col>
              <Col xs={{span: 4, offset: 1}} sm={{span: 2, offset: 0}}>
                <h5 className="routine-woe-header-sets">
                  {workoutExercise.sets.length} sets
                </h5>
              </Col>
              <Col xs={4} sm={3} md={{span: 2, offset: 0}}>
                { workoutExercise.overload_rule && workoutExercise.overload_rule.overload_type === OverloadType.TM ? (
                  <HardyTooltip message={`Overload: After ${workoutExercise.overload_rule.successful_sessions_trigger_number} successful ${workoutExercise.overload_rule.successful_sessions_trigger_number == 1 ? 'session' : 'sessions'}, Training Max is suggested to increase by ${formatWeight(workoutExercise.overload_rule.tm_overload_amount, exercise.type, {units})}.`}>
                    <div className="icon-overload"><GraphUp /></div>
                  </HardyTooltip>
                ) : null}

                { workoutExercise.deload_rule && workoutExercise.deload_rule.deload_type === DeloadType.TM ? (
                  <HardyTooltip message={`Deload: After ${workoutExercise.deload_rule.failed_sessions_trigger_number} uncompleted ${workoutExercise.deload_rule.failed_sessions_trigger_number == 1 ? 'session' : 'sessions'}, Training Max is suggested to decrease by ${workoutExercise.deload_rule.tm_deload_percentage} %`}>
                    <div className="icon-deload"><GraphDown /></div>
                  </HardyTooltip>
                ) : null}
              </Col>
              <Col xs={2} sm={{span: 'auto'}} className="ml-auto">
                <Button className="btn-icon" variant="outline-secondary">
                  <Pencil />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
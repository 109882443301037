import React, { useState } from 'react';
import { FormControlProps } from 'react-bootstrap';
import ValidationFormControlComponent from './ValidationFormControl';

interface Props {
  errors?: { validation?: { [key: string]: string; }}
  name: string;
  inputGroupText: string;
  formCtrl: FormControlProps & any;
}

export default function HardyInputGroupComponent({ errors, name, inputGroupText, formCtrl }: Props) {
  const [touched, setTouched] = useState(false);

  const changeFunc = formCtrl.onChange;
  formCtrl.onChange = (input: any) => {
    setTouched(true);
    changeFunc(input);
  };

  return (
    <div className="custom-input-group custom-input-group--intensity2">
      <ValidationFormControlComponent name={name} formCtrl={formCtrl} errors={errors} hideError={!touched}  />
      <span>
        {inputGroupText}
      </span>
    </div>
  );
}

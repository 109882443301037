import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import { Check, Trash, X, List } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import useRoutineEditorError from '../../../../lib/hooks/useRoutineEditorError';
import slice from '../../../../lib/slices/routine-editor';
import { RootState } from '../../../../lib/store';
import HardyInputComponent from '../../../form/HardyInput';
import HardyTooltip from '../../../ui/HardyTooltip';
import { SetAutoFocusInput } from './SetRowEditorSwitch';

interface SetRowEditorProps {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  autoFocusElement: SetAutoFocusInput
}

enum EditableKeys {
  INTENSITY_REPETITIONS = 'intensity_repetitions',
  REST_TIME = 'rest_time',
  AMRAP = 'amrap'
}

const BodyweightRepSetRowEdit = ({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps, ref: any) => {
  const dispatch = useDispatch();
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}`;
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const set = workoutExercise.sets[setIndex];

  function onSetNumberChange(key: string) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(slice.actions.updateSet({
        workoutIndex,
        exerciseIndex,
        setIndex,
        data: {
          [key]: e.target.value ? Number(e.target.value) : undefined
        }
      }));
    };
  }

  const onSetAmrapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(slice.actions.updateSet({
      workoutIndex,
      exerciseIndex,
      setIndex,
      data: { amrap: e.target.checked }
    }));
  };

  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--edit" ref={ref}>
      <div className="woe-cus-row routine-woe-sets-row">
        <div className="woe-cus-col-1">
          <List className="set-number-drag"/>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-4">
          <div className={set.amrap && !!set.amrap_overload_rules ? 'po-highlight ml-0' : ''}>
            <div className={set.amrap ? (set.amrap_overload_rules ? 'amrap-highlight' : 'amrap-highlight ml-2') : ''}>
              <div className="woe-cus-col--reps-holder-1 text-right">
                <div className={set.amrap ? 'custom-input-group custom-input-group--reps-amrap' : 'custom-input-group custom-input-group--reps'}>
                  <HardyInputComponent
                    hideError={true}
                    name={`${validationPrefix}_${EditableKeys.INTENSITY_REPETITIONS}`}
                    errors={errors}
                    formCtrl={{
                      type: 'number',
                      onChange: onSetNumberChange(EditableKeys.INTENSITY_REPETITIONS),
                      value: String(set.intensity_repetitions),
                      autoFocus: autoFocusElement === SetAutoFocusInput.REPETITIONS,
                      selectOnFocus: autoFocusElement === SetAutoFocusInput.REPETITIONS,
                      min: 0,
                      max: 999
                    }}
                  />
                  {
                    set.amrap ? (
                      <span>
                        +
                      </span>
                    ) : null
                  }
                </div>
              </div>
              <div className="woe-cus-col--reps-holder-2">
                <HardyTooltip message={set.amrap ? 'As Many Reps As Possible enabled' : 'Click to enable As Many Reps As Possible'}>
                  <label className="btn-tag btn-tag--amrap ml-2">
                    <input
                      type="checkbox"
                      id={`${validationPrefix}_${EditableKeys.AMRAP}`}
                      checked={set.amrap}
                      onChange={onSetAmrapChange}
                    />
                    <div className="amrap-tag"><Check className="icon-check" /><X className="icon-x" /> amrap</div>
                  </label>
                </HardyTooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="woe-cus-col-5 text-right">
          <div className="custom-input-group custom-input-group--rest">
            <HardyInputComponent
              hideError={true}
              name={`${validationPrefix}_${EditableKeys.REST_TIME}`}
              errors={errors} formCtrl={{
                type: 'number',
                autoFocus: autoFocusElement === SetAutoFocusInput.REST_TIME,
                selectOnFocus: autoFocusElement === SetAutoFocusInput.REST_TIME,
                onChange: onSetNumberChange(EditableKeys.REST_TIME),
                value: String(set.rest_time),
                min: 0
              }}
            />
            <span>
              sec
            </span>
          </div>
        </div>
        <div className="woe-cus-col-7 text-right">
          <Button className="btn-icon btn-delete" variant="outline-secondary" onClick={() => dispatch(slice.actions.removeSet({
            workoutIndex,
            exerciseIndex,
            setIndex
          }))}>
            <Trash />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(BodyweightRepSetRowEdit);

import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import slice from '../../../lib/slices/routine-editor';
import { RootState } from '../../../lib/store';
import WorkoutExercise from './WorkoutExercise';

interface Props {
  workoutIndex: number;
}

export default function WorkoutExerciseList({ workoutIndex }: Props) {
  const dispatch = useDispatch();
  const workoutExercises = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises);
  return (
    <>
      {workoutExercises.map((woe) => {
        return (
          <WorkoutExercise
            key={woe.index}
            workoutIndex={workoutIndex}
            exerciseIndex={woe.index}
          />
        );
      })}

      <Button className="routine-workout-exercise routine-workout-exercise--add" onClick={() => dispatch(slice.actions.addExercise({ workoutIndex }))}>
        <Form.Group className="mb-0">
          <Row>
            <Col>
              <div className="btn-add-lg"><span className="add-plus mr-2"><PlusCircleFill /></span> Add exercise</div>
            </Col>
          </Row>
        </Form.Group>
      </Button>
    </>
  );
}

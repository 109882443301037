import React, { SyntheticEvent } from 'react';
import { Button } from 'react-bootstrap';
import { Check, Pencil } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../lib/store';
import HardyTooltip from '../../../ui/HardyTooltip';
import { SetAutoFocusInput } from '../row-edit/SetRowEditorSwitch';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  onClick: (autoFocus: SetAutoFocusInput) => void;
}

export default function BodyweightRepSetRow({ workoutIndex, exerciseIndex, setIndex, onClick }: Props) {
  const set = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex]);

  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--clickable" onClick={() => onClick(SetAutoFocusInput.INTENSITY_TIME)}>
      <div className="woe-cus-row routine-woe-sets-row">
        <div className="woe-cus-col-1 text-muted">
          <span className="ml-1">{set.index + 1}</span>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-4">
          <div className={set.amrap_overload_rules ? 'po-highlight ml-0' : 'ml-2'}>
            <span className={set.amrap ? 'amrap-highlight pl-2' : 'ml-2'} onClick={(e: SyntheticEvent) => {
              e.stopPropagation();
              onClick(SetAutoFocusInput.REPETITIONS);
            }
            }>
              <span className={set.amrap ? '' : 'clickable-area-indicator'}>
                <strong className="rep-text">
                  <span className="rep-number">
                    {set.intensity_repetitions}
                  </span>
                  {
                    set.amrap ? (
                      <span className="rep-plus"> +</span>
                    ) : null
                  }
                </strong>
                {
                  set.amrap ? (
                    <HardyTooltip message='As Many Reps As Possible enabled'>
                      <div className="amrap-tag ml-3"><Check /> amrap</div>
                    </HardyTooltip>
                  ) : null
                }
              </span>
            </span>
          </div>
        </div>

        <div className="woe-cus-col-5 text-right" onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          onClick(SetAutoFocusInput.REST_TIME);
        }
        }>
          <span className="mr-3"><span className="clickable-area-indicator">{set.rest_time} sec</span></span>
        </div>
        <div className="woe-cus-col-7 text-right">
          <Button className="btn-icon" variant="outline-secondary">
            <Pencil />
          </Button>
        </div>
      </div>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import authSlice from '../../lib/slices/auth';
import { useDispatch } from 'react-redux';
import { RegisterEmailSchema } from '../../../common/validators/user';
import { createValidator } from '../../lib/core';
import {
  registerEmailRequest,
  // registerFBRequest
} from '../../lib/services/auth.service';
import ValidationFormControlComponent from '../form/ValidationFormControl';
import ValidationErrorComponent from '../form/ValidationError';
import useUnits from '../../lib/hooks/useUnits';

const validateRegisterEmail = createValidator(RegisterEmailSchema);

function StartRegistrationForm () {

  const dispatch = useDispatch();
  const units = useUnits();
  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [errors, setErrors] = useState<any>();

  useEffect(() => {
    async function validate() {
      const { error, valid } = await validateRegisterEmail({
        units,
        email
      });

      setErrors(!valid && error ? error : undefined);
    }
    validate();
    if (email) {
      setEmailTouched(true);
    }

  }, [email]);


  async function onRegisterSubmit(e: any) {
    e.preventDefault();

    const {error, response} = await registerEmailRequest({
      units,
      email
    });

    if (error) {
      setErrors(error);
    }

    if (response) {
      dispatch(authSlice.actions.setUser(response));
    }
  }

  // async function handleFBRegister (res: ReactFacebookLoginInfo) {
  //   const {error, response} = await registerFBRequest({
  //     units,
  //     token: res.accessToken
  //   });

  //   if (error) {
  //     setErrors(error);
  //   }

  //   if (response) {
  //     dispatch(authSlice.actions.setUser(response));
  //   }
  // }

  return (
    <Form onSubmit={onRegisterSubmit}>
      {/* <Row className="mb-3">
        <Col>
          <FacebookLogin
            appId="222152542516399"
            fields="email"
            textButton="Signup with Facebook"
            size="small"
            disableMobileRedirect={true}
            callback={handleFBRegister}
            cssClass="btn btn-lg btn--facebook btn-block"
            icon="fa-facebook"
          />
        </Col>
      </Row> */}

      {/* <Row className="or-divider mb-2">
        <Col><hr/></Col>
        <Col xs="auto">OR</Col>
        <Col><hr/></Col>
      </Row> */}

      <Form.Group >
        <Form.Label>Email address</Form.Label>
        <ValidationFormControlComponent name={'email'} hideError={true} formCtrl={{
          className: 'input-lg mb-2',
          type: 'email',
          placeholder: 'Enter email',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
        }} />

        {!errors || !emailTouched ? (<Form.Text className="text-muted">
          We&apos;ll never share your email with anyone else.
        </Form.Text>): null}
        <Row>
          <Col xs={12} className={'validation-error-message'}>
            <ValidationErrorComponent name={'email'} errors={errors} hide={!emailTouched}/>
          </Col>
        </Row>
      </Form.Group>

      <Row>
        <Col xs={12} className={'validation-error-message'}>
          <ValidationErrorComponent name={'general'} errors={errors} />
        </Col>
      </Row>

      <Form.Group className="text-center mt-4">
        <Button variant="primary" size="lg" type="submit" block disabled={!!errors}>
          Continue
        </Button>
      </Form.Group>

      <Row>
        <Col>
          <Link className="btn btn-outline btn-outline-secondary mt-2 btn-block btn-lg" to={'/login'} >Login</Link>
        </Col>
      </Row>
    </Form>
  );
}

export default StartRegistrationForm;

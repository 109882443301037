import { WorkoutCreateRequestData, WorkoutUpdateRequestData,  WorkoutModel } from './workout';

export enum RoutineStatus {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export interface RoutineModel {
  id: number;
  name: string;
  slug: string;
  description: string;
  user_id: number;
  status: RoutineStatus;
  tm_percent_from_1rm: number;
  max_weeks: number;
  old_routine_id?: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface RoutineResponse extends RoutineModel{
  username: string;
}

export interface RoutineUpdateData {
  name: string;
  description?: string;
  tm_percent_from_1rm: number;
  max_weeks: number;
  old_routine_id?: number;
}

export interface RoutineCreateData {
  name: string;
  description: string;
  tm_percent_from_1rm: number;
  old_routine_id?: number;
  max_weeks: number;
  user_id: number;
}

export interface RoutineFullUpdateData {
  name: string;
  description: string;
  tm_percent_from_1rm: number;
  max_weeks: number;
  old_routine_id?: number;
  workouts: WorkoutUpdateRequestData[];
}

export interface RoutineFullData {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: RoutineStatus;
  user_id: number;
  username: string;
  tm_percent_from_1rm: number;
  max_weeks: number;
  old_routine_id?: number;
  workouts: WorkoutModel[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

import { ExerciseType } from './exercise';
import { WorkoutExerciseSetCreateData, WorkoutExerciseSetUpdateData, WorkoutExerciseSetData } from './workout-exercise-set';

export enum WorkoutExerciseType {
  WEIGHT = 'weight',
  BODYWEIGHT_TIME = 'bodyweight_time',
  BODYWEIGHT_REP = 'bodyweight_rep'
}

export enum WeightSetType {
  NO_SUGGESTIONS = 'no_suggestions',
  TM = 'tm',
  RPE = 'rpe',
  _1RM = '1rm'
}

export enum DeloadType {
  TM = 'tm',
  _1RM = '1rm'
}

export enum OverloadType {
  TM = 'tm',
  _1RM = '1rm'
}

export interface WorkoutExerciseDeloadRuleTM {
  routine_id?: number;
  failed_sessions_trigger_number: number;
  deload_type: DeloadType.TM;
  tm_deload_percentage: number;
}

export interface WorkoutExerciseDeloadRule1RM {
  routine_id?: number;
  failed_sessions_trigger_number: number;
  deload_type: DeloadType._1RM;
  deload_percentage_1rm: number;
}

export type WorkoutExerciseDeloadRule = WorkoutExerciseDeloadRule1RM | WorkoutExerciseDeloadRuleTM;

export interface WorkoutExerciseOverloadRuleTM {
  routine_id?: number;
  successful_sessions_trigger_number: number;
  tm_overload_amount: number;
  overload_type: OverloadType.TM;
}

export interface WorkoutExerciseOverloadRule1RM {
  routine_id?: number;
  successful_sessions_trigger_number: number;
  overload_amount_1rm: number;
  overload_type: OverloadType._1RM;
}

export type WorkoutExerciseOverloadRule = WorkoutExerciseOverloadRuleTM | WorkoutExerciseOverloadRule1RM;

export interface WorkoutExerciseData {
  id: number;
  index: number;
  workout_id: number;
  routine_id: number;
  exercise_id: number;
  exercise_label: string;
  exercise_type: ExerciseType;
  workout_exercise_type: WorkoutExerciseType;
  deload_rule?: WorkoutExerciseDeloadRule;
  overload_rule?: WorkoutExerciseOverloadRule;
  intensity_weight_tm_percentage_exercise_id?: number;
  intensity_weight_tm_percentage_exercise_label?: string;
  intensity_weight_tm_percentage_exercise_type?: ExerciseType;
  sets: WorkoutExerciseSetData[];
}

export interface WorkoutExerciseCreateRequestData {
  exercise_id: number;
  index: number;
  workout_exercise_type: WorkoutExerciseType;
  intensity_weight_tm_percentage_exercise_id?: number;
  deload_rule?: WorkoutExerciseDeloadRule | null;
  overload_rule?: WorkoutExerciseOverloadRule | null;
  sets: WorkoutExerciseSetCreateData[];
}

export interface WorkoutExerciseUpdateRequestData {
  id?: number;
  index: number;
  exercise_id: number;
  workout_exercise_type: WorkoutExerciseType;
  intensity_weight_tm_percentage_exercise_id?: number;
  deload_rule?: WorkoutExerciseDeloadRule | null;
  overload_rule?: WorkoutExerciseOverloadRule | null;
  sets: WorkoutExerciseSetUpdateData[];
}

import React from 'react';
import './Footer.scss';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import Logo from '../../assets/hardy-logo.svg';
import { Instagram } from 'react-bootstrap-icons';
import RedditImg from '../../assets/icon-reddit.svg';
import appleStoreBadge from '../../assets/apple-appstore-badge.png';
import googleStoreBadge from '../../assets/google-play-badge.png';
import { Link } from 'react-router-dom';

//@ts-ignore
import { NavHashLink } from 'react-router-hash-link';

function FooterComponent () {

  return (
    <>
      <Container fluid className="footer">
        <Container>
          <Row>
            <Col xs={12} sm={4} md={4}>
              <img className="nav-logo mb-4" src={Logo} alt="Hardy" />
              <p className="footer-text">
                Made with 💪 in Europe.<br/>
                Please note we just launched,<br/>
                if you notice any bugs let us know.
              </p>
            </Col>
            <Col xs={12} sm={8} md={8}>
              <Row>
                <Col>
                  <Nav className="flex-column">
                    {/*<Nav.Item><h5 className="">About</h5></Nav.Item>*/}
                    <Nav.Link className="footer-nav-link" tabIndex={0} as={Link} to="/">
                      Home
                    </Nav.Link>
                    <Nav.Link className="footer-nav-link" tabIndex={0} as={Link} to="/routines">
                      Routines
                    </Nav.Link>
                    <Nav.Link className="footer-nav-link" tabIndex={0} as={Link} to="/about">
                      About us
                    </Nav.Link>
                    <NavHashLink className="footer-nav-link nav-link" tabIndex={0} to="/#faq" smooth>
                      FAQ
                    </NavHashLink>
                    <Nav.Link className="footer-nav-link" tabIndex={0} as={Link} to="/download">
                      Download app
                    </Nav.Link>
                  </Nav>
                </Col>
                <Col>
                  <Nav className="flex-column">
                    <Nav.Item>
                      <a className="d-inline-block appstore-img appstore-img--apple mr-3 mt-2 mb-3" href="https://apps.apple.com/app/hardy-smart-workout-routines/id1554129445" target="_blank" rel="noreferrer"><img src={appleStoreBadge} alt="Download Hardy weight lifting app for iPhone on Apple App Store" /></a>
                    </Nav.Item>
                    <Nav.Item>
                      <a className="d-inline-block appstore-img appstore-img--google mt-2 mb-3" href="https://play.google.com/store/apps/details?id=com.hardyapp.hardy" target="_blank" rel="noreferrer"><img src={googleStoreBadge} alt="Download Hardy weight lifting app for iPhone on Google App Store" /></a>
                    </Nav.Item>
                    <a className="footer-nav-link nav-link" tabIndex={0} href="https://www.reddit.com/r/hardyapp" target="_blank" rel="noreferrer">
                      <img src={RedditImg} className="footer-icon-reddit mr-2" alt="Reddit icon" /> /r/HardyApp
                    </a>
                    <a className="footer-nav-link nav-link" tabIndex={0} href="https://www.instagram.com/hardy.app" target="_blank" rel="noreferrer">
                      <Instagram color={'white'} size={30} className="footer-icon-instagram mr-2" /> @Hardy.app
                    </a>
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className="footer-divider" />
          <Row>
            <Col>
              <Nav className="justify-content-end">
                <Nav.Link className="footer-muted-nav-link" tabIndex={0} as={Link} to="/privacypolicy">
                  Privacy Policy
                </Nav.Link>
                <Nav.Link className="footer-muted-nav-link" tabIndex={0} as={Link} to="/termsandconditions">
                  Terms & Conditions
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default FooterComponent;


import React, { useContext, createContext, ReactNode } from 'react';
import { WorkoutExerciseSetData } from '../../../common/types';

export const PreviewSetContext = createContext<WorkoutExerciseSetData | undefined>(undefined);

export function usePreviewSet () {
  return useContext(PreviewSetContext);
}

export function PreviewSetContextProvider ({children, set }: { children: ReactNode, set: WorkoutExerciseSetData }) {
  return (
    <PreviewSetContext.Provider value={set}>
      {children}
    </PreviewSetContext.Provider>
  );
}
import React from 'react';
import { Form } from 'react-bootstrap';
import { UserUnit } from '../../../common/types/index';
import ValidationErrorComponent from '../form/ValidationError';
import UnitSelect from '../units/UnitSelect';

interface UpdateData {
  units: UserUnit;
  username?: string;
  password?: string;
  password_repeat?: string;
}

interface UpdateUserProps {
  updateData: UpdateData;
  onChange: any;
  errors: any;
}

function UpdateUserComponent ({
  updateData,
  onChange,
  errors
}: UpdateUserProps) {
  return (<>
    <Form.Group>
      <Form.Label>Units</Form.Label>
      <UnitSelect
        onSelected={(unit: UserUnit) => {
          onChange({
            ...updateData,
            units: unit
          });
        }}
        defaultValue={updateData.units}
      />
      <ValidationErrorComponent name={'units'} errors={errors} />
    </Form.Group>
  </>);
}

export default UpdateUserComponent;

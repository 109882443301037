import React from 'react';
import { FormControlProps, Form } from 'react-bootstrap';
import { hasValidationError } from '../../helpers/form';

interface Props {
  errors?: { validation?: { [key: string]: string; }}
  hideError?: boolean;
  name: string;
  formCtrl: FormControlProps & any;
}

export default function ValidationFormControlComponent({ errors, name, formCtrl }: Props) {

  const errorClass = hasValidationError(name, errors) ? ' validation-error-input ' : ' ';
  const controlProps = {...formCtrl, className: errorClass + (formCtrl.className || '')};

  return <Form.Control {...controlProps} />;
}

import { ExerciseData, ExerciseType, WorkoutExerciseType } from '../../common/types';

export function determineWorkoutExerciseType(exerciseCacheMap: {[key:string]: ExerciseData}, exerciseId: number): WorkoutExerciseType {
  const exercise = exerciseCacheMap[exerciseId];

  if (exercise?.type === ExerciseType.BODYWEIGHT_TIME) {
    return WorkoutExerciseType.BODYWEIGHT_TIME;
  }

  if (exercise?.type === ExerciseType.BODYWEIGHT_REP) {
    return WorkoutExerciseType.BODYWEIGHT_REP;
  }

  return WorkoutExerciseType.WEIGHT;
}
import { RouteRoutinePutStatusBody, RoutineFullData } from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import RoutineService from '../services/routine.service';

export interface RoutineOneFullState {
    data?: RoutineFullData;
    loading: boolean;
    error?: any;
}

const initialState: RoutineOneFullState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'routineOneFull',
  initialState,
  reducers: {
    routineOneFullPending (state) {
      state.loading = true;
    },
    routineOneFullFulfilled (state, action: PayloadAction<RoutineFullData>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    routineOneFullRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetRoutineOne (state) {
      state.data = undefined;
      state.loading = false;
      state.error = undefined;
    }
  },
});

export function getFullRoutine(id: string) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.routineOneFullPending());
    const action = RoutineService.getFullAction({ id }, {
      onSuccess: (r) => {dispatch(slice.actions.routineOneFullFulfilled(r));},
      onFailure: (e) => {dispatch(slice.actions.routineOneFullRejected(e));}
    });
    dispatch(action);
  };
}

export function setRoutineStatus(id: string, body: RouteRoutinePutStatusBody) {
  return (dispatch: Dispatch) => {
    const action = RoutineService.updateStatusAction({ id, body }, {
      // @ts-ignore
      onSuccess: () => {dispatch(getFullRoutine(id));},
      onFailure: (e) => {dispatch(slice.actions.routineOneFullRejected(e));}
    });
    dispatch(action);
  };
}

export function softDeleteRoutine(id: string) {
  return (dispatch: Dispatch) => {
    const action = RoutineService.softDeleteAction({ id }, {
      // @ts-ignore
      onSuccess: () => {dispatch(getFullRoutine(id));},
      onFailure: (e) => {dispatch(slice.actions.routineOneFullRejected(e));}
    });
    dispatch(action);
  };
}

export default slice;

import { useSelector } from 'react-redux';
import { UserUnit } from '../../../common/types';
import { RootState } from '../store';

export default function useUnits(): UserUnit {
  const userUnit = useSelector((state: RootState) => state.authState.data?.units);
  const guestUnit = useSelector((state: RootState) => state.authState.guestUnit);

  return userUnit || guestUnit;
}

import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { ExerciseType, UserUnit } from '../../../common/types/index';
import { formatWeight, getUnitLabel, weightMinimalStepPerType } from './Weight';

interface Props {
  units: UserUnit;
  defaultValue: number;
  onChange: (grams: number) => void;
  type: ExerciseType;
}

export default function WeightPicker({ units, defaultValue, onChange, type }: Props) {
  const [weightGrams, setWeightGrams] = useState(defaultValue);
  const [weightReadable, setWeightReadable] = useState(formatWeight(weightGrams, type, {
    units: units,
    valueOnly: true,
    roundToUsableWeight: true
  }));

  useEffect(() => {
    let grams = 0;
    if (units === UserUnit.IMPERIAL) {
      grams = Math.floor(Number(weightReadable) * 454);
    } else if (units === UserUnit.METRIC) {
      grams = Math.floor(Number(weightReadable) * 1000);
    }

    if (grams === 0) {
      return;
    }
    setWeightGrams(grams);
    onChange(grams);
  }, [weightReadable, defaultValue, units]);

  return (<>
    <div className="custom-input-group custom-input-group--weight">
      <FormControl type={'number'} step={
        //@ts-ignore
        weightMinimalStepPerType[type][units] / 1000
      } onChange={(e: any) => {
        setWeightReadable(e.target.value);
      }} value={weightReadable} placeholder="0" />
      <span>
        {getUnitLabel(units)}
      </span>
    </div>
  </>);
}

import React, { forwardRef } from 'react';
import { WorkoutExerciseType } from '../../../../../common/types';
import useEditorWorkoutExerciseType from '../../../../lib/hooks/useEditorWorkoutExerciseType';
import BodyweightRepSetRowEdit from './BodyweightRepSetRowEdit';
import BodyweightTimeSetRowEdit from './BodyweightTimeSetRowEdit';
import WeightSetRowEdit from './WeightSetRowEdit';

export enum SetAutoFocusInput {
  TM_PERCENTAGE,
  REPETITIONS,
  REST_TIME,
  INTENSITY_TIME,
  _1RM_PERCENTAGE,
  RPE
}

interface SetRowEditorProps {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  autoFocusElement: SetAutoFocusInput
}

const SetRowEditorSwitch = ({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps, ref: any) => {
  const woeType = useEditorWorkoutExerciseType({ workoutIndex, exerciseIndex });

  if (woeType === WorkoutExerciseType.BODYWEIGHT_TIME) {
    return (<BodyweightTimeSetRowEdit workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} autoFocusElement={autoFocusElement} ref={ref} />);
  }

  if (woeType === WorkoutExerciseType.BODYWEIGHT_REP) {
    return (<BodyweightRepSetRowEdit workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} autoFocusElement={autoFocusElement} ref={ref} />);
  }

  return (<WeightSetRowEdit workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} autoFocusElement={autoFocusElement}  ref={ref} />);
};

export default forwardRef(SetRowEditorSwitch);

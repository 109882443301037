import React, { useEffect, useState } from 'react';
import { Button, Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { recoverySetPasswordRequest } from '../../lib/services/auth.service';
import { Redirect, useRouteMatch } from 'react-router';
import ValidationErrorComponent from '../form/ValidationError';
import { createValidator } from '../../lib/core';
import { PasswordRecoverSchema } from '../../../common/validators/user';

const recoverPasswordValidator = createValidator(PasswordRecoverSchema);

function RecoverAccountComponent () {
  const routeParams = useRouteMatch<{token: string;}>();
  const token = routeParams.params.token;

  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<any>();

  useEffect(() => {
    if (password) {
      setPasswordTouched(true);
    }

    async function validate() {
      const {valid, error} = await recoverPasswordValidator({ password, token});
      setErrors(valid ? undefined : error);
    }

    validate();
  }, [password]);

  async function onSetPasswordSubmit(e: any) {
    e.preventDefault();
    setLoading(true);

    try {
      const {error} = await recoverySetPasswordRequest({
        token,
        password
      });

      if (error) {
        setErrors(error);
      } else {
        setSuccess(true);
      }

    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  if (loading) {
    return <div className="page-loading"><Spinner animation="grow" /></div>;
  }

  if (success) {
    return <Redirect to={{
      pathname: '/login',
      state: {
        redirectPath: '/',
        successfulRecovery: true
      }
    }} />;
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col lg={{ span: 4, offset: 4 }}>
          <h1 className="text-center mt-5 caps-condensed">Create New Password</h1>

          <div className="content-area mb-4">
            <Form onSubmit={onSetPasswordSubmit}>
              <Form.Group>
                <Form.Label>New password</Form.Label>
                <Form.Control
                  className={errors?.password ? 'validation-error-input input-lg mb-2' : 'input-lg mb-2'}
                  type="password"
                  placeholder="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Row>
                <Col xs={12} className={'validation-error-message'}>
                  <ValidationErrorComponent name="password" errors={errors} hide={!passwordTouched} />
                  <ValidationErrorComponent name="token" errors={errors} hide={!passwordTouched} />
                </Col>
              </Row>
              <Form.Group className="text-center mt-4">
                <Button variant="primary" size="lg" type="submit" block disabled={!!errors}>
                  Save
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default RecoverAccountComponent;

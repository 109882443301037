import { ListQuery, RoutineResponse } from '../../../common/types/index';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import RoutineService from '../services/routine.service';

export interface RoutineListState {
    list: RoutineResponse[];
    count: number;
    loading: boolean;
    error?: any;
}

const initialState: RoutineListState = {
  list: [],
  count: 0,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'routineList',
  initialState,
  reducers: {
    listRoutinePending (state) {
      state.loading = true;
    },
    listRoutineFulfilled (state, action: PayloadAction<{items: RoutineResponse[]; count: number;}>) {
      state.loading = false;
      state.list = action.payload.items;
      state.count = action.payload.count;
      state.error = undefined;
    },
    listRoutineRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export function listRoutine(query: Partial<ListQuery>) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.listRoutinePending());
    const action = RoutineService.listAction({ query }, {
      onSuccess: (r) => {dispatch(slice.actions.listRoutineFulfilled(r));},
      onFailure: (e) => {dispatch(slice.actions.listRoutineRejected(e));}
    });
    dispatch(action);
  };
}

export default slice;

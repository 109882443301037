import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import authSlice, { selectUser } from '../../lib/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import { UserUnit } from '../../../common/types/model/user';
import { UserCompleteSignupInputSchema } from '../../../common/validators/user';
import { createValidator } from '../../lib/core';
import { completeSignupRequest } from '../../lib/services/auth.service';
import ValidationErrorComponent from '../form/ValidationError';
import HardyInputComponent from '../form/HardyInput';
import HardyUnitsDropdownComponent from '../form/HardyUnitsDropdown';
import useUnits from '../../lib/hooks/useUnits';
import { useHistory } from 'react-router';

const validateCompleteSignup = createValidator(UserCompleteSignupInputSchema);

function CompleteRegistrationForm () {

  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.authState);
  const user = useSelector(selectUser);

  const defaultUnits = useUnits();
  const [units, setUnits] = useState(defaultUnits);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState<string | undefined>();
  const [errors, setErrors] = useState<any>();

  const history = useHistory();
  useEffect(() => {
    if (password === '') {
      setPassword(undefined);
    }
  }, [password]);

  useEffect(() => {
    async function validate() {
      const { error, valid } = await validateCompleteSignup({
        units,
        username,
        password,
        email
      });

      setErrors(!valid && error ? error : undefined);
    }
    validate();
  }, [units, username, password, email]);

  async function onUpdateSubmit(e: any) {
    e.preventDefault();

    const {error, response} = await completeSignupRequest({
      units,
      username,
      password,
      email: authState.data?.email ? undefined : email
    });

    if (error) {
      setErrors(error);
    }

    if (response) {
      dispatch(authSlice.actions.setUser(response));
      history.push('/routines');
    }
  }

  return (
    <Form onSubmit={onUpdateSubmit}>
      { !user?.email ? (
        <Form.Group >
          <Form.Label>Email address</Form.Label>
          <Form.Control className="input-lg mb-2" type="email" placeholder="Enter email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
          <Form.Text className="text-muted">
            We&apos;ll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
      ) : null}

      <Form.Group>
        <Form.Label>Username</Form.Label>
        <HardyInputComponent name={'un'} errors={errors} formCtrl={{
          className: 'input-lg mb-2',
          type:'text',
          placeholder: 'Username',
          autoComplete: 'none',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)
        }} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Password (optional)</Form.Label>
        <HardyInputComponent name={'pw'} errors={errors} formCtrl={{
          className: 'input-lg',
          type:'text',
          placeholder: 'Password',
          autoComplete: 'none',
          style: { textSecurity: 'disk', WebkitTextSecurity: 'disc' },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
        }} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Units</Form.Label>

        <HardyUnitsDropdownComponent name={'units'} errors={errors} formCtrl={{
          onSelected: (unit: UserUnit) => setUnits(unit),
          defaultValue: units
        }} />
      </Form.Group>

      <Row>
        <Col xs={12} className={'validation-error-message'}>
          <ValidationErrorComponent name={'general'} errors={errors} />
        </Col>
      </Row>

      <Form.Group className="text-center mt-4">
        <Button variant="primary" size="lg" type="submit" block>
          Register
        </Button>
      </Form.Group>
    </Form>
  );
}

export default CompleteRegistrationForm;

import React from 'react';
import { ExerciseType, UserUnit } from '../../../common/types/index';

interface Options {
  units: UserUnit;
  valueOnly?: boolean;
  roundToUsableWeight?: boolean;
}

interface Props {
  weight: number;
  type: ExerciseType;
  options: Options;
}

export const weightMinimalStepPerType = {
  [ExerciseType.BARBELL]: {
    [UserUnit.METRIC]: 2500,
    [UserUnit.IMPERIAL]: 5000
  },
  [ExerciseType.DUMBBELL]: {
    [UserUnit.METRIC]: 500,
    [UserUnit.IMPERIAL]: 1000
  },
  [ExerciseType.KETTLEBELL]: {
    [UserUnit.METRIC]: 2000,
    [UserUnit.IMPERIAL]: 2000
  },
  [ExerciseType.MACHINE]: {
    [UserUnit.METRIC]: 500,
    [UserUnit.IMPERIAL]: 1000
  },
  [ExerciseType.PLATE]: {
    [UserUnit.METRIC]: 2500,
    [UserUnit.IMPERIAL]: 5000
  },
};

const PRECISION = 2;

function roundToUsableWeight(weightThousanth: number, unit: UserUnit, type: ExerciseType): number {
  //@ts-ignore
  const step = weightMinimalStepPerType[type][unit];
  const roundedWeight =  Math.round(weightThousanth / step) * step;

  return roundToPercision(roundedWeight, PRECISION);
}

function roundToPercision(weightThousanth: number, precision: number) {
  const lbsOrKg = weightThousanth / 1000;
  const precisionConst = Math.pow(10, precision);
  return Math.floor(lbsOrKg * precisionConst) / precisionConst;
}

export function getWeightStep(unit: UserUnit, type: ExerciseType = ExerciseType.BARBELL): number {
  //@ts-ignore
  return weightMinimalStepPerType[type][unit];
}

export function doesExerciseUseWeight(type: ExerciseType): boolean {
  return !(type === ExerciseType.BODYWEIGHT_REP || type === ExerciseType.BODYWEIGHT_TIME);
}

// to 1/1000 kg or 1/1000 lbs
export function gramsToUnitConverter (grams: number, unit: UserUnit): number {
  switch (unit) {
  case UserUnit.IMPERIAL:
    return Math.floor(grams * 1000 / 454);
  case UserUnit.METRIC:
    return grams;
  }
}

export function getUnitLabel (unit: UserUnit) {
  const unitMap = {
    [UserUnit.METRIC]: 'kg',
    [UserUnit.IMPERIAL]: 'lbs'
  };

  return unitMap[unit];
}

export function formatWeight(weightInGrams: number, type: ExerciseType, options: Options) : number | string {
  let weightValue = gramsToUnitConverter(weightInGrams, options.units);

  if (options.roundToUsableWeight) {
    weightValue = roundToUsableWeight(weightValue, options.units, type);
  } else {
    weightValue = roundToPercision(weightValue, PRECISION);
  }

  if (options.valueOnly) {
    return weightValue;
  }

  const label = getUnitLabel(options.units);
  return `${weightValue} ${label}`;
}

export default function Weight({ weight, type, options }: Props) {
  return (<>
    {formatWeight(weight, type, options)}
  </>);
}

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { RootState } from '../../lib/store';
import { useSelector } from 'react-redux';

type Props = {
  path: string;
  component: any
} & RouteProps;

const PrivateAdminRoute = (props: Props) => {
  const { component: Component, ...rest } = props;

  const auth = useSelector((state: RootState) => state.authState);

  const isUserLoading = auth.loading;
  const isLoggedIn = !!auth.data && !!auth.data.id;
  const isUserAdmin = auth.data?.admin;

  if (isUserLoading) {
    return (<div className="page-loading"><Spinner animation="grow" /></div>);
  }

  return (
    <Route {...rest} render={(props) => {
      if (isLoggedIn && isUserAdmin) {
        // @ts-ignore
        return <Component {...props} />;
      }
      return <Redirect to={{ pathname: '/' }} />;
    }}/>
  );
};

export default PrivateAdminRoute;

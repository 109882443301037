import React, { useContext, createContext, ReactNode } from 'react';
import { RoutineFullData } from '../../../common/types';

export const PreviewRoutineContext = createContext<RoutineFullData | undefined>(undefined);

export function usePreviewRoutine () {
  return useContext(PreviewRoutineContext);
}

export function PreviewRoutineContextProvider ({children, routine }: { children: ReactNode, routine: RoutineFullData }) {
  return (
    <PreviewRoutineContext.Provider value={routine}>
      {children}
    </PreviewRoutineContext.Provider>
  );
}
import { combineReducers } from 'redux';
import routineListSlice from './routine.list';
import routineSubscribedListSlice from './routine.subscribed-list';
import routineOwnListSlice from './routine.own-list';
import routineCreateSlice from './routine.create';
import routineUpdateFullSlice from './routine.update-full';
import routineOneFullSlice from './routine.one-full';
import exerciseListSlice from './exercise.list';
import exerciseCacheSlice from './exercise-cache';
import authSlice from './auth';
import completeSignupSlice from './complete-signup';
import updateUserSlice from './update-user';
import routineEditorSlice from './routine-editor';

export default combineReducers({
  routineListState: routineListSlice.reducer,
  routineSubscribedListState: routineSubscribedListSlice.reducer,
  routineOwnListState: routineOwnListSlice.reducer,
  routineCreateState: routineCreateSlice.reducer,
  routineUpdateFullState: routineUpdateFullSlice.reducer,
  routineOneFullState: routineOneFullSlice.reducer,
  exerciseListState: exerciseListSlice.reducer,
  exerciseCacheState: exerciseCacheSlice.reducer,
  authState: authSlice.reducer,
  completeSignup: completeSignupSlice.reducer,
  updateUser: updateUserSlice.reducer,
  routineEditorState: routineEditorSlice.reducer
});

import React from 'react';
import BodyweightTimeSetRow from './BodyweightTimeSetRow';
import BodyweightRepSetRow from './BodyweightRepSetRow';
import { SetAutoFocusInput } from '../row-edit/SetRowEditorSwitch';
import WeightSetRow from './WeightSetRow';
import useEditorWorkoutExerciseType from '../../../../lib/hooks/useEditorWorkoutExerciseType';
import { WorkoutExerciseType } from '../../../../../common/types';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  onClick: (autoFocus: SetAutoFocusInput) => void;
}

function SetRowSwitch ({workoutIndex, exerciseIndex, setIndex, onClick}: Props) {
  const woeType = useEditorWorkoutExerciseType({ workoutIndex, exerciseIndex });

  if (woeType === WorkoutExerciseType.BODYWEIGHT_TIME) {
    return <BodyweightTimeSetRow workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} onClick={onClick} />;
  }

  if (woeType === WorkoutExerciseType.BODYWEIGHT_REP) {
    return <BodyweightRepSetRow workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} onClick={onClick} />;
  }

  return <WeightSetRow workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} onClick={onClick} />;
}

export default SetRowSwitch;
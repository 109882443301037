import { useSelector } from 'react-redux';
import { WorkoutExerciseData, WorkoutExerciseType } from '../../../common/types';
import { usePreviewWorkoutExercise } from '../../components/context/PreviewWorkoutExerciseContext';
import { determineWorkoutExerciseType } from '../../helpers/workout-exercise-type';
import { RootState } from '../store';

export default function usePreviewWorkoutExerciseType(): WorkoutExerciseType {
  const workoutExercise = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exerciseCache = useSelector((state: RootState) => state.exerciseCacheState.cacheMap);

  return determineWorkoutExerciseType(exerciseCache, workoutExercise.exercise_id);
}

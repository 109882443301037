import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../lib/store';
import SetsEditor from '../../set/SetsEditor';


interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

export default function BodyweightRepBody({ workoutIndex, exerciseIndex }: Props) {
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);

  if (!exercise) {
    return null;
  }

  return (
    <SetsEditor workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
  );
}
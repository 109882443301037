import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { sendRecoveryRequest } from '../../lib/services/auth.service';

function ForgotPasswordComponent () {

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  async function onRecoverSubmit(e: any) {
    e.preventDefault();
    setLoading(true);

    try {
      await sendRecoveryRequest({
        email
      });
      setSuccessMessage(true);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  if (loading) {
    return <div className="page-loading"><Spinner animation="grow" /></div>;
  }

  if (successMessage) {
    return (
      <Container className="pt-4">
        <Row>
          <Col lg={{ span: 4, offset: 4 }}>
            <h1 className="text-center mt-5 caps-condensed">Recover password</h1>

            Recovery e-mail has been sent, please check your inbox.
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col lg={{ span: 4, offset: 4 }}>
          <h1 className="text-center mt-5 caps-condensed">Recover password</h1>

          <div className="content-area mb-4">
            <Form onSubmit={onRecoverSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control className="input-lg mb-2" type="email" placeholder="Enter email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
              </Form.Group>

              <Form.Group className="text-center mt-4">
                <Button variant="primary" size="lg" type="submit" block>
                  Recover
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPasswordComponent;

export enum ExerciseType {
  BARBELL = 'barbell',
  DUMBBELL = 'dumbbell',
  KETTLEBELL = 'kettlebell',
  MACHINE = 'machine',
  PLATE = 'plate',
  BODYWEIGHT_TIME = 'bodyweight-time',
  BODYWEIGHT_REP = 'bodyweight-rep'
}

export interface ExerciseData {
  id: number;
  name: string;
  type: ExerciseType
  alternative_names: string;
  user_id?: number;
}

export type ExerciseInsertData = Omit<ExerciseData, "id" | "alternative_names">;
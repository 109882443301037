import React, { useState } from 'react';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import { ChevronDown, ChevronRight, GraphDown, GraphUp } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import { DeloadType, OverloadType, WeightSetType, WorkoutExerciseData } from '../../../../common/types';
import usePublicPrivateExercise from '../../../lib/hooks/usePublicPrivateExercise';
import useUnits from '../../../lib/hooks/useUnits';
import { PreviewSetContextProvider } from '../../context/PreviewSetContext';
import { usePreviewWorkoutExercise } from '../../context/PreviewWorkoutExerciseContext';
import HardyTooltip from '../../ui/HardyTooltip';
import { formatWeight } from '../../weight/Weight';
import WeightSet from '../set/WeightSet';

function WeightExercisePreviewOverload() {
  const units = useUnits();
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);
  const tmExercise = usePublicPrivateExercise(woe.intensity_weight_tm_percentage_exercise_id || woe.exercise_id);

  if (!woe.overload_rule || !exercise) {
    return null;
  }

  if (woe.overload_rule.overload_type === OverloadType._1RM) {
    return (
      <div className="mt-0">
        <Form.Group className="routine-woe-sets mb-0">
          <div className="routine-woe-sets-row-holder">
            <Row className="routine-woe-sets-row" noGutters>
              <Col xs={12} className="d-flex">
                <div className="icon-overload"><GraphUp /></div> <p><b>Overload:</b> After <b>{woe.overload_rule.successful_sessions_trigger_number}</b> successful {woe.overload_rule.successful_sessions_trigger_number === 1 ? 'session' : 'sessions'}, <b>1RM</b> will be suggested to increase by <b>{formatWeight(woe.overload_rule.overload_amount_1rm, exercise.type, { units })}</b>.</p>
              </Col>
            </Row>
          </div>
        </Form.Group>
      </div>
    );  
  }

  if (woe.overload_rule.overload_type === OverloadType.TM && tmExercise) {
    return (
      <div className="mt-0">
        <Form.Group className="routine-woe-sets mb-0">
          <div className="routine-woe-sets-row-holder">
            <Row className="routine-woe-sets-row" noGutters>
              <Col xs={12} className="d-flex">
                <div className="icon-overload"><GraphUp /></div> <p><b>Overload:</b> After <b>{woe.overload_rule.successful_sessions_trigger_number}</b> successful {woe.overload_rule.successful_sessions_trigger_number === 1 ? 'session' : 'sessions'}, <b>Training Max</b> will be suggested to increase by <b>{formatWeight(woe.overload_rule.tm_overload_amount, exercise.type, { units })}</b>.</p>
              </Col>
            </Row>
          </div>
        </Form.Group>
      </div>
    );
  }

  return null;
}

function WeightExercisePreviewDeload() {
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);
  const tmExercise = usePublicPrivateExercise(woe.intensity_weight_tm_percentage_exercise_id || woe.exercise_id);

  if (!woe.deload_rule || !exercise) {
    return null;
  }

  if (woe.deload_rule.deload_type === DeloadType._1RM) {
    return (
      <div className="mt-0">
        <Form.Group className="routine-woe-sets mb-0">
          <div className="routine-woe-sets-row-holder">
            <Row className="routine-woe-sets-row" noGutters>
              <Col xs={12} className="d-flex">
                <div className="icon-deload"><GraphDown /></div> <p><b>Deload:</b> After <b>{woe.deload_rule.failed_sessions_trigger_number}</b> uncompleted {woe.deload_rule.failed_sessions_trigger_number == 1 ? 'session' : 'sessions'}, <b>1RM</b> will be suggested to decrease by <b>{woe.deload_rule.deload_percentage_1rm} %</b></p>
              </Col>
            </Row>
          </div>
        </Form.Group>
      </div>
    );  
  }

  if (woe.deload_rule.deload_type === DeloadType.TM && tmExercise) {
    return (
      <div className="mt-0">
        <Form.Group className="routine-woe-sets mb-0">
          <div className="routine-woe-sets-row-holder">
            <Row className="routine-woe-sets-row" noGutters>
              <Col xs={12} className="d-flex">
                <div className="icon-deload"><GraphDown /></div> <p><b>Deload:</b> After <b>{woe.deload_rule.failed_sessions_trigger_number}</b> uncompleted {woe.deload_rule.failed_sessions_trigger_number == 1 ? 'session' : 'sessions'}, <b>Training Max</b> will be suggested to decrease by <b>{woe.deload_rule.tm_deload_percentage} %</b></p>
              </Col>
            </Row>
          </div>
        </Form.Group>
      </div>
    );
  }

  return null;
}

function WeightExercisePreviewTmBasis() {
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const tmExercise = usePublicPrivateExercise(woe.intensity_weight_tm_percentage_exercise_id as number);
  const anyTmSets = woe.sets.some(s => s.weight_set_type === WeightSetType.TM);

  if (!tmExercise?.id || !anyTmSets || woe.intensity_weight_tm_percentage_exercise_id === woe.exercise_id) {
    return null;
  }

  return (
    <Row className="routine-woe-sets" noGutters>
      <Col>
        <div className="routine-woe-sets-row-holder">
          <Row className="routine-woe-sets-row" noGutters>
            <Col xs={12}>
              <p className="mb-0">* Training Max (TM) percentage is based on your <b className="">{tmExercise?.name}</b> TM.</p>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

function OverloadRuleTooltip () {
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const units = useUnits();
  const exercise = usePublicPrivateExercise(woe.exercise_id);

  if (!woe.overload_rule || !exercise) {
    return null;
  }

  if (woe.overload_rule.overload_type === OverloadType._1RM) {
    return (
      <HardyTooltip
        message={`Overload: After ${woe.overload_rule.successful_sessions_trigger_number} successful ${woe.overload_rule.successful_sessions_trigger_number == 1 ? 'session' : 'sessions'}, 1RM is suggested to increase by ${formatWeight(woe.overload_rule.overload_amount_1rm, exercise.type, { units })}.`}
      >
        <div className="icon-overload"><GraphUp /></div>
      </HardyTooltip>
    );
  }

  if (woe.overload_rule.overload_type === OverloadType.TM) {
    return (
      <HardyTooltip
        message={`Overload: After ${woe.overload_rule.successful_sessions_trigger_number} successful ${woe.overload_rule.successful_sessions_trigger_number == 1 ? 'session' : 'sessions'}, Training Max is suggested to increase by ${formatWeight(woe.overload_rule.tm_overload_amount, exercise.type, { units })}.`}
      >
        <div className="icon-overload"><GraphUp /></div>
      </HardyTooltip>
    );
  }

  return null;
}

function DeloadRuleTooltip () {
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);

  if (!woe.deload_rule || !exercise) {
    return null;
  }

  if (woe.deload_rule.deload_type === DeloadType._1RM) {
    return (
      <HardyTooltip
        message={`Deload: After ${woe.deload_rule.failed_sessions_trigger_number} uncompleted ${woe.deload_rule.failed_sessions_trigger_number == 1 ? 'session' : 'sessions'}, 1RM is suggested to decrease by ${woe.deload_rule.deload_percentage_1rm} %`}
      >
        <div className="icon-deload"><GraphDown /></div>
      </HardyTooltip>
    );
  }

  if (woe.deload_rule.deload_type === DeloadType.TM) {
    return (
      <HardyTooltip
        message={`Deload: After ${woe.deload_rule.failed_sessions_trigger_number} uncompleted ${woe.deload_rule.failed_sessions_trigger_number == 1 ? 'session' : 'sessions'}, Training Max is suggested to decrease by ${woe.deload_rule.tm_deload_percentage} %`}
      >
        <div className="icon-deload"><GraphDown /></div>
      </HardyTooltip>
    );
  }

  return null;
}

export default function WeightExercise() {
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);
  const history = useHistory();
  const [isExerciseOpen, setIsExerciseOpen] = useState(true);

  if (!exercise || !woe) {
    return null;
  }

  return (
    <div className="routine-workout-exercise">

      <div className={(isExerciseOpen) ? 'd-block' : 'd-none'}>
        <Row className="routine-woe-header">
          <Col className="routine-woe-header-col-clickable" onClick={() => setIsExerciseOpen(false)}>
            <Row className="align-items-center">
              <Col className="align-items-center d-flex">
                <ChevronDown className="woe-chevron" />
                <HardyTooltip message={exercise.type}>
                  <div className={'icon-exercise icon-exercise--' + exercise.type}></div>
                </HardyTooltip>
                <h4 className="mb-0">{exercise.name}</h4>
              </Col>
            </Row>
          </Col>
          <Col xs={'auto'}>
            <Button variant="primary" className="btn-start mt-2" onClick={() => history.push('/download')}>Start<span className="hidden-xs"> in App</span></Button>
          </Col>
        </Row>

        <div className="routine-wow-collapse-holder">
          <Collapse appear={true} in={isExerciseOpen}>
            <div className="routine-wow-collapse-inner">
              <Row className="routine-woe-sets mt-4 mb-3" noGutters>
                <Col>
                  <Row className="">
                    <Col>
                      <div className="woe-cus-row routine-woe-sets-header routine-woe-sets-row--preview">
                        <div className="woe-cus-col-1">
                          <h5>Set</h5>
                        </div>
                        <div className="woe-cus-col-2 text-right">
                          <h5>Intensity</h5>
                        </div>
                        <div className="woe-cus-col-3 text-right">
                          <h5>Weight</h5>
                        </div>
                        <div className="woe-cus-col-4">
                          <h5 className="ml-2 pl-1">Reps</h5>
                        </div>
                        <div className="woe-cus-col-5 text-right">
                          <h5>Rest time</h5>
                        </div>
                      </div>
                      {woe.sets.map((set, index) => (
                        <PreviewSetContextProvider set={set} key={index} >
                          <WeightSet index={index} />
                        </PreviewSetContextProvider>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <WeightExercisePreviewTmBasis />
              <WeightExercisePreviewOverload />
              <WeightExercisePreviewDeload />
            </div>
          </Collapse>
        </div>

      </div>
      <div className={(isExerciseOpen) ? 'd-none' : 'd-block woe-minimized'}>
        <Row className="routine-woe-header">
          <Col className="routine-woe-header-col-clickable" onClick={() => setIsExerciseOpen(true)}>
            <Row className="align-items-center">
              <Col xs={12} sm={7} className="align-items-center d-flex mb-2 mb-sm-0">
                <ChevronRight className="woe-chevron" />
                <HardyTooltip message={exercise.type} >
                  <div className={'icon-exercise icon-exercise--' + exercise.type}></div>
                </HardyTooltip>
                <h4 className="mb-0">{exercise.name}</h4>
              </Col>
              <Col xs={{ span: 4, offset: 1 }} sm={{ span: 2, offset: 0 }}>
                <h5 className="routine-woe-header-sets">
                  {woe.sets.length} sets
                </h5>
              </Col>
              <Col xs={5} sm={3}>
                <OverloadRuleTooltip />
                <DeloadRuleTooltip />
              </Col>
            </Row>
          </Col>
          <Col xs={'auto'}>
            <Button variant="primary" className="btn-start mt-2" onClick={() => history.push('/download')}>Start<span className="hidden-xs"> in App</span></Button>
          </Col>
        </Row>
      </div>
    </div>
  );

}
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//@ts-ignore
import { Search, X } from 'react-bootstrap-icons';
import './RoutineListPage.scss';
import { listRoutine } from '../../lib/slices/routine.list';
import { listSubscribedRoutine } from '../../lib/slices/routine.subscribed-list';
import { listOwnRoutine } from '../../lib/slices/routine.own-list';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import HardyPagination from '../form/HardyPagination';
import { selectIsGuest, selectUser } from '../../lib/slices/auth';
import { RoutineResponse, RoutineStatus } from '../../../common/types';
import FooterComponent from '../footer/Footer';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { publicUrl } from '../../lib/config';

const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

const ROUTINE_LIST_PAGE_LIMIT = 10;


function FullRoutineRow({routine, isOwn, isSubscribed}: {routine: RoutineResponse; isOwn: boolean; isSubscribed: boolean}) {

  return (
    <Row key={routine.id} className={`mb-4 ${routine.deleted_at && isOwn ? 'd-none' : null}`}>
      <Col xs={10}>
        <Link className="routine-link" to={`routine/${routine.slug}-${routine.id}`}>
          <Row>
            <Col xs={12} sm={8} md={8} lg={6}>
              <div className={`routine-img-holder mr-3 ${isSubscribed ? 'routine-img-holder--subscribed' : ''} ${isOwn ? 'routine-img-holder--own' : ''}`}>
                <svg enableBackground="new 0 0 512.181 512.181" height="512" viewBox="0 0 512.181 512.181" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m74.251 247.001-49.5 49.5 21.214 21.215-28.288 28.287 148.501 148.501 28.288-28.288 21.214 21.215 49.5-49.501z"/><path d="m346.003 17.677-28.287 28.288-21.215-21.214-49.5 49.5 190.929 190.929 49.501-49.5-21.215-21.214 28.288-28.288z"/><path d="m434.144 3.033h50.003v100.005h-50.003z" transform="matrix(.707 -.707 .707 .707 96.979 340.199)"/><path d="m28.034 409.143h50.003v100.005h-50.003z" transform="matrix(.707 -.707 .707 .707 -309.131 171.983)"/><path d="m163.938 206.088h184.306v100.005h-184.306z" transform="matrix(.707 -.707 .707 .707 -106.076 256.091)"/></g></svg>
                { isOwn && routine.status === RoutineStatus.PRIVATE ? (
                  <div className="routine-private-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" /></svg>
                  </div>
                ) : null }
              </div>
              <h5>{routine.name}</h5>

              <span className="routine-username text-muted">by {routine.username || 'Anonymous'}</span>
            </Col>
            {/*
            <Col xs={2}>
              <span className="routine-link-stats">3 weeks ago</span>
            </Col>
            <Col xs={2}>
              <span className="routine-link-stats">450kg lifted</span>
            </Col>
            <Col xs={2}>
              <span className="routine-link-stats">2x sessions</span>
            </Col>
            */}
          </Row>
        </Link>
      </Col>
    </Row>
  );
}

function RoutineListPageComponent () {

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const user = useSelector(selectUser);
  const guest = useSelector(selectIsGuest);
  const routineListState = useSelector((state: RootState) => state.routineListState);
  const routineSubscribedListState = useSelector((state: RootState) => state.routineSubscribedListState);
  const routineOwnListState = useSelector((state: RootState) => state.routineOwnListState);

  const [listPageNumber, setListPageNumber] = useState(1);

  function loadSubscribedList () {
    dispatch(listSubscribedRoutine({}));
  }

  function loadOwnList() {
    dispatch(listOwnRoutine({}));
  }

  function loadPublicList () {
    dispatch(listRoutine({
      search: searchTerm,
      offset: (listPageNumber - 1) * ROUTINE_LIST_PAGE_LIMIT,
      limit: ROUTINE_LIST_PAGE_LIMIT
    }));
  }

  useEffect(() => {
    loadSubscribedList();
    loadOwnList();
    loadPublicList();
  }, []);

  useEffect(() => {
    loadSubscribedList();
  }, [user]);

  useEffect(() => {
    loadPublicList();
  }, [listPageNumber, searchTerm, user]);

  return (<>

    <Helmet titleTemplate="Hardy - %s">
      <title>Workout Routines</title>
      <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
      <meta property="og:title" content="Hardy - Workout Routines" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.hardy.app/routines" />
      <meta property="og:image" content={socialMediaFullUrl} />
      <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>

    <Container className="content-area pb-3 mb-5">
      <Form.Group className="search-group">
        <Row>
          <Col>
            <Form.Control className="search-field" type="text" placeholder="Search workout routines" onChange={(e: any) => setSearchTerm(e.target.value) } value={searchTerm} />
            <div className="search-field-icon">
              {searchTerm ? (
                <a role="button"><X color="#949DAE" size={40} onClick={() => setSearchTerm('')} style={{ marginTop: -6, marginRight: -5 }} /></a>
              ) : (
                <Search color="#949DAE" size={25} />
              )}
            </div>
          </Col>
        </Row>
        { routineSubscribedListState.list.length == 0 ? (
          <Row>
            <Col className="mt-3 ml-4 mr-4 text-muted">
              <span className="text-muted mr-2">Popular searches:</span> 
              <a role="button" onClick={() => setSearchTerm('nsuns')} className="search-suggestion-link ml-2">nSuns</a>, 
              <a role="button" onClick={() => setSearchTerm('5/3/1')} className="search-suggestion-link ml-2">5/3/1</a>, 
              <a role="button" onClick={() => setSearchTerm('beginner')} className="search-suggestion-link ml-2">Beginner</a>, 
              <a role="button" onClick={() => setSearchTerm('push pull')} className="search-suggestion-link ml-2">Push Pull</a>, 
              <a role="button" onClick={() => setSearchTerm('reddit')} className="search-suggestion-link ml-2">Reddit</a>
              {searchTerm ? (
                <Button variant="outline-primary" size="sm" onClick={() => setSearchTerm('')} className="float-right"><X color="#949DAE" size={20} style={{ marginRight: 2 }} /> Clear</Button>
              ) : null }
            </Col>
          </Row>
        ) : null }
      </Form.Group>

      {!searchTerm ? (
        <>
          <div className="">
            <Row className="pt-2 pb-3">
              <Col><h2 className="caps-condensed mb-2">Your Routines</h2></Col>
            </Row>
          </div>
          { user && !guest && routineSubscribedListState.list.length !== 0 ? (
            <>
              <div className="">
                {routineSubscribedListState.list.map((r) => <FullRoutineRow key={r.id} routine={r} isOwn={false} isSubscribed={true} />)}
              </div>
            </>
          ) : null }

          <div className="mb-5">
            { user && !guest ? (
              <>
                {routineOwnListState.list.map((r) => <FullRoutineRow key={r.id} routine={r} isOwn={true} isSubscribed={false} />)}
              </>
            ) : null }
            <Row className="">
              <Col xs={10}>
                <Link className="routine-link routine-link--create" to={'create'}>
                  <Row>
                    <Col xs={12} sm={8} md={8} lg={6}>
                      <div className="routine-img-holder routine-img-holder--create mr-3">
                        <span>+</span>
                      </div>
                      <h5>Create a new routine</h5>
                    </Col>
                  </Row>
                </Link>
              </Col>
            </Row>
          </div>
        </>
      ) : null }

      <Row className="pt-2 pb-3">
        <Col><h2 className="caps-condensed mb-2">{searchTerm ? 'Search results' : 'Workout Routines'}</h2></Col>
      </Row>
      { routineListState.list.map((r) => {
        return (
          <Row key={r.id} className="mb-4">
            <Col xs={10}>
              <Link className="routine-link" to={`routine/${r.slug}-${r.id}`}>
                <Row>
                  <Col xs={12} sm={8} md={8} lg={6}>
                    <div className={`routine-img-holder mr-3 ${user && !guest && routineSubscribedListState.list.some((sr) => sr.id === r.id) ? 'routine-img-holder--subscribed' : ''} ${user && !guest && routineOwnListState.list.some((sr) => sr.id === r.id) ? 'routine-img-holder--own' : ''}`}>
                      <svg enableBackground="new 0 0 512.181 512.181" height="512" viewBox="0 0 512.181 512.181" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m74.251 247.001-49.5 49.5 21.214 21.215-28.288 28.287 148.501 148.501 28.288-28.288 21.214 21.215 49.5-49.501z"/><path d="m346.003 17.677-28.287 28.288-21.215-21.214-49.5 49.5 190.929 190.929 49.501-49.5-21.215-21.214 28.288-28.288z"/><path d="m434.144 3.033h50.003v100.005h-50.003z" transform="matrix(.707 -.707 .707 .707 96.979 340.199)"/><path d="m28.034 409.143h50.003v100.005h-50.003z" transform="matrix(.707 -.707 .707 .707 -309.131 171.983)"/><path d="m163.938 206.088h184.306v100.005h-184.306z" transform="matrix(.707 -.707 .707 .707 -106.076 256.091)"/></g></svg>
                    </div>
                    <h5>{r.name}</h5>
                    <span className="routine-username text-muted">by {r.username || 'Anonymous'}</span>
                  </Col>
                </Row>
              </Link>
            </Col>
          </Row>
        );
      })}
      { routineListState.list.length == 0 ? (
        <p className="text-muted">None found</p>
      ) : null}
      { routineListState.count > ROUTINE_LIST_PAGE_LIMIT ? (
        <HardyPagination
          count={routineListState.count}
          pageNumber={listPageNumber}
          limit={ROUTINE_LIST_PAGE_LIMIT}
          goToPage={(page) => {
            setListPageNumber(page);
          }}
        />
      ) : null }
    </Container>

    <FooterComponent/>   
  </>
  );
}

export default RoutineListPageComponent;

import React, {
  Ref,
  useRef,
  useState
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import useClickOutsideHandler from '../../../lib/hooks/useClickOutsideHandler';
import slice from '../../../lib/slices/routine-editor';
import { RootState } from '../../../lib/store';
import { DraggableTypes } from '../DraggableTypes';
import AddSetButtons from './AddSetButtons';
import SetsHeader from './header/SetsHeader';
import SetRowEditorSwitch, { SetAutoFocusInput } from './row-edit/SetRowEditorSwitch';
import SetRowSwitch from './row/SetRowSwitch';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

interface Item {
  index: number;
}
function EditorSetRow({
  index,
  editIndex,
  workoutIndex,
  exerciseIndex,
  setEditIndex,
  setEditAutoFocus,
  setEditorRef,
  editAutoFocus
}: {
  index: number;
  editIndex: number;
  workoutIndex: number;
  exerciseIndex: number;
  setEditIndex: (v: number) => void;
  setEditAutoFocus: (v: SetAutoFocusInput) => void;
  setEditorRef: Ref<any>;
  editAutoFocus: SetAutoFocusInput;
}) {
  const dispatch = useDispatch();
  const sets = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets);
  const set = sets[index];

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DraggableTypes.SET,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index],
  );

  const [{ currentHovering, hoveringSetIndex }, drop] = useDrop(
    () => ({
      accept: DraggableTypes.SET,
      collect: (monitor) => {
        const isOver = monitor.isOver();
        const item: Item = monitor.getItem();

        return { currentHovering: isOver && item?.index !== set.index, hoveringSetIndex: item?.index };
      },
      drop(item: Item) {
        const setIndex = item.index;
        const newIndex = set.index;
        dispatch(slice.actions.moveSet({
          workoutIndex,
          exerciseIndex,
          setIndex,
          newIndex
        }));
        setEditIndex(-1);
      }
    }),
    [set.index],
  );

  return (
    <div className="routine-woe-sets-row-drag" ref={(node) => drag(drop(node))}>
      { currentHovering && index < hoveringSetIndex ? (
        <div style={{ opacity: 1, boxShadow: '0 0 0 1px #df563d', zIndex: 9, position: 'relative' }}></div>
      ) : null }

      <div style={{opacity: isDragging ? 0.15 : 1 }}>
        {
          (index !== editIndex) ? (
            <SetRowSwitch
              workoutIndex={workoutIndex}
              exerciseIndex={exerciseIndex}
              setIndex={index}
              onClick={(autoF: SetAutoFocusInput) => {
                setEditIndex(index);
                setEditAutoFocus(autoF);
              }}
            />
          ) : (
            <SetRowEditorSwitch
              ref={setEditorRef}
              workoutIndex={workoutIndex}
              exerciseIndex={exerciseIndex}
              setIndex={index}
              autoFocusElement={editAutoFocus}
            />
          )
        }
      </div>

      { currentHovering && index > hoveringSetIndex ? (
        <div style={{ opacity: 1, boxShadow: '0 0 0 1px #df563d', zIndex: 9, position: 'relative' }}></div>
      ) : null }
    </div>
  );
}

export default function SetsEditor({ workoutIndex, exerciseIndex }: Props) {
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editAutoFocus, setEditAutoFocus] = useState<SetAutoFocusInput>(SetAutoFocusInput.REPETITIONS);
  const setEditorRef = useRef(null);

  useClickOutsideHandler(setEditorRef, () => {
    setEditIndex(-1);
  });

  const addSetButtonProps = { workoutIndex, exerciseIndex, setEditIndex, setEditAutoFocus };
  const [, drop] = useDrop(() => ({ accept: DraggableTypes.SET }));

  return (
    <Row className="routine-woe-sets" noGutters>
      <Col>
        <SetsHeader workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />

        <div className="" ref={drop}>
          {workoutExercise.sets.map((exerciseSet, index) => (
            <EditorSetRow 
              key={exerciseSet.index}
              {
                ...{
                  index,
                  editIndex,
                  workoutIndex,
                  exerciseIndex,
                  setEditIndex,
                  setEditAutoFocus,
                  setEditorRef,
                  editAutoFocus
                }
              }
            />
          ))
            
          }
        </div>
        <AddSetButtons {...addSetButtonProps} />
      </Col>
    </Row>
  );

}

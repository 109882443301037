import React, { useEffect, useState } from 'react';
import './Menu.scss';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/hardy-logo.svg';
import { RootState } from '../../lib/store';
import { logout } from '../../lib/slices/auth';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const CreateButton = () => {
  const routineEditorState = useSelector((state: RootState) => state.routineEditorState);
  const location = useLocation();
  const isCreatePage = location.pathname.includes('create');
  const isUpdatePage = location.pathname.includes('update');
  const isRoutineEditOpen = routineEditorState.createData.id || routineEditorState.createData.name;
  const isRoutineUpdate = isRoutineEditOpen && routineEditorState.createData.id;

  if (isCreatePage || isUpdatePage) {
    return null;
  }

  if (isRoutineUpdate) {
    return (
      <Nav.Link className="btn btn-primary btn-nav mr-2" as={Link} tabIndex={0} to={`/routine/update/${routineEditorState.createData.id}`}>
        Continue Editing
      </Nav.Link>
    );
  }

  return (
    <Nav.Link className="btn btn-primary btn-nav mr-2" as={Link} tabIndex={0} to="/create">
      {isRoutineEditOpen ? 'Continue Creating' : 'Create a Routine'}
    </Nav.Link>
  );
};
function MenuComponent(props: { children: any }) {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authState);
  const isLoggedIn = auth.token;
  const username = auth.data?.username;
  const isAdmin = auth.data?.admin;
  const history = useHistory();
  const location = useLocation();
  const isHowItWorksPage = location.pathname === '/'; 
  const [scrollTransparency, setScrollTransparency] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTransparency(window.scrollY < 250);
    });
  }, []);

  
  return (
    <>
      <Navbar className={isHowItWorksPage && scrollTransparency ? 'fit-menu fit-menu--transparent' : 'fit-menu'} variant="dark" fixed="top" expand="md">
        <Nav>
          <Nav.Link as={Link} to="/routines" tabIndex={0}><span className="hidden-xs">Browse </span>Routines</Nav.Link>
        </Nav>
        <Navbar.Brand as={Link} to="/" tabIndex={0}><img className="nav-logo" src={Logo} alt="Hardy" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="hardy-navbar-nav" />
        <Navbar.Collapse id="hardy-navbar-nav">
          <Nav className="ml-auto align-items-center">
            <CreateButton />
            {isLoggedIn ? (
              <NavDropdown className="nav-user-dropdown" title={username ? username : 'Anonymous'} alignRight id="nav-user-dropdown">
                {!username ? (
                  <NavDropdown.Item as={Link} to="/register">Register</NavDropdown.Item>
                ) : null}
                <NavDropdown.Item as={Link} to="/account">Settings</NavDropdown.Item>

                {
                  isAdmin ? (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/admin">Admin</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/admin/workouts">Admin Workouts</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/admin/workout/stats">Admin Workout Stats</NavDropdown.Item>
                    </>
                  ) : null
                }
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {
                  dispatch(logout());
                  history.push('/');
                }}>Log out</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/login">Log In</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="fit-body">
        {props.children}
      </div>
    </>
  );
}

export default MenuComponent;


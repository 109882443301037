import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import ValidationErrorComponent from './ValidationError';

interface Props {
  errors?: { validation?: { [key: string]: string; }}
  name: string;
  formCtrl: any;
}

export default function HardyDropdownComponent({ errors, name, formCtrl }: Props) {
  const [touched, setTouched] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const changeFunc = formCtrl.onChange;
  formCtrl.onChange = (input: any) => {
    setIsMenuOpen(false);
    setTouched(true);
    changeFunc(input);
  };
  formCtrl.menuIsOpen = isMenuOpen;

  return (<>
    <Select {...formCtrl} />
    {
      touched ?
        (<Row>
          <Col xs={12} className={'validation-error-message'}>
            <ValidationErrorComponent name={name} errors={errors}/>
          </Col>
        </Row>)
        : null
    }
  </>);
}

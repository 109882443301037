import { useEffect } from 'react';
import { useHistory } from 'react-router';

function ScrollToTop() {
  const history = useHistory();
  
  useEffect(() => {
    const unlisten = history.listen((data: any) => {
      if (!data?.state?.noScroll) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return (null);
}

export default ScrollToTop;
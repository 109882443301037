import React, { useState } from 'react';
import {
  WorkoutExerciseSetUpdateData,
  WorkoutExerciseDeloadRule,
  WorkoutExerciseOverloadRule
} from '../../../../common/types/index';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../lib/store';
import WorkoutExerciseExpandedCardBody from './expanded-body/ExpandedCardBody';
import WorkoutExerciseCollapsedCardBody from './collapsed-body/CollapsedCardBody';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableTypes } from '../DraggableTypes';
import slice from '../../../lib/slices/routine-editor';

type WorkoutExerciseSet = WorkoutExerciseSetUpdateData;

export interface WorkoutExerciseShallowData {
  exercise_id?: number;
  exercise_label?: string;
  deload_rule?: WorkoutExerciseDeloadRule;
  overload_rule?: WorkoutExerciseOverloadRule;
  intensity_weight_tm_percentage_exercise_id?: number;
  intensity_weight_tm_percentage_exercise_label?: string;
}
export interface WorkoutExerciseData extends WorkoutExerciseShallowData {
  sets: WorkoutExerciseSet[];
}
interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

interface DraggableItem {
  index: number;
}

export default function WorkoutExercise({ workoutIndex, exerciseIndex }: Props) {
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const [expanded, setExpanded] = useState(false);
  const exercise = useSelector((state: RootState) => state.exerciseCacheState.cacheMap[workoutExercise.exercise_id]);
  const dispatch = useDispatch();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DraggableTypes.EXERCISE,
      item: { index: exerciseIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [exerciseIndex],
  );

  const [{ currentHoveringExercise, hoveringExerciseIndex }, drop] = useDrop(
    () => ({
      accept: DraggableTypes.EXERCISE,
      collect: (monitor) => {
        const isOver = monitor.isOver();
        const item: DraggableItem = monitor.getItem();

        return {
          currentHoveringExercise: isOver && item?.index !== exerciseIndex,
          hoveringExerciseIndex: item?.index
        };
      },
      drop(item: DraggableItem) {
        const newIndex = exerciseIndex;
        dispatch(slice.actions.moveExercise({
          workoutIndex,
          exerciseIndex: item.index,
          newIndex
        }));
      }
    }),
    [exerciseIndex],
  );

  if (!exercise) {
    return null;
  }
  return (
    <div className="routine-workout-exercise" ref={(node) => drag(drop(node))}>
      
      { currentHoveringExercise && exerciseIndex < hoveringExerciseIndex ? (
        <div style={{ opacity: 1, boxShadow: '0 0 0 1px #df563d', zIndex: 9, position: 'relative' }}></div>
      ) : null }

      <div style={{opacity: isDragging ? 0.15 : 1 }}>
        { expanded ? (
          <WorkoutExerciseExpandedCardBody workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setExpanded={(isExpanded) => setExpanded(isExpanded)} expanded={ expanded } />
        ): (
          <WorkoutExerciseCollapsedCardBody workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setExpanded={(isExpanded) => setExpanded(isExpanded)} />
        )}
      </div>

      { currentHoveringExercise && exerciseIndex > hoveringExerciseIndex ? (
        <div style={{ opacity: 1, boxShadow: '0 0 0 1px #df563d', zIndex: 9, position: 'relative' }}></div>
      ) : null }
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
// import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RootState } from '../../lib/store';
import { Link } from 'react-router-dom';
import HardyInputComponent from '../form/HardyInput';
import {
  // loginFbRequest,
  loginRequest
} from '../../lib/services/auth.service';
import authSlice, { selectIsGuest } from '../../lib/slices/auth';
import ValidationErrorComponent from '../form/ValidationError';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { publicUrl } from '../../lib/config';
const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

function LoginComponent () {
  const dispatch = useDispatch();
  const location = useLocation<{
    redirectPath: string;
    successfulRecovery: boolean
  }>();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<any>();
  const authState = useSelector((state: RootState) => state.authState);
  const guest = useSelector(selectIsGuest);

  async function onLoginSubmit(e: any) {
    e.preventDefault();
    const {error, response} = await loginRequest({email, password});

    if (error) {
      setErrors(error);
    }

    if (response) {
      dispatch(authSlice.actions.setUser(response));
    }
  }

  // async function handleFBLogin (res: ReactFacebookLoginInfo) {
  //   const {error, response} = await loginFbRequest({token: res.accessToken});

  //   if (error) {
  //     setErrors(error);
  //   }

  //   if (response) {
  //     dispatch(authSlice.actions.setUser(response));
  //   }
  // }

  useEffect(() => {
    if (authState.token && !guest) {
      const path = location.state && location.state.redirectPath ? location.state.redirectPath: '/routines';
      history.replace(path);
    }
  }, [authState]);

  return (
    <>
      <Helmet titleTemplate="Hardy - %s">
        <title>Login</title>
        <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
        <meta property="og:title" content="Hardy - Login" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hardy.app/login" />
        <meta property="og:image" content={socialMediaFullUrl} />
        <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
      </Helmet>
      
      <Container className="pt-4">
        <Row>
          {location.state?.successfulRecovery ? (<Col lg={{ span: 4, offset: 4 }}> Please log in with new password </Col>) : null }
          <Col lg={{ span: 4, offset: 4 }}>
            <h1 className="text-center mt-5 caps-condensed">Login</h1>

            <div className="content-area mb-4">
              {/* <Row className="mb-3">
                <Col>
                  <FacebookLogin
                    appId="222152542516399"
                    fields="email"
                    textButton="Login with Facebook"
                    size="small"
                    disableMobileRedirect={true}
                    callback={handleFBLogin}
                    cssClass="btn btn-lg btn--facebook btn-block"
                    icon="fa-facebook"
                  />
                </Col>
              </Row> */}

              {/* <Row className="or-divider mb-2">
                <Col><hr/></Col>
                <Col xs="auto">OR</Col>
                <Col><hr/></Col>
              </Row> */}

              <Form onSubmit={onLoginSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <HardyInputComponent name={'email'} errors={errors} formCtrl={{
                    className: 'input-lg mb-2',
                    type: 'email',
                    placeholder: 'Enter email',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
                  }} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mt-4">
                  <Form.Label className="w-100">
                    <Row>
                      <Col>
                        Password
                      </Col>
                      <Col className="text-right">
                        <Link to={'/forgot-password'} className="text-link" tabIndex={-1}>Forgot?</Link>
                      </Col>
                    </Row>
                  </Form.Label>
                  <HardyInputComponent name={'password'} errors={errors} formCtrl={{
                    className: 'input-lg',
                    type:'password',
                    placeholder: 'Password',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
                  }} />
                </Form.Group>

                <Row>
                  <Col xs={12} className={'validation-error-message'}>
                    <ValidationErrorComponent name={'general'} errors={errors} />
                  </Col>
                </Row>

                <Form.Group className="text-center mt-4">
                  <Button variant="primary" size="lg" type="submit" block>
                    Log in
                  </Button>
                </Form.Group>

                <Row>
                  <Col>
                    <Link className="btn btn-outline btn-outline-secondary mt-2 btn-block btn-lg" to={{ pathname: '/register' }} >Register</Link>
                  </Col>
                </Row>

              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginComponent;

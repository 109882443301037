import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import './HowItWorksPage.scss';
import FooterComponent from '../footer/Footer';
import FaqComponent from '../faq/FAQ';

import videoHardyExerciseTMChangeSets from '../../assets/video-exercisetmchange-sets.mp4';
import videoHardyCreateExercise from '../../assets/video-hardy-create-exercise.mp4';
import videoHardyExerciseTMChangeTMs from '../../assets/video-exercisetmchange-tms.mp4';
import videoHardyExerciselist from '../../assets/video-exercise-list.mp4';
import videoHardyAppDemoFull from '../../assets/hardy-app-demo-full.mp4';
import videoHardyAppHome from '../../assets/hardy-app-demo-home.mp4';
import videoHardyAppTracker from '../../assets/hardy-app-demo-tracker.mp4';
import { Check2, CheckCircleFill, Instagram } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { publicUrl } from '../../lib/config';
import RedditImg from '../../assets/icon-reddit.svg';
import { Link } from 'react-router-dom';
import floatingPhonesCTAImg from '../../assets/hardy-floating-phones.jpg';
import appleStoreBadge from '../../assets/apple-appstore-badge.png';
import googleStoreBadge from '../../assets/google-play-badge.png';
import hardyTmChangeImg from '../../assets/hardy-tm-change-demo1.jpg';
import hardySetsImg from '../../assets/hardy-sets-demo1.png';
import iconApple from '../../assets/icon-apple.svg';
import iconGooglePlay from '../../assets/icon-google-play.svg';
import Testimonials from '../testimonials/Testimonials';
import HardyTooltip from '../ui/HardyTooltip';
// import ArrowImg from '../../assets/arrow.svg';
import { StarFill, StarHalf } from 'react-bootstrap-icons';

const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

function HowItWorksPageComponent () {
  const history = useHistory();
  const handleBrowseRoutinesLink = () => history.push('/');
  const handleNavigateToDownload = () => history.push('/download');
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = () => {
    if (videoRef.current !== null) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        handlePlayVideo();
      }
    });
  }, []);

  return (
    <>
      <Helmet titleTemplate="Hardy - %s">
        <title>Smart routines weight lifting workout app</title>
        <meta name={'description'} content={'Modern weight lifting routines made as easy to use as Spotify'} />
        <meta property="og:title" content="Hardy - Smart weight lifting routines" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hardy.app" />
        <meta property="og:image" content={socialMediaFullUrl} />
        <meta property="og:description" content="Modern weight lifting routines made as easy to use as Spotify" />
        <meta name="twitter:image" content={socialMediaFullUrl} />
      </Helmet>

      <Container fluid className="hiw-hero pr-0 pl-0">
        <Container fluid className="hiw-above-cards text-center">
          <h1 className="hero-headline">
            First app for creating, browsing, and tracking world’s popular <span>advanced weight training</span> workout routines. For free.
            {/* Browse, create, and track world’s popular <span>weight training</span> workout routines */}
          </h1>

          <div className="hero-phone-shape">
            <div className="video-holder">
              <video width="728" height="1500" loop ref={videoRef} muted playsInline>
                <source src={videoHardyAppDemoFull} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="hero-buttons">
            <Button className="ml-2 mr-2 mt-4" as={Link} to="/download">
              Get the app 
              <img src={iconApple} alt="" />
              <img src={iconGooglePlay} alt="" />
            </Button>
            <Button className="ml-2 mr-2 mt-4 btn-hero-outline-primary" variant="outline-primary" as={Link} to="/routines">
              Browse Routines
            </Button>
          </div>

          <div className="hero-stats">
            <div className="hero-stat">
              <h4>
                <StarFill />
                <StarFill />
                <StarFill />
                <StarFill />
                <StarHalf />
              </h4>
              <p>4.8 on Apple App store</p>
            </div>
            <div className="hero-stat">
              <h4>36 000+</h4>
              <p>Users</p>
            </div>
            <div className="hero-stat">
              <h4>800+</h4>
              <p>Public Routines</p>
            </div>
            <div className="hero-stat hidden-xs">
              <h4>1 900+</h4>
              <p>Users working out monthly</p>
            </div>
            <div className="hero-stat hidden-xs">
              <h4>12 000+</h4>
              <p>Workouts done monthly</p>
            </div>
          </div>
        </Container>

        <Container>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} >
              <Card className="hiw-cta-card">
                <Card.Body>
                  <Row>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 7, offset: 0 }} className="flex-column justify-items-center d-flex">
                      <h4 className="content-subtitle mt-0 mt-md-5 mt-lg-8 hidden-xs">Over 800 public routines to choose from</h4>
                      <h2 className="content-title mb-0 mb-md-5 mb-lg-8">
                        Choose a community-proven gym routine or create your own
                      </h2>
                    </Col>
                  </Row>
                  <div className="phone-holder-angled phone-holder-angled--absolute phone-holder-angled--mobilenotangled">
                    <div className="phone-angled">
                      <div className="phone-shape phone-shape--primary">
                        <div className="video-holder">
                          <video width="728" height="1500" loop autoPlay muted playsInline>
                            <source src={videoHardyAppHome} type="video/mp4"/>
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
              <Card className="hiw-cta-card mt-3">
                <Card.Body>
                  <Row>
                    <Col xs={{ span: 12, offset: 0 }} className="text-center">
                      <h2 className="content-title mt-0 mt-md-5 mb-3 mb-md-5">Always know your next exercise and the optimal weight to lift</h2>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} className="text-center">
                      <img src={hardySetsImg} alt="" className="hardy-app-demo-img mb-3 mb-md-5" />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
              <Card className="hiw-cta-card mt-3">
                <Card.Body>
                  <Row>
                    <Col xs={{ span: 12, offset: 0 }} className="text-center">
                      <h2 className="content-title mt-0 mt-md-5 mb-3 mb-md-5">As you progress, so do the weights, based on routine rules</h2>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} className="text-center">
                      <img src={hardyTmChangeImg} alt="" className="hardy-app-demo-img mb-3 mb-md-5" />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12, offset: 0 }}>
              <Card className="hiw-cta-card mt-3">
                <Card.Body>
                  <Row>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 0 }} className="justify-items-center d-flex flex-column">
                      <h4 className="content-subtitle mt-0 mt-md-5 mt-lg-8">Workout logging, reimagined.</h4>
                      <h2 className="content-title mb-0 mb-md-5 mb-lg-8">
                        We guide you through the workouts, set-by-set, like a personal trainer
                      </h2>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4, offset: 0 }} className="d-flex justify-items-center">
                      <div className="phone-holder-angled phone-holder-angled--two">
                        <div className="phone-angled">
                          <div className="phone-shape phone-shape--primary">
                            <div className="video-holder">
                              <video width="728" height="1500" loop autoPlay muted playsInline>
                                <source src={videoHardyAppTracker} type="video/mp4"/>
                              </video>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 3 }} className="text-center mt-6">
              <h3 style={{ color: '#fff' }}>Join before we add monthly subscription and get a free lifetime membership. <br/>And a status of OG!</h3>
              <div className="text-center mb-4">
                <a className="d-inline-block appstore-img appstore-img--apple mr-3 mt-2 mb-3" href="https://apps.apple.com/app/hardy-smart-workout-routines/id1554129445" target="_blank" rel="noreferrer"><img src={appleStoreBadge} alt="Download on the Apple App Store" /></a>
                <a className="d-inline-block appstore-img appstore-img--google mt-2 mb-3" href="https://play.google.com/store/apps/details?id=com.hardyapp.hardy" target="_blank" rel="noreferrer"><img src={googleStoreBadge} alt="Download on the Google App Store" /></a>
              </div>

              {/* <Button className="mt-4" size={'lg'} as={Link} to="/download" style={{ backgroundColor: '#fff', borderColor: '#fff',  color: '#000' }}>
                Get the app
              </Button> */}
            </Col>
          </Row>
        </Container>
      </Container>

      <Testimonials />

      <Container fluid className="content-headline">
        <Container className="">
          <Row>
            <Col lg={{ span: 10, offset: 1 }} className="text-center mt-5">
              <h2 className="">Goodbye Excel and PDF, <br/>hello <span>smart routines</span></h2>
              <p className="lead">
                {/*No more PDF routines or manually editing formulas in Excel.<br/>*/}
                Hardy takes the complexity of modern weight lifting routines <br/>
                and makes them as easy to use as Spotify playlists.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>



      {/*<Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col xs={{ span: 10, offset: 1}} md={{ span: 8, offset: 2}} lg={{ span: 5, offset: 1, order: 2 }} className="text-center">
              <div className="generic-shape generic-shape--exercise">
                <div className="video-holder">
                  <video width="1040" height="1488" loop autoPlay muted playsInline>
                    <source src={videoHardyCreateExercise} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 0, order: 1 }} className="align-self-center">
              <h2 className="content-title">Never worry about what weight to lift ever again.<br/>Just click start and begin.</h2>
              <p className="lead">
                Weight lifting routines can include progression rules for changing the weights and repetitions based on your performance.
                Often this means manually keeping track of your stats. 
                Let us handle the weight progressions and intensity calculations.
              </p>
              <ul className="checklist">
                <li><Check2 size={25} color={'#6dcb0a'} />World&apos;s popular gym routines (like 531, Push Pull Legs, 5x5, 3x3, etc)</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Easy to use tracker, guides you through the workouts set-by-set</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Create your own routines</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Free</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>*/}


      {/*How does it work?

      1. Pick a routine
      2. Enter your 1RM-s (if you don't know them, we'll help)
      3. Select your workout for the day
      4. Our tracker works like a music player, it guides you through the workout exercises set by set
      5. Exercise weights to lift are calculated based on your personal performance, following the routine you selected
      6. At the end of the workout, app suggests changes to your weight, based on the routine */}




      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col xs={{ span: 10, offset: 1}} md={{ span: 8, offset: 2}} lg={{ span: 5, offset: 1, order: 2 }} className="text-center">
              <div className="generic-shape generic-shape--exercise">
                <div className="video-holder">
                  <video width="1040" height="1488" loop autoPlay muted playsInline>
                    <source src={videoHardyCreateExercise} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 0, order: 1 }} className="align-self-center">
              <h3 className="content-subtitle caps-condensed text-muted">Smart routines</h3>
              <h2 className="content-title">
                Modern weight lifting routines can involve boring calculating of weights and progressions.<br/>
                <span>We automate this.</span>
              </h2>
              <p className="lead">
                Discover and create routines where exercise set weights, progressions, overloads and deloads, are based on values of <HardyTooltip message='1 Rep Max (1RM) is the max weight you can lift for a single repetition for a given exercise. 1RM values are used to calculate workout exercise set weights for 1RM percentage sets. 1RM-s are common for all routines (meaning they are the same for your different routines).'><span className='dotted-underline'>1 Rep Max percentage</span></HardyTooltip>
                , <HardyTooltip message='Training Max (TM) values are used to calculate a specific routine’s workout exercise set weights, where the sets use TM percentage. Training Max values are only used per routine (meaning each routine has their own TM values).'><span className='dotted-underline'>Training Max percentage</span></HardyTooltip>
                , <HardyTooltip message='RPE means Rate of Perceived Exertion and RIR means Reps in Reserve. Both RPE and RIR are used to measure the difficulty of a workout set subjectively in your training.'><span className='dotted-underline'>RPE / RIR</span></HardyTooltip> 
                , etc. 
              </p>
              
              <ul className="checklist" style={{display: 'none'}}>
                <li><Check2 size={25} color={'#6dcb0a'} />Dynamic weights based on Training Max %, e1RM, 1RM, RPE, etc.</li>
                <li><Check2 size={25} color={'#6dcb0a'} />AMRAP sets (As Many Reps As Possible)</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Progressive Overload based on AMRAP sets</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Overload and deload based on session success</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Database of common exercises (barbell, dumbbell, kettlebell, plate, machine, bodyweight, and time-based)</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Create custom exercises</li>
                <li className="text-muted">Coming soon: Supersets, dropsets, periodization, and more.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="hiw-content" style={{ display: 'none' }}>
        <Container className="">
          <Row>
            <Col xs={{ span: 10, offset: 1}} md={{ span: 8, offset: 2}} lg={{ span: 5, offset: 0 }} className="text-center">
              <div className="generic-shape generic-shape--exerciselist">
                <div className="video-holder">
                  <video width="1228" height="1280" loop autoPlay muted playsInline>
                    <source src={videoHardyExerciselist} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 1 }} className="align-self-center">
              <h3 className="content-subtitle caps-condensed text-muted">Discover routines</h3>
              <h2 className="content-title">Discover community proven gym workout routines</h2>
              <p className="lead">
                Select your next workout routine from a list of popular and proven weight lifting programs.
                Or pick one created by somebody you trust in the fitness community.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col xs={{ span: 10, offset: 1}} md={{ span: 8, offset: 2}} lg={{ span: 5, offset: 0 }} className="text-center">
              <div className="dual-video-holder">
                <div className="generic-shape generic-shape--sets">
                  <div className="video-holder">
                    <video width="1036" height="1128" loop autoPlay muted playsInline>
                      <source src={videoHardyExerciseTMChangeSets} type="video/mp4"/>
                    </video>
                  </div>
                  <div className="generic-shape generic-shape--mini">
                    <div className="video-holder">
                      <video width="484" height="400" loop autoPlay muted playsInline>
                        <source src={videoHardyExerciseTMChangeTMs} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 1 }} className="align-self-center">
              <h3 className="content-subtitle caps-condensed text-muted">Dynamic weights</h3>
              <h2 className="content-title">
                Weights and progressions based on your performance. <span>Just enter 1RM-s & hit start.</span>
              </h2>
              <p className="lead">
                Just enter your <HardyTooltip message='1 Rep Max (1RM) is the max weight you can lift for a single repetition for a given exercise. 1RM values are used to calculate workout exercise set weights for 1RM percentage sets. 1RM-s are common for all routines (meaning they are the same for your different routines).'><span className='dotted-underline'>1 Rep Max</span></HardyTooltip> or <HardyTooltip message='Training Max (TM) values are used to calculate a specific routine’s workout exercise set weights, where the sets use TM percentage. Training Max values are only used per routine (meaning each routine has their own TM values).'><span className='dotted-underline'>Training Max</span></HardyTooltip> figures and routine will display the correct weight to lift for you, based on the selected routine.
                <br/><br/>
                If you do not know your 1 Rep Max figures, no worries, we can guide you through that.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      

      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col xs={{ span: 10, offset: 1}} md={{ span: 8, offset: 2}} lg={{ span: 4, offset: 1, order: 2 }} className="text-center">
              <div className="phone-holder-angled phone-holder-angled--white phone-holder-angled--free">
                <div className="phone-angled">
                  <div className="phone-shape">
                    <div className="video-holder">
                      <video width="728" height="1500" loop autoPlay muted playsInline>
                        <source src={videoHardyAppDemoFull} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 0, order: 1 }} className="align-self-center">
              <h3 className="content-subtitle caps-condensed text-muted">Track/log routines</h3>
              <h2 className="content-title">Workout tracking, reimagined. As simple as a music player.</h2>
              <p className="lead">
                Our app understands your chosen routine and progression rules, your 1RM-s, RPE-s, and performance. It carries you through the workouts set-by-set, like a personal trainer.

                <br/><br/>
                Just press Start and begin workout.
              </p>
              <Button className="mt-4" onClick={handleNavigateToDownload}>Download App</Button>
              {/*<ul className="checklist">
                <li><Check2 size={25} color={'#6dcb0a'} />Browse popular gym routines</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Customize and create new routines</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Track/log your workouts</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Auto-regulating weight calculations</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Rest timer</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Progression suggestions based on routine</li>
                <li><Check2 size={25} color={'#6dcb0a'} />Workout history</li>
                <li className="text-muted">And more.</li>
              </ul>*/}
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col xs={{ span: 10, offset: 1}} md={{ span: 8, offset: 2}} lg={{ span: 5, offset: 0 }} className="text-center">
              <div className="generic-shape generic-shape--exerciselist">
                <div className="video-holder">
                  <video width="1228" height="1280" loop autoPlay muted playsInline>
                    <source src={videoHardyExerciselist} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 1 }} className="align-self-center">
              <h3 className="content-subtitle caps-condensed text-muted">Discover and share routines</h3>
              <h2 className="content-title">Discover and share routines, that anybody can start using right away with just a link.<br/> <span>Like Spotify playlists.</span></h2>
              <p className="lead">
                Others can open the routine and start tracking their workouts immediately, with weights based on their own personal 1 Rep Max values.
                {/*Private trainer profiles coming soon.*/}
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      

      <Container fluid className="hiw-content pt-0 pb-0">
        <Container className="">
          <Row>
            <Col xs={{ span: 12, offset: 0 }}>
              <Card className="hiw-cta-card hiw-cta-card--cta pt-6 pb-6">
                <Card.Body>
                  <Row>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 10, offset: 1 }} className="text-center">
                      <h2 className="caps-condensed text-muted">Free, no ads, no hidden costs</h2>
                      <h2 className="content-title">Join today and get <span>free lifetime membership</span>. Download our app and start your workouts.</h2>
                      {/*<p className="lead mb-4">Our web platform is accessible by all. Our mobile app is currently still in private beta.</p>*/}
                      <Button size={'lg'} className="mt-5 mr-2 ml-2" onClick={handleNavigateToDownload}>Download App</Button>
                      <Button variant="outline-secondary" size={'lg'} className="mt-5 mr-2 ml-2" onClick={handleBrowseRoutinesLink}>Browse routines</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

      <FaqComponent />

      <Container fluid className="hiw-content bg-dark">
        <Container className="">
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <h2 className="content-title text-center text-white">Features and coming next</h2>
              {/*<p className="lead text-center mb-5">
                Here&apos;s what we&apos;re working on at the moment.
                If you have any suggestions or preferences on what we should prioritize please join us at <a href="https://www.reddit.com/r/hardyapp" target="_blank" rel="noreferrer">/r/hardyapp</a>.
              </p>*/}
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} className="mt-5">
              <Row>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Browse & find routines</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Create & customize routines</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Track / log workouts</div></Col>

                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Dynamic weights (TM%, e1RM, RPE)</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> RPE autoregulation rules</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Public & Private routines</div></Col>
                
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Overload & Deload intensity (weight)</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Database of common exercises</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Create custom exercises</div></Col>

                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Rest timer</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> AMRAP Progressive overload</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box coming-soon-box--done"><CheckCircleFill size={25} color={'#6dcb0a'} /> Progress history</div></Col>

                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Overload & deload of volume (reps)</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Progress statistics</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Programmable deload week</div></Col>

                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Supersets & dropsets</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Complex periodization</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Notes</div></Col>
                
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Personalized public profiles</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Following of community accounts</div></Col>
                <Col sm={{ span: 6 }} lg={{ span: 4 }} className="mb-3"><div className="coming-soon-box">Share workout snippets <br/> (for private trainers)</div></Col>
              </Row>
              <p className="lead text-center text-muted mt-5 mb-5">
                And more to come. 💪
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <h2 className="content-title text-center text-white mt-5">Join our Instagram & subreddit</h2>
              <p className="lead text-center mb-3 text-white">
                Let us know of bugs and vote on what features to add
              </p>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 0 }}>
              <a href="https://www.reddit.com/r/hardyapp" target="_blank" rel="noreferrer" className="card-link">
                <Card className="reddit-cta-card bg-light mt-5">
                  <Card.Body>
                    <Row>
                      <Col sm={{ span: 2, offset: 0 }} className="text-center">
                        <div className="reddit-icon">
                          <img src={RedditImg} alt="Reddit icon" className="align-self-center" />
                        </div>
                      </Col>
                      <Col sm={{ span: 8, offset: 0 }} className="text-center align-self-center">
                        <h3 className="mb-0">
                          r/HardyApp
                        </h3>
                      </Col>
                      <Col sm={{ span: 2, offset: 0 }} className="text-center align-self-center">
                        <p className="lead mb-0" style={{ fontSize: '32px'}}>→</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 0 }}>
              <a href="https://www.instagram.com/hardy.app" target="_blank" rel="noreferrer" className="card-link">
                <Card className="reddit-cta-card bg-light mt-5">
                  <Card.Body>
                    <Row>
                      <Col sm={{ span: 2, offset: 0 }} className="text-center">
                        <div className="reddit-icon">
                          <Instagram color={'#fff'} size={60} className="align-self-center" />
                        </div>
                      </Col>
                      <Col sm={{ span: 8, offset: 0 }} className="text-center align-self-center">
                        <h3 className="mb-0">
                          @Hardy.App
                        </h3>
                      </Col>
                      <Col sm={{ span: 2, offset: 0 }} className="text-center align-self-center">
                        <p className="lead mb-0" style={{ fontSize: '32px'}}>→</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="hiw-content hiw-content--bigcta">
        <Container className="">
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>

              <h2 className="content-title text-center">Download Hardy app</h2>
              <p className="lead text-center mb-5">
                All who join while we are in beta will receive free lifetime membership.
              </p>

              <div className="text-center">
                <Button className="mb-5" size={'lg'} as={Link} to="/download">
                  Get the app
                </Button>
              </div>

              <img src={floatingPhonesCTAImg} alt="" className="floating-phones-cta-img" />

            </Col>
          </Row>
        </Container>
      </Container>

      <FooterComponent/>

    </>
  );
}

export default HowItWorksPageComponent;

import React from 'react';
import { WorkoutExerciseSetData } from '../../../../common/types';
import { usePreviewSet } from '../../context/PreviewSetContext';

export default function BodyweightTimeSet(props: { index: number; }) {
  const set = usePreviewSet() as WorkoutExerciseSetData;

  return (
    <>
      <div className="woe-cus-row routine-woe-sets-row routine-woe-sets-row--preview">

        <div className="woe-cus-col-1">
          <span className="text-muted">{props.index + 1}</span>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-3-4 text-right">
          <span className="mr-3">
            <span className="clickable-area-indicator">
              <strong className="rep-text">
                {set.intensity_time} sec
              </strong>
            </span>
          </span>
        </div>
        <div className="woe-cus-col-5 text-right">
          {set.rest_time} sec
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Check2 } from 'react-bootstrap-icons';
import { RoutineFullData, WeightSetType, WorkoutExerciseData, WorkoutExerciseSetData } from '../../../../common/types';
import usePublicPrivateExercise from '../../../lib/hooks/usePublicPrivateExercise';
import useRpeCalculator from '../../../lib/hooks/useRpeCalculator';
import useUnits from '../../../lib/hooks/useUnits';
import { usePreviewExercise1Rms } from '../../context/PreviewExercise1Rms';
import { usePreviewRoutine } from '../../context/PreviewRoutineContext';
import { usePreviewSet } from '../../context/PreviewSetContext';
import { usePreviewWorkoutExercise } from '../../context/PreviewWorkoutExerciseContext';
import HardyTooltip from '../../ui/HardyTooltip';
import { formatWeight, getUnitLabel } from '../../weight/Weight';

function WeightIntensityColumn() {
  const set = usePreviewSet() as WorkoutExerciseSetData;

  switch(set.weight_set_type) {
  case WeightSetType.NO_SUGGESTIONS:
    return (<span className="text-muted">–</span>);
  case WeightSetType.RPE:
    return (
      <HardyTooltip message={'Intensity based on your RPE (Reps in Reserve)'} >
        <span className="has-overlay">@RPE{set.intensity_rpe}</span>
      </HardyTooltip>
    );    
  case WeightSetType.TM:
    return (
      <HardyTooltip message={'Intensity based on your Training Max %'} >
        <span className="has-overlay">{set.intensity_weight_tm_percentage}% TM</span>
      </HardyTooltip>
    );
  case WeightSetType._1RM:
    return (
      <HardyTooltip message={'Intensity based on your 1 Rep Max'} >
        <span className="has-overlay">{set.intensity_weight_1rm_percentage}% 1RM</span>
      </HardyTooltip>
    );
  default:
    return null;
  }
}

function TmWeightColumn () {
  const set = usePreviewSet() as WorkoutExerciseSetData;
  const routine = usePreviewRoutine() as RoutineFullData;
  const _1rms = usePreviewExercise1Rms();
  const unit = useUnits();
  const unitLabel = getUnitLabel(unit);
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);

  const tmExercise = woe.intensity_weight_tm_percentage_exercise_id as number;
  const _1rm = _1rms[tmExercise];
  const tmWeight = (routine.tm_percent_from_1rm) / 100 * _1rm;
  const tmPercent = set.intensity_weight_tm_percentage as number;
  const weight = (tmPercent / 100) * tmWeight;

  if (!set || !exercise) {
    return null;
  }

  const weightValue = weight ? formatWeight(weight, exercise.type, {
    units: unit,
    roundToUsableWeight: true,
    valueOnly: true
  }) : '';

  return (<b>{weightValue ? `${weightValue} ${unitLabel}` : ''}</b>);
}

function _1RMWeightColumn () {
  const set = usePreviewSet() as WorkoutExerciseSetData;
  const _1rms = usePreviewExercise1Rms();
  const unit = useUnits();
  const unitLabel = getUnitLabel(unit);
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);
  const _1rm = _1rms[woe.exercise_id];
  const _1rmPercent = set.intensity_weight_1rm_percentage as number;
  const weight = (_1rmPercent / 100) * _1rm;

  if (!set || !exercise) {
    return null;
  }

  const weightValue = weight ? formatWeight(weight, exercise.type, {
    units: unit,
    roundToUsableWeight: true,
    valueOnly: true
  }) : '';

  return (<b>{weightValue ? `${weightValue} ${unitLabel}` : ''}</b>);
}

function RpeWeightColumn () {
  const { calculateWeightFromRPE } = useRpeCalculator();
  const set = usePreviewSet() as WorkoutExerciseSetData;
  const _1rms = usePreviewExercise1Rms();
  const unit = useUnits();
  const unitLabel = getUnitLabel(unit);
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);
  const _1rm = _1rms[woe.exercise_id];
  const weight = calculateWeightFromRPE(set.intensity_rpe as number, set.intensity_repetitions as number, _1rm);
  if (!set || !exercise) {
    console.log('no set no exercise');
    return null;
  }

  const weightValue = weight ? formatWeight(weight, exercise.type, {
    units: unit,
    roundToUsableWeight: true,
    valueOnly: true
  }) : '';

  return (<b>{weightValue ? `${weightValue} ${unitLabel}` : ''}</b>);
}


function WeightColumn() {
  const set = usePreviewSet() as WorkoutExerciseSetData;

  switch(set.weight_set_type) {
  case WeightSetType.NO_SUGGESTIONS:
    return (<span className="text-muted">–</span>);
  case WeightSetType.RPE:
    return (<RpeWeightColumn />);    
  case WeightSetType.TM:
    return (<TmWeightColumn />);
  case WeightSetType._1RM:
    return (<_1RMWeightColumn />);
  default:
    return null;
  }
}

export default function WeightSet(props: { index: number; }) {
  const unit = useUnits();
  const set = usePreviewSet() as WorkoutExerciseSetData;
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);

  if (!set || !exercise) {
    return null;
  }

  const hasAmrapRules = set.amrap_overload_rules && set.amrap_overload_rules.length;
  const type = set.weight_set_type === WeightSetType.TM ? 'TM': '1RM';
  return (
    <>
      <div className="woe-cus-row routine-woe-sets-row routine-woe-sets-row--preview">
        <div className="woe-cus-col-1">
          <span className="text-muted">{props.index + 1}</span>
        </div>
        <div className="woe-cus-col-2 text-right">
          <WeightIntensityColumn />
        </div>
        <div className="woe-cus-col-3 text-right">
          <WeightColumn />
        </div>
        <div className="woe-cus-col-4">
          <div className={hasAmrapRules ? 'po-highlight ml-0' : 'ml-2'}>
            <span className={set.amrap ? 'amrap-highlight pl-2' : 'ml-2'}>
              <strong className="rep-text">
                <span className="rep-number">
                  {set.intensity_repetitions}
                </span>
                {
                  set.amrap ? (
                    <span className="rep-plus"> +</span>
                  ) : null
                }
              </strong>
              {
                set.amrap ? (
                  <HardyTooltip message='As Many Reps As Possible' >
                    <div className="amrap-tag ml-3"><Check2 /> amrap</div>
                  </HardyTooltip>
                ) : null
              }
            </span>
            {
              hasAmrapRules ? (
                <HardyTooltip message='This set includes suggested Progressive Overload rules' >
                  <div className="po-tag ml-2"><Check2 /> Prog. Overload</div>
                </HardyTooltip>
              ) : null
            }
          </div>
        </div>

        <div className="woe-cus-col-5 text-right">
          {set.rest_time} sec
        </div>
      </div>

      {set.amrap_overload_rules && set.amrap_overload_rules.length ? (
        <div className="woe-cus-row routine-woe-sets-row routine-woe-sets-row--amrap routine-woe-sets-row--preview">
          <div className="woe-cus-col-1 hidden-xs"></div>
          <div className="woe-cus-col-2 hidden-xs"></div>
          <div className="woe-cus-col-3 hidden-xs"></div>
          <div className="woe-cus-col-4 woe-cus-col-4--amrap">
            <div className="po-box">
              <p className="mb-0">
                This AMRAP set is also a target for Progressive Overload rules.
                If you do more reps than <b>{set.intensity_repetitions}</b>, the {type} will be suggested to increase next session.
              </p>

              <Row className="routine-woe-sets-header mt-0 ml-3 mr-3">
                <Col className="pl-0 pr-0"><h5>Progressive Overload</h5></Col>
              </Row>

              {set.amrap_overload_rules.map((aol, i) => {
                return (
                  <Row key={i} className="ml-2 mr-2 pt-2 pb-2">
                    <Col>
                      Extra <b>{set.amrap_overload_rules[i].min}{set.amrap_overload_rules[i].max ? `-${set.amrap_overload_rules[i].max}` : '+'}</b> reps, increase {type} by <b>{formatWeight(set.amrap_overload_rules[i].amount, exercise.type, {
                        units: unit,
                        roundToUsableWeight: true,
                        valueOnly: false
                      })}</b>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
          <div className="woe-cus-col-5 hidden-xs"></div>
        </div>
      ) : null}
    </>
  );
}

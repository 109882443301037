import React from 'react';
import { Button } from 'react-bootstrap';
import WorkoutComponent from './Workout';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../lib/store';
import slice from '../../../lib/slices/routine-editor';

export default function WorkoutListComponent() {

  const dispatch = useDispatch();
  const workouts = useSelector((state: RootState) => state.routineEditorState.createData.workouts);

  return (
    <>
      <h2 className="caps-condensed">Workouts</h2>
      {workouts.map((workout) => {
        return <WorkoutComponent
          key={workout.index}
          workoutIndex={workout.index}
        />;
      })}
      <div className="routine-workout">
        <div className="routine-workout-header routine-workout-header--clickable" onClick={() => dispatch(slice.actions.addWorkout())}>
          <Button className="btn-add-lg"><span className="add-plus mr-2 ml-1"><PlusCircleFill /></span> Add Workout</Button>
        </div>
      </div>
    </>);
}

import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import usePostAsync from '../../lib/hooks/usePostAsync';

function UserRemoveFacebookPage () {
  const {value, error, getResource, loading} = usePostAsync<any, object>('/auth/facebook-delete');

  console.log('value', value);
  console.log('error', error);
  console.log('loading', loading);

  return (
    <Container className="pt-4">
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <h2 className="text-center mt-5 caps-condensed">Remove Facebook Data</h2>

          <div className="content-area mb-4 text-center">

            { value ? (
              <p>Facebook data successfully removed from user &quot;{value.data.username}&quot;</p>
            ) : null }

            {
              loading ? (
                <p>loading</p>
              ) : null
            }

            {
              !value && !loading ? (
                <p> Currently the only data provided by facebook that we have stored is facebook user ID, If you choose to remove it, you will not be able to use Facebook Login </p>
              ) : null
            }

            { !value ? (
              <Button size="lg" variant="danger" type="submit" block onClick={() => getResource({})}>
                Remove Facebook Data
              </Button>
            ) : null }

          </div>

          <div className="content-area mb-4 text-center">
            <p>Should you also choose to delete your Hardy account, please write us at <a href='mailto:hello@hardy.app'>hello@hardy.app</a></p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default UserRemoveFacebookPage;

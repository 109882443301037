import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Dropdown } from 'react-bootstrap';
import { GraphDown, Pencil, Trash } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DeloadType, WorkoutExerciseDeloadRule, WorkoutExerciseDeloadRule1RM, WorkoutExerciseDeloadRuleTM } from '../../../../common/types';
import usePublicPrivateExercise from '../../../lib/hooks/usePublicPrivateExercise';
import useRoutineEditorError from '../../../lib/hooks/useRoutineEditorError';
import slice from '../../../lib/slices/routine-editor';
import { RootState } from '../../../lib/store';
import EditableText from '../../form/EditableText';
import ValidationFormControlComponent from '../../form/ValidationFormControl';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

function SelectDeloadType({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].deload_rule);

  const selectedTmData = {
    deload_type: DeloadType.TM,
    // @ts-ignore
    tm_deload_percentage: rule?.deload_percentage_1rm ?? 10,
    deload_percentage_1rm: undefined,
  };

  const selected1RMData = {
    deload_type: DeloadType._1RM,
    // @ts-ignore
    deload_percentage_1rm: rule?.tm_deload_percentage ?? 10,
    tm_deload_percentage: undefined,
  };

  const selectNewType = (type: DeloadType) => {
    if (rule && type === rule?.deload_type) {
      return;
    }

    const newValues = type === DeloadType.TM ? selectedTmData: selected1RMData;
    const deloadRule = { ...rule, ...newValues } as WorkoutExerciseDeloadRule;

    dispatch(slice.actions.updateExercise({ workoutIndex, exerciseIndex, data: { deload_rule: deloadRule } }));
  };

  return (
    <Dropdown className="inline-dropdown">
      <Dropdown.Toggle variant="outline-secondary">
        {rule?.deload_type}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => selectNewType(DeloadType._1RM)} active={rule?.deload_type === DeloadType._1RM ? true : false }>
          1RM
        </Dropdown.Item>
        <Dropdown.Item onClick={() => selectNewType(DeloadType.TM)} active={rule?.deload_type === DeloadType.TM ? true : false }>
          TM
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DeloadText({ workoutIndex, exerciseIndex }: Props) {
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].deload_rule);
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exercise = usePublicPrivateExercise(workoutExercise.exercise_id || 1);
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);
  if (!rule || (rule.deload_type === DeloadType.TM && !targetExercise) || !exercise) {
    return null;
  }

  const sessions = rule.failed_sessions_trigger_number == 1 ? 'session' : 'sessions';
  const increaseValue = rule.deload_type === DeloadType.TM
    ? rule.tm_deload_percentage
    : rule.deload_percentage_1rm;
  const type = rule.deload_type === DeloadType.TM ? 'TM' : '1RM';

  return (
    <Row className="routine-woe-sets-row align-items-center" noGutters>
      <Col xs={11}>
        <p className="mb-0"><div className="icon-deload"><GraphDown /></div>  <b>Deload:</b> After <b>{rule.failed_sessions_trigger_number}</b> uncompleted {sessions}, <b>{type}</b> is suggested to decrease by <b>{increaseValue} %</b></p>
      </Col>
      <Col xs={1} className="text-right">
        <Button className="btn-icon" variant="outline-secondary">
          <Pencil />
        </Button>
      </Col>
    </Row>
  );
}

function DeleteDeloadRuleButton({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  return (
    <Button className="btn-icon btn-delete text-muted" variant="outline-secondary" onClick={() => dispatch(slice.actions.updateExercise({ workoutIndex, exerciseIndex, data: { deload_rule: undefined } }))} >
      <Trash />
    </Button>
  );
}

function DeloadPercentageInput({ workoutIndex, exerciseIndex }: Props) {
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].deload_rule);
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}`;
  const dispatch = useDispatch();

  const updatePercentageTM = (e: any) => dispatch(slice.actions.updateExercise({
    workoutIndex,
    exerciseIndex,
    data: {
      deload_rule: {
        ...rule,
        tm_deload_percentage: e.target.value ? Number(e.target.value) : undefined,
        //@ts-ignore
        deload_percentage_1rm: undefined
      } as WorkoutExerciseDeloadRuleTM
    }
  }));

  const updatePercentage1RM = (e: any) => dispatch(slice.actions.updateExercise({
    workoutIndex,
    exerciseIndex,
    data: {
      deload_rule: {
        ...rule,
        deload_percentage_1rm: e.target.value ? Number(e.target.value) : undefined,
        //@ts-ignore
        tm_deload_percentage: undefined,
      } as WorkoutExerciseDeloadRule1RM
    }
  }));

  const defaultValue = rule?.deload_type === DeloadType.TM ? rule.tm_deload_percentage : rule?.deload_percentage_1rm;
  const onChange = rule?.deload_type === DeloadType.TM ? updatePercentageTM : updatePercentage1RM;
  return (
    <span>
      <div className="custom-input-group custom-input-group--2">
        <ValidationFormControlComponent
          name={`${validationPrefix}_deload_rule_tm_deload_percentage`}
          formCtrl={{
            onChange,
            defaultValue,
            type: 'number',
            placeholder: '0'
          }}
          errors={errors}
        />

        <span>
          %
        </span>
      </div>
    </span>
  );
}

export default function DeloadRule({ workoutIndex, exerciseIndex }: Props) {
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].deload_rule);
  const [failedSessionsTouched, setFailedSessionsTouched] = useState(false);
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}`;
  const dispatch = useDispatch();

  useEffect(() => {
    if (rule?.failed_sessions_trigger_number) {
      setFailedSessionsTouched(true);
    }

  }, [rule]);

  if (!rule) {
    return null;
  }

  const failedSessionsTriggerFormCtrl = {
    onChange: (e: any) => dispatch(slice.actions.updateExercise({
      workoutIndex,
      exerciseIndex,
      data: {
        deload_rule: {
          ...rule,
          // @ts-ignore
          failed_sessions_trigger_number: e.target.value ? Number(e.target.value): undefined
        }
      }
    })),
    defaultValue: rule.failed_sessions_trigger_number,
    type: 'number',
    autoFocus: true,
    placeholder: '0'
  };

  return (
    <div className="">
      <Form.Group className="routine-woe-sets mb-0">
        <EditableText>
          <div className="routine-woe-sets-row-holder">
            <Row className="routine-woe-sets-row" noGutters>
              <Col xs={11}>
                <div className="inputs-inline">
                  <div className="icon-deload"><GraphDown /></div>  <b>Deload:</b> After
                  <ValidationFormControlComponent
                    name={`${validationPrefix}_deload_rule_failed_sessions_trigger_number`}
                    formCtrl={failedSessionsTriggerFormCtrl}
                    errors={errors}
                    hideError={!failedSessionsTouched}
                  />
                  uncompleted sessions, decrease <SelectDeloadType workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} /> by
                  <DeloadPercentageInput workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
                </div>
              </Col>
              <Col xs={1} className="text-right">
                <DeleteDeloadRuleButton workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
              </Col>
            </Row>
          </div>
          <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--clickable">
            <DeloadText workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
          </div>
        </EditableText>
      </Form.Group>
    </div>
  );
}

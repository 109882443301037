import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiAction, Methods } from '../middleware/api';


interface Response<T,B> {
  loading: boolean;
  value?: T;
  error: any;
  getResource: (body: B) => void
}

export default function usePostAsync<ReturnValue, RequestBody extends object>(path: string): Response<ReturnValue, RequestBody>{
  const dispatch = useDispatch();
  const [value, setValue] = useState<ReturnValue>();
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  function getResource(body: RequestBody) {
    setLoading(true);
    const apiCall = apiAction<ReturnValue>({
      path,
      method: Methods.POST,
      body,
      onFailure: (e) => {
        setValue(undefined);
        setError(e);
        setLoading(false);
      },
      onSuccess: (resp) => {
        setValue(resp);
        setLoading(false);
      }
    });

    dispatch(apiCall);
  }

  return {
    value,
    error,
    loading,
    getResource,
  };
}
import React, { useEffect, useRef, useState } from 'react';
import useClickOutsideHandler from '../../lib/hooks/useClickOutsideHandler';

interface Props {
  children: any;
  valueClass?: string;
  editorClass?: string;
  forceCancel?: boolean;
  edittingChanged?: (isEditting: boolean) => void;
}

function EditableText({ children, valueClass, editorClass, forceCancel, edittingChanged }: Props) {

  const [edit, setEdit] = useState(false);

  const ref = useRef(null);

  useClickOutsideHandler(ref, () => {
    setEdit(false);
  });

  useEffect(() => {
    if (forceCancel) {
      setEdit(false);
    }
  }, [forceCancel]);

  useEffect(() => {
    if (edittingChanged) {
      edittingChanged(edit);
    }
  }, [edittingChanged, edit]);

  if (!edit) {
    return (
      <div
        onClick={() => setEdit(true)}
        className={valueClass ? valueClass : ''}
      >
        {children[1]}
      </div>
    );
  }

  return (
    <div ref={ref} className={editorClass ? editorClass : ''}>
      {children[0]}
    </div>
  );
}

export default EditableText;

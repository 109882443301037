import { useState } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const _200Days = 60 * 60 * 24 * 200;

export default function useCookie (key: string, defaultValue: string) {
  const getCookie = () => cookies.get(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());
  const updateCookie = (value: string) => {
    setCookie(value);
    cookies.set(key, value, {
      path: '/',
      maxAge: _200Days
    });
  };
  return [cookie, updateCookie];
}

export function useBooleanCookie (key: string, defaultValue: boolean): [boolean, (val: boolean) => void] {
  const [cookie, updateCookie] = useCookie(key, defaultValue ? '1' : '0');
  const booleanCookie = !!parseInt(cookie);
  const updateBooleanCookie = (val: boolean): void => {
    updateCookie(val ? '1': '0');
  };

  return [booleanCookie, updateBooleanCookie];
}
import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import { Trash, List } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import useRoutineEditorError from '../../../../lib/hooks/useRoutineEditorError';
import slice from '../../../../lib/slices/routine-editor';
import { RootState } from '../../../../lib/store';
import HardyInputComponent from '../../../form/HardyInput';
import { SetAutoFocusInput } from './SetRowEditorSwitch';

interface SetRowEditorProps {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  autoFocusElement: SetAutoFocusInput
}

enum EditableKeys {
  INTENSITY_TIME = 'intensity_time',
  REST_TIME = 'rest_time'
}

const BodyweightTimeSetRowEdit = ({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps, ref: any) => {
  const dispatch = useDispatch();
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}`;
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const set = workoutExercise.sets[setIndex];

  function onSetNumberChange(key: string) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(slice.actions.updateSet({
        workoutIndex,
        exerciseIndex,
        setIndex,
        data: {
          [key]: e.target.value ? Number(e.target.value) : undefined
        }
      }));
    };
  }

  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--edit" ref={ref}>
      <div className="woe-cus-row routine-woe-sets-row">
        <div className="woe-cus-col-1">
          <List className="set-number-drag"/>
        </div>
        <div className="woe-cus-col-2">
        </div>
        <div className="woe-cus-col-3-4 text-right">
          <div className="custom-input-group custom-input-group--bodyweight-time">
            <HardyInputComponent
              hideError={true}
              name={`${validationPrefix}_${EditableKeys.INTENSITY_TIME}`}
              errors={errors} formCtrl={{
                type: 'number',
                autoFocus: autoFocusElement === SetAutoFocusInput.INTENSITY_TIME,
                selectOnFocus: autoFocusElement === SetAutoFocusInput.INTENSITY_TIME,
                onChange: onSetNumberChange(EditableKeys.INTENSITY_TIME),
                value: String(set.intensity_time),
                min: 0
              }}
            />
            <span>
              sec
            </span>
          </div>
        </div>
        <div className="woe-cus-col-5 text-right">
          <div className="custom-input-group custom-input-group--rest">
            <HardyInputComponent
              hideError={true}
              name={`${validationPrefix}_${EditableKeys.REST_TIME}`}
              errors={errors} formCtrl={{
                type: 'number',
                autoFocus: autoFocusElement === SetAutoFocusInput.REST_TIME,
                selectOnFocus: autoFocusElement === SetAutoFocusInput.REST_TIME,
                onChange: onSetNumberChange(EditableKeys.REST_TIME),
                value: String(set.rest_time),
                min: 0
              }}
            />
            <span>
              sec
            </span>
          </div>
        </div>
        <div className="woe-cus-col-7 text-right">
          <Button className="btn-icon btn-delete" variant="outline-secondary" onClick={() => dispatch(slice.actions.removeSet({
            workoutIndex,
            exerciseIndex,
            setIndex
          }))}>
            <Trash />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(BodyweightTimeSetRowEdit);

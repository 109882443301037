export enum UserUnit {
  IMPERIAL = 'imperial',
  METRIC = 'metric'
}

export interface UserCreateData {
  facebook_id?: string;
  google_id?: string;
  email?: string;
  password?: string;
  username?: string;
  units: UserUnit;
}

export interface UserUpdateData {
  email?: string;
  units?: UserUnit;
  username?: string;
  password?: string;
}

export interface UserData extends UserIncompleteData {
  id: number;
  facebook_id?: string;
  google_id?: string;
  email?: string;
  password?: string;
  units: UserUnit;
  created_at: string;
  updated_at: string;

  impersonating?: boolean;
  admin: boolean;
}

export interface UserIncompleteData {
  username: string;
}

export interface AdminUserStatsResponse {
  id: number;
  email: string;
  username: string;
  created_at: string;
  workouts_completed: number;
  routines_subscribed_to: number;
  created_private_routines: number;
  created_public_routines: number;
}

export interface AdminWorkoutStats {
  minutes_working_out: number;
  hours_working_out: number;
  client_start_time: string;
  client_end_time: string;
  routine_id: number;
  routine_name: string;
  user_username: string;
  user_email: string;
}


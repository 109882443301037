import React from 'react';
import { WorkoutExerciseType } from '../../../../../common/types';
import useEditorWorkoutExerciseType from '../../../../lib/hooks/useEditorWorkoutExerciseType';
import BodyWeightRepSetHeader from './BodyweightRepSetHeader';
import BodyWeightTimeSetHeader from './BodyweightTimeSetHeader';
import WeightSetHeader from './WeightSetHeader';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

function SetsHeader (props: Props) {
  const { workoutIndex, exerciseIndex } = props;
  const woeType = useEditorWorkoutExerciseType({ workoutIndex, exerciseIndex });

  if (woeType === WorkoutExerciseType.BODYWEIGHT_TIME) {
    return (<BodyWeightTimeSetHeader />);
  }

  if (woeType === WorkoutExerciseType.BODYWEIGHT_REP) {
    return (<BodyWeightRepSetHeader />);
  }

  return (<WeightSetHeader />);
}

export default SetsHeader;

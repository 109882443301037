import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import { WorkoutExerciseData } from '../../../../common/types';
import usePublicPrivateExercise from '../../../lib/hooks/usePublicPrivateExercise';
import { PreviewSetContextProvider } from '../../context/PreviewSetContext';
import { usePreviewWorkoutExercise } from '../../context/PreviewWorkoutExerciseContext';
import HardyTooltip from '../../ui/HardyTooltip';
import BodyweightRepSet from '../set/BodyweightRepSet';

export default function BodyweightRepExercise() {
  const woe = usePreviewWorkoutExercise() as WorkoutExerciseData;
  const exercise = usePublicPrivateExercise(woe.exercise_id);
  const history = useHistory();
  const [isExerciseOpen, setIsExerciseOpen] = useState(true);

  if (!exercise || !woe) {
    return null;
  }

  return (
    <div className="routine-workout-exercise">
      <div className={(isExerciseOpen) ? 'd-block' : 'd-none'}>
        <Row className="routine-woe-header">
          <Col className="routine-woe-header-col-clickable" onClick={() => setIsExerciseOpen(false)}>
            <Row className="align-items-center">
              <Col className="align-items-center d-flex">
                <ChevronDown className="woe-chevron" />
                <HardyTooltip message={exercise.type}>
                  <div className={'icon-exercise icon-exercise--' + exercise.type}></div>
                </HardyTooltip>
                <h4 className="mb-0">{exercise.name}</h4>
              </Col>
            </Row>
          </Col>
          <Col xs={'auto'}>
            <Button variant="primary" className="btn-start mt-2" onClick={() => history.push('/download')}>Start<span className="hidden-xs"> in App</span></Button>
          </Col>
        </Row>

        <div className="routine-wow-collapse-holder">
          <Collapse appear={true} in={isExerciseOpen}>
            <div className="routine-wow-collapse-inner">
              <Row className="routine-woe-sets mt-4 mb-3" noGutters>
                <Col>
                  <Row className="">
                    <Col>
                      <div className="woe-cus-row routine-woe-sets-header routine-woe-sets-row--preview">
                        <div className="woe-cus-col-1">
                          <h5>Set</h5>
                        </div>
                        <div className="woe-cus-col-2">
                        </div>
                        <div className="woe-cus-col-3">
                        </div>
                        <div className="woe-cus-col-4 text-right">
                          <h5 className="ml-2 pl-1">Reps</h5>
                        </div>
                        <div className="woe-cus-col-5 text-right">
                          <h5>Rest time</h5>
                        </div>
                      </div>
                      {woe.sets.map((set, index) => (
                        <PreviewSetContextProvider set={set} key={index} >
                          <BodyweightRepSet index={index} />
                        </PreviewSetContextProvider>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>

      </div>
      <div className={(isExerciseOpen) ? 'd-none' : 'd-block woe-minimized'}>
        <Row className="routine-woe-header">
          <Col className="routine-woe-header-col-clickable" onClick={() => setIsExerciseOpen(true)}>
            <Row className="align-items-center">
              <Col xs={12} sm={7} className="align-items-center d-flex mb-2 mb-sm-0">
                <ChevronRight className="woe-chevron" />
                <HardyTooltip message={exercise.type} >
                  <div className={'icon-exercise icon-exercise--' + exercise.type}></div>
                </HardyTooltip>
                <h4 className="mb-0">{exercise.name}</h4>
              </Col>
              <Col xs={{ span: 4, offset: 1 }} sm={{ span: 2, offset: 0 }}>
                <h5 className="routine-woe-header-sets">
                  {woe.sets.length} sets
                </h5>
              </Col>
            </Row>
          </Col>
          <Col xs={'auto'}>
            <Button variant="primary" className="btn-start mt-2" onClick={() => history.push('/download')}>Start<span className="hidden-xs"> in App</span></Button>
          </Col>
        </Row>
      </div>
    </div>
  );

}
import React, { useContext, createContext, ReactNode } from 'react';
import { WorkoutExerciseData } from '../../../common/types';

export const PreviewWorkoutExerciseContext = createContext<WorkoutExerciseData | undefined>(undefined);

export function usePreviewWorkoutExercise () {
  return useContext(PreviewWorkoutExerciseContext);
}

export function PreviewWorkoutExerciseContextProvider ({children, workoutExercise }: { children: ReactNode, workoutExercise: WorkoutExerciseData }) {
  return (
    <PreviewWorkoutExerciseContext.Provider value={workoutExercise}>
      {children}
    </PreviewWorkoutExerciseContext.Provider>
  );
}
import * as Joi from 'joi';

export const UserUpdateSchema = Joi.object({
  units: Joi.string().valid('imperial', 'metric').required().label('Units')
});

export const PasswordRecoverSchema = Joi.object({
  password: Joi.string().min(6).max(32).required().label('Password'),
  token: Joi.string().required().label('token')
});

export const UserCompleteSignupInputSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).label('Email'),
  password: Joi.string().min(6).max(32).label('Password'),
  username: Joi.string().min(4).max(32).required().label('Username'),
  units: Joi.string().valid('imperial', 'metric').required().label('Units'),
});

export const UserUpdateInputSchema = Joi.object({
  units: Joi.string().valid('imperial', 'metric').required().label('Units')
});

export const UserLoginInputSchema = Joi.object({
  email: Joi.string().required().label('Email'),
  password: Joi.string().required().label('Password')
});

export const RegisterFBSchema = Joi.object({
  units: Joi.string().valid('imperial', 'metric').required().label('Units'),
  token: Joi.string().required().label('facebook token')
});

export const RegisterEmailSchema = Joi.object({
  units: Joi.string().valid('imperial', 'metric').required().label('Units'),
  email: Joi.string().email({ tlds: { allow: false } }).required().label('Email')
});

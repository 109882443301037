import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import ValidationErrorComponent from './ValidationError';
import UnitSelect, { UnitSelectProps } from '../units/UnitSelect';

interface Props {
  errors?: { validation?: { [key: string]: string; }}
  name: string;
  formCtrl: UnitSelectProps
}

export default function HardyUnitsDropdownComponent({ errors, name, formCtrl }: Props) {
  const [touched, setTouched] = useState(false);

  const changeFunc = formCtrl.onSelected;
  formCtrl.onSelected = (input: any) => {
    setTouched(true);
    changeFunc(input);
  };

  return (<>
    <UnitSelect {...formCtrl} />
    {
      touched ?
        (<Row>
          <Col xs={12} className={'validation-error-message'}>
            <ValidationErrorComponent name={name} errors={errors}/>
          </Col>
        </Row>)
        : null
    }
  </>);
}

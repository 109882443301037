import React from 'react';
import { selectUser } from '../../lib/slices/auth';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import CompleteRegistrationForm from './CompleteRegistrationForm';
import StartRegistrationForm from './StartRegistrationForm';

function RegistrationFlow() {
  const user = useSelector(selectUser);
  const location = useLocation<{
    redirectPath: string;
  }>();
  const path = location.state?.redirectPath || '/';

  if (user?.username && user?.email) {
    return <Redirect to={path} />;
  }

  if (user?.facebook_id || user?.google_id || user?.email) {
    return <CompleteRegistrationForm />;
  }

  return <StartRegistrationForm />;
}

export default RegistrationFlow;

import { RouteAuthPostLoginOutput, UserData, UserUnit } from '../../../common/types/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../middleware/api';
import { Dispatch } from 'react';
import AuthService from '../services/auth.service';
import { RootState } from '../store';
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
const timezone = moment.tz.guess();
const guessedUnit = !timezone.match('America') ? UserUnit.METRIC : UserUnit.IMPERIAL;
const cookies = new Cookies();
export interface AuthState {
    data?: UserData;
    token?: string;
    guestUnit: UserUnit;
    loading: boolean;
    error?: any;
}

const initialState: AuthState = {
  data: undefined,
  token: undefined,
  guestUnit: cookies.get('guest-unit') || guessedUnit || UserUnit.IMPERIAL,
  loading: false,
  error: undefined
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state: AuthState, action: PayloadAction<{data: UserData; token: string}>) {
      state.data = action.payload.data;
      state.token = action.payload.token;
      state.loading = false;
      state.error = undefined;
    },
    logOut() {
      return {
        ...initialState,
        loading: false
      };
    },
    loginPending (state) {
      state.loading = true;
    },
    loginFulfilled (state, action: PayloadAction<RouteAuthPostLoginOutput>) {
      state.loading = false;
      state.data = action.payload.data;
      state.token = action.payload.token;
      state.error = undefined;
    },
    loginRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.data = undefined;
      state.token = undefined;
      state.error = action.payload;
    },
    setGuestUnit(state, action: PayloadAction<UserUnit>) {
      cookies.set('guest-unit', action.payload, { path: '/' });
      state.guestUnit = action.payload;
    }
  }
});

export function login(
  {email, password}: {email: string; password: string;}
) {
  return (dispatch: Dispatch<any>) => {
    dispatch(authSlice.actions.loginPending());

    const action = AuthService.loginAction({
      body: {email, password}
    }, {
      onSuccess: (r) => { dispatch(authSlice.actions.loginFulfilled(r)); },
      onFailure: (e) => { dispatch(authSlice.actions.loginRejected(e)); }
    });

    dispatch(action);
  };
}

export function logout() {
  return (dispatch: Dispatch<any>) => {
    dispatch(authSlice.actions.loginPending());

    const action = AuthService.logoutAction({
      onSuccess: () => { dispatch(authSlice.actions.logOut()); },
      onFailure: () => { dispatch(authSlice.actions.logOut()); }
    });

    dispatch(action);
  };
}

export const selectUser = (state: RootState) => state.authState.data || undefined;
export const selectIsGuest = (state: RootState) => !state.authState.data?.id;
export const selectIsLoggedInIncompleteUser = (state: RootState) => state.authState.data?.id && (state.authState.data?.facebook_id || state.authState.data?.google_id || state.authState.data?.email);

export default authSlice;

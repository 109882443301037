import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import RoutinePreviewComponent from '../preview/RoutinePreviewComponent';
import { RoutineStatus } from '../../../common/types/index';
import { getFullRoutine, setRoutineStatus, softDeleteRoutine } from '../../lib/slices/routine.one-full';
import { useRouteMatch } from 'react-router';
import { RootState } from '../../lib/store';
import { publicUrl } from '../../lib/config';
import { Helmet } from 'react-helmet';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { DumbbellIcon } from '../icon';
const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

function RoutineOnePageComponent () {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch<{id: string}>();
  const routineId = routeMatch.params.id;
  const routineOneFullState = useSelector((state: RootState) => state.routineOneFullState);
  const user = useSelector((state: RootState) => state.authState.data);

  useEffect(() => {
    dispatch(getFullRoutine(routineId));
  }, []);

  function changeStatus(status: RoutineStatus) {
    dispatch(setRoutineStatus(routineId, { status }));
  }

  function deleteRoutine() {
    dispatch(softDeleteRoutine(routineId));
  }

  if (routineOneFullState.error?.code) {
    return (
      <div className="page-loading">
        <div className="d-block text-center">
          <div className="routine-img-holder routine-img-holder--lg mb-20 float-none">
            <DumbbellIcon />
          </div>
          <h4 className="text-muted mt-4 text-center">Routine not found or is set to private.</h4>
        </div>
      </div>
    );
  }
  if (!routineOneFullState.data || routineOneFullState.loading) {
    return (<div className="page-loading"><Spinner animation="grow" /></div>);
  }

  const contentUrl = `https://www.hardy.app/routine/${routineOneFullState.data.slug}-${routineOneFullState.data.id}`;

  return (<>
    <Helmet titleTemplate="Hardy - %s">
      <title>Workout Routine {routineOneFullState.data.name}</title>
      <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
      <meta property="og:title" content={`Hardy - ${routineOneFullState.data.name}`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={contentUrl} />
      <meta property="og:image" content={socialMediaFullUrl} />
      <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
    </Helmet>
    <RoutinePreviewComponent
      routineData={routineOneFullState.data}
      changeStatus={(status: RoutineStatus) => { changeStatus(status); }}
      deleteRoutine={() => { deleteRoutine(); }}
      user={user}
      onSave={() => {}}
      editting={false}
    />
  </>
  );
}

export default RoutineOnePageComponent;

import React from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import HardyInput from '../form/HardyInput';
import HardyInputGroupComponent from '../form/HardyInputGroup';
import { Pencil } from 'react-bootstrap-icons';
import EditableText from '../form/EditableText';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import editorSlice from '../../lib/slices/routine-editor';
import useRoutineEditorError from '../../lib/hooks/useRoutineEditorError';
import MDEditor from '@uiw/react-md-editor';

enum RoutineDataEditable {
  NAME = 'name',
  DESCRIPTION = 'description',
  MAX_WEEKS = 'max_weeks',
  TM_PERCENT_FROM_1RM = 'tm_percent_from_1rm'
}

function RoutineBasicInfoEditor () {
  const dispatch = useDispatch();
  const basicInfo = useSelector((state: RootState) => state.routineEditorState.createData);
  const errors = useRoutineEditorError();
  return (
    <>
      <Row>
        <Col md={12} lg={8}>
          <EditableText>
            <Form.Group as={Row} noGutters>
              <Col xs={12}>
                <div className="input-fake-editable input-fake-editable--h1">
                  <HardyInput name='name' errors={errors} formCtrl={{
                    type: 'text',
                    placeholder: 'Routine title here',
                    onChange: (e: any) => dispatch(editorSlice.actions.updateBasicInfo({name: e.target.value})),
                    value: basicInfo.name,
                    autoFocus: true,
                  }}/>
                </div>
              </Col>
            </Form.Group>
            <div className="editable-text">
              <h1>
                {basicInfo.name ? basicInfo.name : (
                  <span className="editable-text-empty">Routine title here</span>
                )}
                <Button className="btn-icon edit-pencil" variant="outline-secondary">
                  <Pencil />
                </Button>
              </h1>
            </div>
          </EditableText>

          <div className="routine-description">
            <EditableText>
              <div>
                <MDEditor
                  value={basicInfo.description || ''}
                  preview={'edit'}
                  hideToolbar={true}
                  onChange={(v) => dispatch(editorSlice.actions.updateBasicInfo({description: v}))}
                />
                <p className="textarea-subtext text-right mt-1"><small>Some <a href="https://www.markdownguide.org/getting-started/" target="_blank" rel="noreferrer">markdown</a> is supported</small></p>
              </div>
              <div className="editable-text">
                <MDEditor.Markdown
                  source={basicInfo.description ? basicInfo.description : 'Description here...'}
                  skipHtml={true}
                />
                <Button className="btn-icon edit-pencil" variant="outline-secondary">
                  <Pencil />
                </Button>
              </div>
            </EditableText>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <Card border="light" className="bg-light mb-5 your-stats">
            <Card.Body>
              <Row>
                <Col>
                  <h3 className="caps-condensed mb-3">Routine Settings</h3>
                </Col>
              </Row>
              <Form.Group as={Row}>
                <Form.Label column xs={12}>
                  Starting Training Max:
                </Form.Label>
                <Col xs={12}>
                  <div className="inputs-inline">
                    <span className="ml-0">
                      <HardyInputGroupComponent
                        inputGroupText={'%'}
                        name={RoutineDataEditable.TM_PERCENT_FROM_1RM} errors={errors}
                        formCtrl={{
                          type: 'number',
                          onChange: (e: any) => dispatch(editorSlice.actions.updateBasicInfo({tm_percent_from_1rm: e.target.value ? Number(e.target.value): undefined})),
                          value: String(basicInfo.tm_percent_from_1rm)
                        }}
                      />
                    </span>
                    of 1 Rep Max
                  </div>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RoutineBasicInfoEditor;

import { ExerciseData, ListResponse, ListQuery, RouteExerciseCreateCustomInput, RouteExerciseCreateCustomOutput } from '../../../common/types/index';
import {
  apiAction,
  ApiActionOptions,
  apiRequest,
  buildListQuery,
  Methods,
} from '../middleware/api';

export function listAction(
  { query }: { query: Partial<ListQuery> },
  { onSuccess, onFailure }: ApiActionOptions<ListResponse<ExerciseData>>
) {
  const path = '/exercise?';

  return apiAction<ListResponse<ExerciseData>>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function listAllAction(
  { onSuccess, onFailure }: ApiActionOptions<ListResponse<ExerciseData>>
) {
  const path = '/exercise/all';

  return apiAction<ListResponse<ExerciseData>>({
    method: Methods.GET,
    path,
    onSuccess,
    onFailure,
  });
}

export function createCustomRequest(body: RouteExerciseCreateCustomInput) {
  return apiRequest<RouteExerciseCreateCustomOutput>({
    path: '/exercise/create',
    method: Methods.POST,
    body
  });
}

export default { listAction, listAllAction, createCustomRequest };

import React, { useState } from 'react';
import './Testimonials.scss';
import { Container, Button } from 'react-bootstrap';
import TestimonialBox from './TestimonialBox';

function TestimonialsComponent () {
  const [showMoreTestimonials, setShowMoreTestimonials] = useState(false);
  const testimonials = [
    {
      stars: 4,
      title: 'Love the app',
      text: 'Downloaded this today and set up a workout program on it and deleted my old tracker almost immediately absolutely love the app.',
      name: 'trashgodkev',
      platform: 'Apple App Store',
      date: '13.02.2023',
    },
    {
      // stars: 4.5,
      title: '... finally!!! How long have I searched for an app like this 😍 keep up the great work.',
      name: 'PaderKing',
      platform: 'Reddit',
      date: '23.05.2023',
    },
    {
      stars: 5,
      title: 'Fantastic app',
      text: 'I never leave reviews for apps, but I had to leave one for the Hardy app. The level of customization is great. My favorite part of the app is once you get your workout routine plugged into the app, you don’t ever have to think about raising weight / deloading / progressive overload / etc. the app takes care of everything for you. I just go to the gym, open the app, and move weights. Everything else is taken care of for me.',
      name: 'conanwanda',
      platform: 'Apple App Store',
      date: '07.06.2023',
    },
    {
      stars: 5,
      title: 'Fantastic Workout App',
      text: 'This app has been a game changer for me. There are so many options and the ability to browse workout splits has been eye opening to see what’s out there. The deva have been very open to feedback and are constantly improving the app experience. Small things like the recent addition of the workout timer notification is something I’ve really appreciated and I plan on supporting and using this app well into the future! The dev roadmap on the site gives me a lot to look forward to!',
      name: 'Pikietchu',
      platform: 'Apple App Store',
      date: '13.12.2023',
    },
    {
      stars: 5,
      // title: 'Fantastic Workout App',
      text: 'Across all the fitness and workout apps out there, this one hits all the right spot. Clean UI, no bloat, no ads. Also worth mentioning is that this app is crowdsourced. Only problem I can think of is the notifications can be hit or miss for me, other than that its a 10/10',
      name: 'Sandy Urpis',
      platform: 'Google Play Store',
      date: '22.12.2023',
    },
    {
      stars: 5,
      title: 'So much value!',
      text: 'Love the app and its UI, feels very modern. It’s designed very well. I just wish it kept track of the weight you lift in all exercises such as accessories! It’s a bit of a hassle having to go back to previous workouts in the calendar just to see what weight I was at so far.',
      name: 'Exphyre',
      platform: 'Apple App Store',
      date: '20.12.2023',
    },
    {
      stars: 5,
      title: '10/10',
      text: 'Going to be honest this is way better than the apps on the market right now like strong or jefit. The developers knew exactly what they were doing. It has all the functions the others lacked. Only thing missing is gifs/form videos/exercise videos but really not necessary tbh. A+',
      name: '123amaval',
      platform: 'Apple App Store',
      date: '12.03.2023',
    },
    {
      stars: 5,
      // title: '',
      text: 'Tracks workouts, reps, weight. Navigates easily. Rest time stays running in the background when you\'re playing on your phone between sets - it\'s the little things. Clearly made with lots of thought and testing. Absolutely on board.',
      name: 'Dan Dan',
      platform: 'Google Play Store',
      date: '09.09.2023',
    },
    {
      stars: 5,
      title: 'So much value!',
      text: 'Love the app and its UI, feels very modern. It’s designed very well. I just wish it kept track of the weight you lift in all exercises such as accessories! It’s a bit of a hassle having to go back to previous workouts in the calendar just to see what weight I was at so far.',
      name: 'Exphyre',
      platform: 'Apple App Store',
      date: '20.12.2023',
    },
    {
      stars: 5,
      title: 'Does a lot!',
      text: 'So far this app is of great use when tracking your training sessions. The app developers are also very responsive to any questions or feedback I have. Thank you',
      name: 'resturanklez',
      platform: 'Apple App Store',
      date: '15.09.2023',
    },
    {
      // stars: 5,
      // title: '',
      text: 'First off I just want to say that this app is already the best workout tracking app I have ever used.',
      name: 'affinepplan',
      platform: 'Reddit',
      // date: '28.09.2023',
    },
    {
      stars: 5,
      // title: '',
      text: 'Hardy is an outstanding workout tracker in it\'s current state, and as the devs add more features it will only improve. I appreciate that the devs are engaging with the community on reddit.',
      name: 'Dan Dan',
      platform: 'Google Play Store',
      date: '28.08.2023',
    },
    {
      stars: 5,
      // title: '',
      text: 'Great app. Functionality is pretty much perfect!',
      name: 'Incongruity',
      platform: 'Google Play Store',
      date: '29.08.2023',
    },
    {
      // stars: 5,
      // title: '',
      text: 'Keep up the great work. First workout app that I\'ve actually used. They\'re usually garbage. Cheers. Brandon',
      name: 'Brandon',
      platform: 'email',
      // date: '28.09.2023',
    },
    {
      stars: 5,
      // title: '',
      text: 'Scope for improvement but a great app nonetheless.',
      name: 'Atul Anand',
      platform: 'Google Play Store',
      date: '28.09.2023',
    },
    {
      stars: 5,
      // title: '',
      text: 'Feedback is well recieved and worked with, all the cudos to the creators',
      name: 'Rick poelman',
      platform: 'Google Play Store',
      date: '14.01.2023',
    },
    {
      // stars: 5,
      // title: '',
      text: 'First of all I would like to commend the developers for the fact that I had a workout running for 10 hours and it was easily able to continue the workout, unlike gymshark fitness app',
      name: 'StillSleepyAS',
      platform: 'Reddit',
      // date: '14.01.2023',
    },
    {
      stars: 5,
      title: 'This app is almost perfect',
      text: 'Would be nice to be able to rate and review user made workouts',
      name: 'Tryce404',
      platform: 'Apple App Store',
      date: '26.11.2023',
    },
    {
      // title: '',
      text: 'I really do like the Hardy app, there\'s nothing quite like it. I love the UI and the minimal look and feel, I like that it doesn\'t have all the BS features like a social media center or image / video guides on how to exercise or which muscle groups are being used on the exercises.',
      name: 'Inner-Issue1908',
      platform: 'Reddit',
      date: '13.01.2023',
    },
    {
      stars: 5,
      // title: '',
      text: 'This app is exactly what I was looking for and makes tracking weights and progress way easier than a spreadsheet. I love that there is the option to choose a percentage of the RPM for weights. The only thing I\'d like to see is to be able to choose which RPMs to update after a workout as it seems that currently it\'s either update all or none. Either way, highly recommend this app!',
      name: 'Jude Fruit',
      platform: 'Google Play Store',
      date: '06.01.2024',
    },
  ];

  return (
    <>
      <Container fluid className={showMoreTestimonials ? 'testimonials testimonials--showall' : 'testimonials'}>
        <div className="testimonials-scroll">
          {testimonials.map((testimonial, i) => (
            <TestimonialBox key={i}
              stars={testimonial.stars}
              title={testimonial.title}
              text={testimonial.text}
              name={testimonial.name}
              platform={testimonial.platform}
              date={testimonial.date}
            />
          ))}
        </div>
        <Button className="button-show-testimonials" variant="outline-primary"  tabIndex={0} onClick={() => {
          setShowMoreTestimonials(true);
        }}>
          Show more
        </Button>
      </Container>
    </>
  );
}

export default TestimonialsComponent;


import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import useRoutineEditorError from '../../../lib/hooks/useRoutineEditorError';
import slice from '../../../lib/slices/routine-editor';
import { RootState } from '../../../lib/store';
import EditableText from '../../form/EditableText';
import ValidationFormControlComponent from '../../form/ValidationFormControl';
import { DraggableTypes } from '../DraggableTypes';
import { WorkoutExerciseData } from '../workout-exercise/WorkoutExercise';
import WorkoutExerciseListComponent from '../workout-exercise/WorkoutExerciseList';

export interface Workout {
  name: string;
  workoutExercises: WorkoutExerciseData[];
}

interface Props {
  workoutIndex: number;
}
interface DraggableItem {
  index: number;
}

export default function WorkoutComponent({ workoutIndex }: Props) {
  const dispatch = useDispatch();
  const workout = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex]);
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}`;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DraggableTypes.WORKOUT,
      item: { index: workoutIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [workoutIndex],
  );

  const [{ currentHoveringWorkout, hoveringWorkoutIndex }, drop] = useDrop(
    () => ({
      accept: DraggableTypes.WORKOUT,
      collect: (monitor) => {
        const isOver = monitor.isOver();
        const item: DraggableItem = monitor.getItem();

        return {
          currentHoveringWorkout: isOver && item?.index !== workoutIndex,
          hoveringWorkoutIndex: item?.index
        };
      },
      drop(item: DraggableItem) {
        const newIndex = workoutIndex;
        dispatch(slice.actions.moveWorkout({
          workoutIndex: item.index,
          newIndex
        }));
      }
    }),
    [workoutIndex],
  );

  if (!workout) {
    return null;
  }

  return (
    <div className="routine-workout" ref={(node) => drag(drop(node))}>
      { currentHoveringWorkout && workoutIndex < hoveringWorkoutIndex ? (
        <div style={{ opacity: 1, boxShadow: '0 0 0 1px #df563d', zIndex: 9, position: 'relative' }}></div>
      ) : null }

      <div className="routine-workout-header" style={{opacity: isDragging ? 0.15 : 1 }} >
        <Form.Group as={Row}>
          <Col xs={12}>
            <EditableText>
              <div>
                <Row>
                  <Col xs={9} md={10}>
                    <div className="input-fake-editable input-fake-editable--dark input-fake-editable--h3">
                      <ValidationFormControlComponent name={`${validationPrefix}_name`} errors={errors} formCtrl={{
                        type: 'text',
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(slice.actions.updateWorkout({workoutIndex, data: {name: e.target.value}})),
                        value: workout.name,
                        autoFocus: true
                      }} />
                    </div>
                  </Col>
                  <Col xs={3} md={2} className="text-right">
                    <Button className="btn-icon btn-trash" onClick={() => dispatch(slice.actions.removeWorkout({ workoutIndex }))} title="Remove Workout"><Trash /></Button>
                  </Col>
                </Row>
              </div>
              <div className="editable-text d-block">
                <Row>
                  <Col xs={8} md={10}>
                    <h3>
                      {workout.name}
                    </h3>
                  </Col>
                  <Col xs={4} md={2} className="text-right">
                    <div className="d-inline-block mt-1 btn-icon edit-pencil"><Trash /></div>
                    <div className="d-inline-block mt-1 btn-icon edit-pencil"><Pencil /></div>
                  </Col>
                </Row>
              </div>
            </EditableText>
          </Col>
        </Form.Group>
      </div>
      <div className="routine-workout-body">
        <WorkoutExerciseListComponent workoutIndex={workoutIndex} />
      </div>

      { currentHoveringWorkout && workoutIndex > hoveringWorkoutIndex ? (
        <div style={{ opacity: 1, boxShadow: '0 0 0 1px #df563d', zIndex: 9, position: 'relative' }}></div>
      ) : null }
    </div>
  );
}

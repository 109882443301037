import {
  RouteAuthCompleteSignupInput,
  RouteAuthCompleteSignupOutput,
  RouteAuthPostLoginBody,
  RouteAuthPostLoginFBBody,
  RouteAuthPostLoginFBOutput,
  RouteAuthPostLoginOutput,
  RouteAuthPostRegisterBody,
  RouteAuthPostRegisterFBBody,
  RouteAuthRecoverSetPassword,
  RouteAuthSendRecovery,
  RouteAuthUpdateBody,
  RouteAuthUpdateOutput
} from '../../../common/types/index';
import { apiAction, ApiActionOptions, apiRequest, Methods } from '../middleware/api';

export function loginAction(
  { body }: { body: RouteAuthPostLoginBody },
  { onSuccess, onFailure }: ApiActionOptions<RouteAuthPostLoginOutput>
) {
  const path = '/auth/login';
  return apiAction<RouteAuthPostLoginOutput>({
    method: Methods.POST,
    path,
    body,
    onSuccess,
    onFailure,
  });
}

export function loginFbAction(
  { body }: { body: RouteAuthPostLoginFBBody },
  { onSuccess, onFailure }: ApiActionOptions<RouteAuthPostLoginFBOutput>
) {
  const path = '/auth/login-facebook';
  return apiAction<RouteAuthPostLoginFBOutput>({
    method: Methods.POST,
    path,
    body,
    onSuccess,
    onFailure,
  });
}

export function completeSignupAction(
  { body }: { body: RouteAuthCompleteSignupInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteAuthCompleteSignupOutput>
) {
  const path = '/auth/complete-signup';
  return apiAction<RouteAuthCompleteSignupOutput>({
    method: Methods.POST,
    path,
    body,
    onSuccess,
    onFailure,
  });
}

export function loginRequest(body: RouteAuthPostLoginBody) {
  return apiRequest<RouteAuthPostLoginOutput>({
    path: '/auth/login',
    method: Methods.POST,
    body
  });
}

export function loginFbRequest(body: RouteAuthPostLoginFBBody) {
  return apiRequest<RouteAuthPostLoginFBOutput>({
    path: '/auth/login-facebook',
    method: Methods.POST,
    body
  });
}

export function updateUserAction(
  { body }: { body: RouteAuthUpdateBody },
  { onSuccess, onFailure }: ApiActionOptions<RouteAuthUpdateOutput>
) {
  const path = '/auth/update';
  return apiAction<RouteAuthUpdateOutput>({
    method: Methods.POST,
    path,
    body,
    onSuccess,
    onFailure,
  });
}

export function logoutAction(
  { onSuccess, onFailure }: ApiActionOptions<void>
) {
  const path = '/auth/logout';
  return apiAction<void>({
    method: Methods.GET,
    path,
    onSuccess,
    onFailure,
  });
}

export function sendRecoveryRequest(body: RouteAuthSendRecovery) {
  return apiRequest<void>({
    path: '/auth/send-recovery',
    method: Methods.POST,
    body
  });
}

export function deleteAccountRequest(body: {}) {
  return apiRequest<void>({
    path: '/auth/delete-my-account',
    method: Methods.POST,
    body
  });
}

export function registerEmailRequest(body: RouteAuthPostRegisterBody) {
  return apiRequest<RouteAuthPostLoginOutput>({
    path: '/auth/register-email',
    method: Methods.POST,
    body
  });
}

export function completeSignupRequest(body: RouteAuthCompleteSignupInput) {
  return apiRequest<RouteAuthCompleteSignupOutput>({
    path: '/auth/complete-signup',
    method: Methods.POST,
    body
  });
}

export function registerFBRequest(body: RouteAuthPostRegisterFBBody) {
  return apiRequest<RouteAuthPostLoginFBOutput>({
    path: '/auth/register-facebook',
    method: Methods.POST,
    body
  });
}

export function recoverySetPasswordRequest(body: RouteAuthRecoverSetPassword) {
  return apiRequest<void>({
    path: '/auth/recover-set-password',
    method: Methods.POST,
    body
  });
}

export default { loginAction, loginFbAction, updateUserAction, completeSignupAction, logoutAction, deleteAccountRequest };

import ResponseCodes from '../../../common/constants/ResponseCodes';
import { apiUrl } from '../config';
import { ListQuery } from '../../../common/types/api';
import moment from 'moment-timezone';
import { UserData, UserUnit } from '../../../common/types/model/user';
import authSlice from '../slices/auth';

const timezone = moment.tz.guess();
const guessedUnit = !timezone.match('America') ? UserUnit.METRIC : UserUnit.IMPERIAL;

export enum Methods {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export interface GeneralErrorData {
    message: string;
}

export interface ValidationErrorData {
    [key: string]: string;
}

export type ApiError = GeneralErrorData | ValidationErrorData;

interface Meta {
  setUser?: {
    token: string;
    data: UserData;
  }
}
interface ApiSuccess<D> {
    data: D;
    code: ResponseCodes.OK;
    meta?: Meta;
    error: null;
}

interface ApiFail {
    data: null;
    code: Omit<ResponseCodes, ResponseCodes.OK>;
    meta?: Meta;
    error: ApiError;
}

export type ApiResponse<D> = ApiSuccess<D> | ApiFail;

export interface ApiActionOptions<T> {
  onSuccess: (responseData: T) => void;
  onFailure: (responseError: ApiError) => void;
}

interface ApiActionResponse<T> {
  type: string;
  payload: {
    path: string;
    method: Methods;
    body?: string;
    onSuccess: (responseData: T) => void;
    onFailure: (responseError: ApiError) => void;
  };
}

export function apiAction<T>(data: {
  path: string;
  method: Methods;
  body?: object;
  onSuccess: (responseData: T) => void;
  onFailure: (responseError: ApiError) => void;
}): ApiActionResponse<T> {
  const {
    path = '',
    method = Methods.GET,
    body = undefined,
    onSuccess = () => {},
    onFailure = () => {},
  } = data;
  return {
    type: 'API',
    payload: {
      path,
      method,
      body: body ? JSON.stringify(body) : undefined,
      onSuccess,
      onFailure,
    },
  };
}

export async function apiRequest<T>({ path, method, body }: {path: string; method: Methods, body?: object}): Promise<{error: any; response?: T | null; meta?: Meta}> {
  const url = `${apiUrl}${path}`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-hardy-guess-unit': guessedUnit
  };

  try {
    const response = await fetch(url, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
      credentials: 'include'
    });

    const result: ApiResponse<T> = await response.json();

    const { data, code, error, meta } = result;

    if (error) {
      return { error, response: undefined };
    }

    if (code !== 200) {
      return { error: { message: 'Error occurred' }};
    }

    return { error: undefined, response: data, meta };
  } catch (e) {
    return { error: e };
  }
}

export function buildListQuery(data: {
  path: string;
  query: Partial<ListQuery>;
}): string {
  const {
    filter = null,
    offset = null,
    limit = null,
    search = null,
    order = null
  } = data.query;
  const frags = [];
  if (filter) {
    if (typeof filter === 'object') {
      frags.push(`filter=${JSON.stringify(filter)}`);
    } else {
      frags.push(`filter=${filter}`);
    }
    
  }
  if (offset) {
    frags.push(`offset=${offset}`);
  }
  if (limit) {
    frags.push(`limit=${limit}`);
  }
  if (search) {
    frags.push(`search=${search}`);
  }
  if (order) {
    frags.push(`order=${order[0]} ${order[1]}`);
  }
  return `${data.path}${frags.join('&')}`;
}

//@ts-ignore
const apiMiddleware = ({ dispatch }) => (next) => (
  //@ts-ignore
  action
) => {
  next(action);

  if (action.type !== 'API') {
    return;
  }

  const { path, method, body, onSuccess, onFailure } = action.payload;

  const url = `${apiUrl}${path}`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-hardy-guess-unit': guessedUnit
  };

  fetch(url, {
    method,
    headers,
    body,
    credentials: 'include'
  })
    .then((r) => r.json())
    .then((responseObject: ApiResponse<any>) => {
      const { data, code, error, meta } = responseObject;

      if (meta?.setUser) {
        dispatch(authSlice.actions.setUser(meta.setUser));
      }

      if (error) {
        return dispatch(() => onFailure(error));
      }

      if (code !== 200) {
        return dispatch(() =>
          onFailure({
            message: 'Error occurred',
          }),
        );
      }

      dispatch(() => onSuccess(data));
    })
    .catch((errorObject) => {
      dispatch(() => onFailure(errorObject));
    });
};

export default apiMiddleware;

import React from 'react';
import './App.scss';
import './style/Form.scss';
import Root from './components/Root';
// import useExercises from './lib/hooks/useExercise';

export default function App () {
  // const isReady = useExercises();
  // if (!isReady) {
  //   return null;
  // }

  return (<Root></Root>);
}

import React, { useState } from 'react';
import Select from 'react-select';
import { UserUnit } from '../../../common/types/index';

interface Option {
  value: UserUnit;
  label: string;
}

export interface UnitSelectProps {
  onSelected: (v: UserUnit) => void;
  defaultValue: UserUnit;
}

const allOptions: Option[] = [{
  value: UserUnit.METRIC,
  label: 'Metric (kg)'
}, {
  value: UserUnit.IMPERIAL,
  label: 'Imperial (lbs)'
}];

function UnitSelect ({ onSelected, defaultValue }: UnitSelectProps) {
  const [selected, setSelected] = useState(allOptions.find((o) => o.value === defaultValue));

  return (
    <Select
      className="unit-select"
      classNamePrefix="unit-select"
      options={allOptions}
      onChange={(s: any) => {
        setSelected(s);
        onSelected(s.value);
      }}
      value={selected}
    />
  );
}

export default UnitSelect;

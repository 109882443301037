import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UserUnit } from '../../../common/types/index';
import { createValidator } from '../../lib/core';
import { UserUpdateInputSchema } from '../../../common/validators/user';
import UpdateUserComponent from '../user/UpdateUserComponent';
import { RootState } from '../../lib/store';
import updateUserSlice, { updateUser } from '../../lib/slices/update-user';
import { Link } from 'react-router-dom';

const updateUserValidator = createValidator(UserUpdateInputSchema);

function UpdateUserPage () {

  const dispatch = useDispatch();
  const updateUserRequest = useSelector((state: RootState) => state.updateUser);
  const user = useSelector((state: RootState) => state.authState.data);

  const [ updateData, setUpdateData ] = useState({
    units: user?.units || UserUnit.METRIC,
    username: user?.username || 'N/A'
  });

  const [errors, setErrors] = useState<any>(undefined);

  useEffect(() => {
    setErrors(updateUserRequest.error);
  }, [updateUserRequest.error]);

  useEffect(() => {
    return () => {
      dispatch(updateUserSlice.actions.resetUpdateUser());
    };
  }, []);

  useEffect(() => {
    async function validate() {
      const {valid, error} = await updateUserValidator({
        units: updateData.units
      });

      setErrors(valid ? undefined : error);
    }

    validate();
  }, [updateData.units]);
  function onUpdateSubmit(e: any) {
    e.preventDefault();

    dispatch(updateUser({
      units: updateData.units
    }));
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col lg={{ span: 4, offset: 4 }}>
          <h2 className="text-center mt-5 caps-condensed">Settings</h2>

          <div className="content-area mb-4">
            {user && user.username ? (
              <Form.Group className="d-flex justify-content-between">
                <Form.Label>Username</Form.Label>
                <div>{user.username}</div>
              </Form.Group>
            ) : null }
            {user && user.email ? (
              <Form.Group className="d-flex justify-content-between">
                <Form.Label>Email</Form.Label>
                <div>{user.email}</div>
              </Form.Group>
            ) : null }
            <Form onSubmit={onUpdateSubmit}>
              { updateUserRequest.data ? (
                <Row className={'text-muted'}>
                  <Col xs={12}>
                    User information saved
                  </Col>
                </Row>
              ): null }
              <UpdateUserComponent
                updateData={updateData}
                errors={errors}
                onChange={setUpdateData}
              />

              <Button size="lg" variant="primary" type="submit" block disabled={!!errors}>
                Save
              </Button>
              {errors && errors?.message ? (
                <Row>
                  <Col xs={12} className={'validation-error-message'}>
                    { errors?.message }
                  </Col>
                </Row>
              ) : null}
            </Form>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 4 }} style={{ textAlign: 'right'}}>
          <Link className='dotted-underline' to={{ pathname: '/delete-account' }} >delete account</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default UpdateUserPage;

import { useEffect } from 'react';

export default function useClickOutsideHandler(ref: any, handler: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (event.target.closest('.ignore-outside-click-handler')) {
          return;
        }

        handler();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

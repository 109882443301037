import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { AdminUserStatsResponse, ListQuery, ListResponse } from '../../../../common/types';
import useGetAsyncPath from '../../../lib/hooks/useGetAsyncPath';
import moment from 'moment-timezone';
import HardyPagination from '../../form/HardyPagination';
import useGetLocationQueryParam from '../../../lib/hooks/useGetLocationQueryParam';
import { useHistory } from 'react-router';
import './Stats.scss';

const LIMIT = 10;

function AdminStatRow ({userStat}: {userStat: AdminUserStatsResponse;}) {
  return (
    <tr>
      <td>{userStat.id}</td>
      <td>{userStat.email}</td>
      <td>{userStat.username}</td>

      <td>{userStat.workouts_completed}</td>
      <td>{userStat.routines_subscribed_to}</td>
      <td>{userStat.created_private_routines}</td>
      <td>{userStat.created_public_routines}</td>
      
      <td>{moment(userStat.created_at).format('MM-DD-yyyy')}</td>
    </tr>
  );
}

function AdminStatsPage () {
  const history = useHistory();
  const pageString = useGetLocationQueryParam('page', '1');
  const page = +pageString;
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc');
  const [orderColumn, setOrderColumn] = useState('id');

  const [query, setQuery] = useState<Partial<ListQuery>>({
    limit: LIMIT,
    offset: 0,
    order: [orderColumn, orderDirection],
    filter: { onlyUninvited: false }
  });

  const { value, loading, error } = useGetAsyncPath<ListResponse<AdminUserStatsResponse>>('/admin/user-stats?', query);

  useEffect(() => {
    const newOffset = (page - 1) * LIMIT;

    if (newOffset !== query.offset) {
      setQuery({
        ...query,
        offset: newOffset
      });
    }
  }, [page]);

  useEffect(() => {
    if (orderColumn !== query.order?.[0] || orderDirection !== query.order?.[1]) {
      setQuery({
        ...query,
        order: [orderColumn, orderDirection]
      });
    }
  }, [orderColumn, orderDirection]);

  function toggleOrder(col: string) {
    if (orderColumn === col) {
      if (orderDirection === 'desc') {
        setOrderDirection('asc');
      } else {
        setOrderColumn('id');
        setOrderDirection('desc');
      }
    } else {
      setOrderColumn(col);
      setOrderDirection('desc');
    }
  }
  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  if (loading || !value) {
    return <div>loading</div>;
  }

  return (
    <>
      <Container fluid className="hiw-content">
        <Container className="">
          <Row>
            <Col>
              <h1>User Stats</h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th onClick={() => toggleOrder('id')} className={'text-nowrap'}>
                  id {orderColumn === 'id' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
                <th onClick={() => toggleOrder('email')}>
                  email {orderColumn === 'email' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
                <th onClick={() => toggleOrder('username')}>
                  username {orderColumn === 'username' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>

                <th onClick={() => toggleOrder('workouts_completed')}>
                  workouts completed {orderColumn === 'workouts_completed' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
                <th onClick={() => toggleOrder('routines_subscribed_to')}>
                  routines subscribed to {orderColumn === 'routines_subscribed_to' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
                <th onClick={() => toggleOrder('created_private_routines')}>
                  created private routines {orderColumn === 'created_private_routines' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
                <th onClick={() => toggleOrder('created_public_routines')}>
                  created public routines {orderColumn === 'created_public_routines' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
                  
                <th onClick={() => toggleOrder('created_at')}>
                  created at {orderColumn === 'created_at' ? orderDirection === 'asc' ? <>&#8593;</> : <>&darr;</> : null}
                </th>
              </tr>

              {value.items.map(v => <AdminStatRow key={v.id} userStat={v} />)}
            </thead>
          </Table>
        </Container>
      
        <HardyPagination count={value.count} pageNumber={page} goToPage={(p) => history.push(`/admin?page=${p}`)} limit={LIMIT} />
      </Container>
    </>
  );
}

export default AdminStatsPage;

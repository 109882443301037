import React, { Fragment, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components, ValueType } from 'react-select';
import { ExerciseData, ExerciseType } from '../../../common/types';
import useFindExercise from '../../lib/hooks/useFindExercise';
import { createCustomRequest } from '../../lib/services/exercise.service';
import { cacheAllExercises } from '../../lib/slices/exercise-cache';
import { RootState } from '../../lib/store';
import HardyDropdownComponent from '../form/HardyDropdown';
import HardyInputComponent from '../form/HardyInput';

interface ExerciseValue {
  value: number;
  label: string;
}
export interface ExerciseSearchProps {
  onSelected: (value: ExerciseData) => void;
  defaultValue?: ExerciseValue;
  isError: boolean;
  autoFocus: boolean;
  errors?: { validation?: { [key: string]: string; } }
  name: string;
}

const menuHeaderStyle = {
  padding: '0.5rem 0.5rem',
};

const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.selectProps.isLoggedIn ? (
        <Row className="btn-add-customexercise">
          <Col className="text-left">
            <Button style={menuHeaderStyle} className="btn-add-sm" onClick={() => props.selectProps.onModalShow()}><span className="add-plus"><Plus /></span> Create my own exercise…</Button>
          </Col>
        </Row>
      ) : null}

    </components.MenuList>
  );
};

const Option = (props: any) => {
  return (
    <Fragment>
      <components.Option {...props}>
        <div className={'icon-exercise icon-exercise--small icon-exercise--' + props.data.exerciseType}></div>
        {props.label}
        {props.data.userId ? (
          <span className="text-muted ml-1">(Custom)</span>
        ) : null}
        <div className="text-muted float-right">{props.data.exerciseType}</div>
      </components.Option>
    </Fragment>
  );
};

function ExerciseSearchComponent({ onSelected, defaultValue, isError, autoFocus, errors, name }: ExerciseSearchProps) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authState.data);
  const [searchTerm, setSearchTerm] = useState('');
  const options = useFindExercise(searchTerm);
  const exerciseCacheMap = useSelector((state: RootState) => state.exerciseCacheState.cacheMap);
  const [selection, setSelection] = useState<ValueType<any, false>>(defaultValue || {
    value: undefined,
    label: 'none'
  });
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [newExerciseName, setNewExerciseName] = useState<string | undefined>(undefined);
  const [newExerciseType, setNewExeciseType] = useState<{
    value: ExerciseType;
    label: string;
  }>({
    label: 'Barbell',
    value: ExerciseType.BARBELL
  });

  const exerciseTypeOptions = [{
    label: 'Barbell',
    value: ExerciseType.BARBELL
  }, {
    label: 'Dumbbell',
    value: ExerciseType.DUMBBELL
  }, {
    label: 'Kettlebell',
    value: ExerciseType.KETTLEBELL
  }, {
    label: 'Machine',
    value: ExerciseType.MACHINE
  }, {
    label: 'Plate',
    value: ExerciseType.PLATE
  }, {
    label: 'Bodyweight (time)',
    value: ExerciseType.BODYWEIGHT_TIME
  }, {
    label: 'Bodyweight (reps)',
    value: ExerciseType.BODYWEIGHT_REP
  }];

  async function createExercise() {
    try {
      const { response } = await createCustomRequest({
        name: newExerciseName as string,
        type: newExerciseType.value
      });

      if (response?.id && response?.name) {
        const r = {
          label: response.name,
          value: response.id
        };
        setSelection(r);
        onSelected(response);
        dispatch(cacheAllExercises());
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (<>
    <Modal
      show={showCreateModal}
      onHide={() => setShowCreateModal(false)}
      backdrop="static"
      keyboard={false}
      className="ignore-outside-click-handler"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a New Exercise</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Group>
            <Form.Label>Exercise Name</Form.Label>
            <HardyInputComponent
              name={'name'}
              errors={undefined}
              hideError={true}
              formCtrl={{
                type: 'text',
                onChange: (v: any) => setNewExerciseName(v.target.value),
                value: newExerciseName,
                autoFocus: true,
                placeholder: 'e.g. Romanian Deadlift',
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Select className='unit-select' classNamePrefix="unit-select" value={newExerciseType}
              options={exerciseTypeOptions}
              autoFocus={false}
              onChange={(v: ValueType<any, false>) => {
                setNewExeciseType(v);
              }}
            />
          </Form.Group>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShowCreateModal(false)} style={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => createExercise()}>Create</Button>
      </Modal.Footer>
    </Modal>
    <HardyDropdownComponent
      name={name}
      errors={errors}

      formCtrl={{
        className: isError ? 'unit-select validation-error-select' : 'unit-select',
        classNamePrefix: 'unit-select',
        value: selection,
        defaultValue,
        onModalShow: () => {
          setNewExerciseName(searchTerm);
          setShowCreateModal(true);
        },
        isLoggedIn: !!user?.id,
        components: { MenuList, Option },
        options: options.map(o => ({
          value: o.id,
          label: o.name,
          exerciseType: o.type,
          userId: o.user_id,
        })),
        onInputChange: (input: any) => setSearchTerm(input),
        onChange: (v: ValueType<any, false>) => {
          setSelection(v);
          onSelected(exerciseCacheMap[v.value]);
        },
        autoFocus
      }}
    />
  </>);

}

export default ExerciseSearchComponent;

import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { UserUnit } from '../../../common/types';
import usePublicPrivateExercise from '../../lib/hooks/usePublicPrivateExercise';
import WeightPicker from '../weight/WeightPicker';

interface Props {
  exerciseIds?: number[];
  exercise1Rms?: { [key: string]: number },
  setExercise1Rms: (_1rms: { [key: string]: number }) => void;
  unit: UserUnit;
}

function Exercise1RmEditComponent(props: { exercise_id: number; units: UserUnit; defaultValue: number; onChange: (grams: number) => void }) {
  const exercise = usePublicPrivateExercise(props.exercise_id);
  if (!exercise) {
    return null;
  }

  return (
    <Form.Group as={Row} className="mb-0">
      <Form.Label column xs={8} sm={8} md={8} lg={7} xl={8} className="your-stats-label">
        <div className={'icon-exercise icon-exercise--small icon-exercise--bordered icon-exercise--' + exercise.type}></div>
        {`${exercise.name}`}
      </Form.Label>
      <Col xs={4} sm={4} md={4} lg={5} xl={4}>
        <WeightPicker units={props.units} defaultValue={props.defaultValue} onChange={props.onChange} type={exercise.type} />
      </Col>
    </Form.Group>
  );
}

export default function _1RMList({ exerciseIds, setExercise1Rms, exercise1Rms, unit }: Props) {
  if (!exercise1Rms || !exerciseIds || !exerciseIds.length) {
    return null;
  }
  return (<Col xs={{ span: 12, order: 2 }} md={12} lg={4} className="your-stats-holder">
    <Card border="light" className="bg-light mb-5 your-stats">
      <Card.Body>
        <Row>
          <Col>
            <h3 className="caps-condensed mb-3">Your 1 Rep Max-s</h3>
          </Col>
        </Row>
        {
          exerciseIds.map((exId) => (
            <Exercise1RmEditComponent
              key={exId}
              exercise_id={exId}
              units={unit}
              defaultValue={exercise1Rms[exId]}
              onChange={(grams: number) => {
                setExercise1Rms({
                  ...exercise1Rms,
                  [exId]: grams
                });
              }}
            />))
        }
      </Card.Body>
    </Card>
  </Col>);
}
import React from 'react';
import { Container } from 'react-bootstrap';
// import UserBetaForm from '../register/UserBetaForm';

function DevTestComponent() {
  return (
    <Container>
      {/* <UserBetaForm /> */}
    </Container>
  );
}

export default DevTestComponent;
import React, { useContext, createContext, ReactNode } from 'react';
import { WorkoutModel } from '../../../common/types';

export const PreviewWorkoutContext = createContext<WorkoutModel | undefined>(undefined);

export function usePreviewWorkout () {
  return useContext(PreviewWorkoutContext);
}

export function PreviewWorkoutContextProvider ({ children, workout }: { children: ReactNode, workout: WorkoutModel }) {
  return (
    <PreviewWorkoutContext.Provider value={ workout }>
      {children}
    </PreviewWorkoutContext.Provider>
  );
}
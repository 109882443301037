import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExerciseData } from '../../../common/types';
import { cacheAllExercises } from '../slices/exercise-cache';
import { RootState } from '../store';

export default function useFindExercise(search: string) {
  const dispatch = useDispatch();
  const exercises = useSelector((state: RootState) => state.exerciseCacheState);
  const [result, setResult] = useState<ExerciseData[]>([]);

  useEffect(() => {
    if (!exercises.count && !exercises.loading && !exercises.error) {
      dispatch(cacheAllExercises());
    }

    if (exercises.count) {
      const searchTerms = search.split(' ').map(s => s.trim().toLocaleLowerCase());
      setResult(exercises.list.filter(e => {
        return searchTerms.some(term => {
          return e.name.match(term) || e.alternative_names.match(term) || e.type.match(term);
        });
      }));
    }
  }, [exercises]);

  return result;
}

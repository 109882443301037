import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { usePreviewWorkout } from '../context/PreviewWorkoutContext';
import { PreviewWorkoutExerciseContextProvider } from '../context/PreviewWorkoutExerciseContext';
import WorkoutExerciseSwitch from './workout-exercise/WorkoutExerciseSwitch';

export default function Workout() {
  const wo = usePreviewWorkout();

  if (!wo) {
    return null;
  }

  return (
    <Row>
      <Col md={12}>
        <div className="routine-workout">
          <div className="routine-workout-header">
            <Row>
              <Col><h3>{wo.name}</h3></Col>
            </Row>
          </div>
          <div className="routine-workout-body">
            {wo.workoutExercises.map((woe, index) => (
              <PreviewWorkoutExerciseContextProvider workoutExercise={woe} key={index}>
                <WorkoutExerciseSwitch />
              </PreviewWorkoutExerciseContextProvider>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
}
import React from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap';

type Props = {
  startDeleteRoutine: () => void;
  handleDeleteModalClose: () => void;
  showDeleteModal: boolean;
};

export default function DuplicateRoutineModal({ startDeleteRoutine, handleDeleteModalClose, showDeleteModal }: Props) {
  return (
    <Modal className="duplicateroutine-modal" show={showDeleteModal} onHide={handleDeleteModalClose} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title>Delete routine?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pl-3 pr-3 pt-2 pb-1">
          <p>
            You are about to delete this routine. Are you sure?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleDeleteModalClose} style={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <Button variant="primary" onClick={startDeleteRoutine}>
          Yes, delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Row, Button, Dropdown } from 'react-bootstrap';
import WeightPicker from '../../weight/WeightPicker';
import { useDispatch, useSelector } from 'react-redux';
import { Trash, Pencil, GraphUp } from 'react-bootstrap-icons';
import ValidationFormControl from '../../form/ValidationFormControl';
import EditableText from '../../form/EditableText';
import { formatWeight } from '../../weight/Weight';
import { RootState } from '../../../lib/store';
import slice from '../../../lib/slices/routine-editor';
import useRoutineEditorError from '../../../lib/hooks/useRoutineEditorError';
import usePublicPrivateExercise from '../../../lib/hooks/usePublicPrivateExercise';
import useUnits from '../../../lib/hooks/useUnits';
import { OverloadType, WorkoutExerciseOverloadRule, WorkoutExerciseOverloadRule1RM, WorkoutExerciseOverloadRuleTM } from '../../../../common/types';

interface Props {
  workoutIndex: number;
  exerciseIndex: number;
}

function SelectOverloadType({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].overload_rule);

  const selectedTmData = {
    overload_type: OverloadType.TM,
    tm_overload_amount: 2500,
    overload_amount_1rm: undefined
  };

  const selected1RMData = {
    overload_type: OverloadType._1RM,
    overload_amount_1rm: 2500,
    tm_overload_amount: undefined
  };

  const selectNewType = (type: OverloadType) => {
    if (rule && type === rule?.overload_type) {
      return;
    }

    const newValues = type === OverloadType.TM ? selectedTmData: selected1RMData;
    const overloadRule = { ...rule, ...newValues } as WorkoutExerciseOverloadRule;

    dispatch(slice.actions.updateExercise({ workoutIndex, exerciseIndex, data: { overload_rule: overloadRule } }));
  };

  return (
    <Dropdown className="inline-dropdown">
      <Dropdown.Toggle variant="outline-secondary">
        {rule?.overload_type}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => selectNewType(OverloadType._1RM)} active={rule?.overload_type === OverloadType._1RM ? true : false }>
          1RM
        </Dropdown.Item>
        <Dropdown.Item onClick={() => selectNewType(OverloadType.TM)} active={rule?.overload_type === OverloadType.TM ? true : false }>
          TM
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DeleteOverloadRuleButton({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  return (
    <Button className="btn-icon btn-delete text-muted" variant="outline-secondary" onClick={() => dispatch(slice.actions.updateExercise({ workoutIndex, exerciseIndex, data: { overload_rule: undefined } }))} >
      <Trash />
    </Button>
  );
}

function OverloadText({ workoutIndex, exerciseIndex }: Props) {
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].overload_rule);
  const units = useUnits();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const exercise = usePublicPrivateExercise(workoutExercise.exercise_id || 1);
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);
  if (!rule || (rule.overload_type === OverloadType.TM && !targetExercise) || !exercise) {
    return null;
  }

  const sessions = rule.successful_sessions_trigger_number == 1 ? 'session' : 'sessions';
  const increaseValue = rule.overload_type === OverloadType.TM
    ? formatWeight(rule.tm_overload_amount, targetExercise ? targetExercise.type : exercise.type, { units })
    : formatWeight(rule.overload_amount_1rm, exercise.type, { units });
  const type = rule.overload_type === OverloadType.TM ? 'TM' : '1RM';

  return (
    <Row className="routine-woe-sets-row align-items-center" noGutters>
      <Col xs={11}>
        <p className="mb-0"><div className="icon-overload"><GraphUp /></div> <b>Overload:</b> After <b>{rule.successful_sessions_trigger_number}</b> successful {sessions}, <b>{type}</b> is suggested to increase by <b>{increaseValue}</b></p>
      </Col>
      <Col xs={1} className="text-right">
        <Button className="btn-icon" variant="outline-secondary">
          <Pencil />
        </Button>
      </Col>
    </Row>
  );
}

function OverloadWeightPicker ({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].overload_rule);
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const units = useUnits();
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);
  const exercise = usePublicPrivateExercise(workoutExercise.exercise_id || 1);

  const updateWeightChangeAmountTm = (grams: number) => {
    dispatch(slice.actions.updateExercise({
      workoutIndex, exerciseIndex, data: {
        overload_rule: { ...rule, tm_overload_amount: grams } as WorkoutExerciseOverloadRuleTM
      }
    }));
  };

  const updateWeightChangeAmount1RM = (grams: number) => {
    dispatch(slice.actions.updateExercise({
      workoutIndex, exerciseIndex, data: {
        overload_rule: { ...rule, overload_amount_1rm: grams } as WorkoutExerciseOverloadRule1RM
      }
    }));
  };

  if (rule?.overload_type === OverloadType._1RM && exercise) {
    return (<span>
      <WeightPicker units={units} defaultValue={rule.overload_amount_1rm} type={exercise.type} onChange={updateWeightChangeAmount1RM} />
    </span>);    
  }

  if (rule?.overload_type === OverloadType.TM && targetExercise) {
    return (<span>
      <WeightPicker units={units} defaultValue={rule.tm_overload_amount} type={targetExercise.type} onChange={updateWeightChangeAmountTm} />
    </span>);
  }


  return null;
}

export default function OverloadRule({ workoutIndex, exerciseIndex }: Props) {
  const dispatch = useDispatch();
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].overload_rule);
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}`;

  const errors = useRoutineEditorError();
  const [successfulSessionsTouched, setSuccessfulSessionsTouched] = useState(false);

  useEffect(() => {
    if (rule?.successful_sessions_trigger_number) {
      setSuccessfulSessionsTouched(true);
    }
  }, [rule]);

  if (!rule) {
    return null;
  }

  const changeSuccessfulSessionsTriggerNumber = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(slice.actions.updateExercise({
      workoutIndex, exerciseIndex, data: {
        overload_rule: {
          ...rule,
          successful_sessions_trigger_number: e.target.value !== '' ? Number(e.target.value) : 0
        }
      }
    }));
  };

  const formCtrl = {
    onChange: changeSuccessfulSessionsTriggerNumber,
    defaultValue: rule.successful_sessions_trigger_number,
    type: 'number',
    placeholder: '0',
    autoFocus: true
  };

  return (
    <div className="mt-0">
      <Form.Group className="routine-woe-sets mb-0">
        <EditableText>
          <div className="routine-woe-sets-row-holder">
            <Row className="routine-woe-sets-row" noGutters>
              <Col xs={11}>
                <div className="inputs-inline">
                  <div className="icon-overload"><GraphUp /></div> <b>Overload:</b> After
                  <ValidationFormControl
                    name={`${validationPrefix}_overload_rule_successful_sessions_trigger_number`}
                    formCtrl={formCtrl}
                    errors={errors}
                    hideError={!successfulSessionsTouched}
                  />
                  successful sessions, overload <SelectOverloadType workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} /> by
                  <OverloadWeightPicker workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
                </div>
              </Col>
              <Col xs={1} className="text-right">
                <DeleteOverloadRuleButton workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
              </Col>
            </Row>
          </div>
          <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--clickable">
            <OverloadText workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} />
          </div>
        </EditableText>
      </Form.Group>
    </div>
  );
}

import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteAccountRequest } from '../../lib/services/auth.service';
import { logout } from '../../lib/slices/auth';

function DeleteUserPage () {
  const dispatch = useDispatch();
  const history = useHistory();

  async function deleteAccountAndLogout() {
    await deleteAccountRequest({});
    dispatch(logout());
    history.push('/');
  }

  return (
    <Container className="pt-4">
      <Row>
        <Col lg={{ span: 4, offset: 4 }}>
          <h2 className="text-center mt-5 caps-condensed">Delete Account</h2>
        </Col>
        <Col lg={{ span: 4, offset: 4 }}>
          <div>
            This action is irreversible. You will not be able to log in again!
          </div>
        </Col>
      </Row>
      <Row style={{marginTop: '40px'}}>
        <Col lg={{ span: 4, offset: 4 }}>
          <Button size="lg" variant="primary" type="submit" block onClick={() => deleteAccountAndLogout()}>
            Yes, I want to delete my account
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default DeleteUserPage;

import {
  ListQuery,
  ListResponse, RouteRoutinePostBody,
  RouteRoutinePutStatusBody,
  RouteRoutinePutStatusOutput,
  RoutineFullData,
  RoutineFullUpdateData,
  RoutineModel, RoutineResponse
} from '../../../common/types/index';
import { apiAction, ApiActionOptions, apiRequest, buildListQuery, Methods } from '../middleware/api';

export function listAction(
  {query}: {query: Partial<ListQuery>},
  { onSuccess, onFailure }: ApiActionOptions<ListResponse<RoutineResponse>>
) {
  const path = '/routine?';
  return apiAction<ListResponse<RoutineResponse>>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function listSubscribedAction(
  {query}: {query: Partial<ListQuery>},
  { onSuccess, onFailure }: ApiActionOptions<ListResponse<RoutineResponse>>
) {
  const path = '/routine/subscribed?';
  return apiAction<ListResponse<RoutineResponse>>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function listOwnAction(
  {query}: {query: Partial<ListQuery>},
  { onSuccess, onFailure }: ApiActionOptions<ListResponse<RoutineResponse>>
) {
  const path = '/routine/own?';
  return apiAction<ListResponse<RoutineResponse>>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function createAction(
  { body }: {body: RouteRoutinePostBody},
  { onSuccess, onFailure }: ApiActionOptions<RoutineModel>
) {
  const path = '/routine';
  return apiAction<RoutineModel>({
    method: Methods.POST,
    path,
    body,
    onSuccess,
    onFailure,
  });
}

export function getFullAction(
  { id }: { id: string },
  { onSuccess, onFailure }: ApiActionOptions<RoutineFullData>
) {
  const path = `/routine/${id}`;
  return apiAction<RoutineFullData>({
    method: Methods.GET,
    path,
    onSuccess,
    onFailure,
  });
}

export function subscribeRequest({ id }: { id: number }) {
  return apiRequest<void>({
    path: '/routine/subscribe',
    method: Methods.POST,
    body: { id }
  });
}

export function unsubscribeRequest({ id }: { id: number }) {
  return apiRequest<void>({
    path: '/routine/unsubscribe',
    method: Methods.POST,
    body: { id }
  });
}

export function copyRoutineRequest({ id }: {id: number }) {
  return apiRequest<RoutineFullData>({
    path: `/routine/${id}/customize`,
    method: Methods.GET,
  });
}

export function updateFullAction(
  { id, body } : { id: string; body: RoutineFullUpdateData },
  { onSuccess, onFailure }: ApiActionOptions<RoutineFullData>
) {
  const path = `/routine/${id}/update-full`;
  return apiAction<RoutineFullData>({
    method: Methods.PUT,
    body,
    path,
    onSuccess,
    onFailure,
  });
}

export function updateStatusAction(
  { id, body }: { id: string; body: RouteRoutinePutStatusBody },
  { onSuccess, onFailure }: ApiActionOptions<RouteRoutinePutStatusOutput>
) {
  const path = `/routine/${id}/status`;
  return apiAction<RouteRoutinePutStatusOutput>({
    method: Methods.PUT,
    body,
    path,
    onSuccess,
    onFailure,
  });
}

export function softDeleteAction(
  { id }: { id: string },
  { onSuccess, onFailure }: ApiActionOptions<void>
) {
  const path = `/routine/${id}`;
  return apiAction<void>({
    method: Methods.DELETE,
    path,
    onSuccess,
    onFailure,
  });
}

export default {
  listAction,
  listSubscribedAction,
  listOwnAction,
  createAction,
  updateFullAction,
  getFullAction,
  updateStatusAction,
  softDeleteAction,

  subscribeRequest,
  unsubscribeRequest
};

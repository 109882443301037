import { z } from 'zod';

export const AmrapOverloadRuleSchema = z.object({
  min: z.number().int().nonnegative(),
  max: z.number().int().nonnegative().nullish(),
  amount: z.number().int().nonnegative()
});

export const WeightSetTypeEnum = z.enum(['no_suggestions', 'tm', 'rpe', '1rm']);

const optionalId = z.number().nullish();
const index = z.number().nonnegative().int();
const restTime = z.number().nonnegative().int();
const repetitions = z.number().nonnegative().min(0).max(999).int();
const time = z.number().nonnegative().min(0).max(999).int();
const amrap = z.boolean().nullish();
const intensityWeightTmPercentage = z.number().nonnegative().min(0).max(999).int();
const intensityWeight1rmPercentage = z.number().nonnegative().min(0).max(999).int();

const baseSet = z.object({
  id: optionalId,
  index,
  amrap,
  rest_time: restTime
});

const baseWeightSet = baseSet.extend({
  id: optionalId,
  index,
  weight_set_type: WeightSetTypeEnum,
  amrap,
  rest_time: restTime
});

export const SetWeightRpe = baseWeightSet.extend({
  intensity_repetitions: repetitions,
  intensity_rpe: z.number().min(6, { message: 'must be at least 6 and not more than 10' }).max(10, { message: "must be at least 6 and not more than 10" }),
  weight_set_type: z.literal('rpe'),
});

export const SetWeightTm = baseWeightSet.extend({
  intensity_repetitions: repetitions,
  intensity_weight_tm_percentage: intensityWeightTmPercentage,
  weight_set_type: z.literal('tm'),
  amrap_overload_rules: z.array(AmrapOverloadRuleSchema).nonempty().nullish()
});

export const SetWeight1Rm = baseWeightSet.extend({
  intensity_repetitions: repetitions,
  intensity_weight_1rm_percentage: intensityWeight1rmPercentage,
  weight_set_type: z.literal('1rm'),
  amrap_overload_rules: z.array(AmrapOverloadRuleSchema).nonempty().nullish(),
});

export const SetWeightNoSuggestion = baseWeightSet.extend({
  intensity_repetitions: repetitions,
  weight_set_type: z.literal('no_suggestions'),
});

export const SetWeight = z.discriminatedUnion('weight_set_type', [SetWeightNoSuggestion, SetWeightTm, SetWeightRpe, SetWeight1Rm]);

export const SetUpdateBodyweightRep = baseSet.extend({
  intensity_repetitions: repetitions,
});

export const SetUpdateBodyweightTime = baseSet.extend({
  intensity_time: time,
});

export const DeloadRuleTM = z.object({
  failed_sessions_trigger_number: z.number().nonnegative().int(),
  tm_deload_percentage: z.number().nonnegative().int(),
  deload_type: z.literal('tm'),
});

export const DeloadRule1RM = z.object({
  failed_sessions_trigger_number:  z.number().nonnegative().int(),
  deload_percentage_1rm: z.number().nonnegative().int(),
  deload_type: z.literal('1rm'),
});

export const DeloadRule = z.discriminatedUnion('deload_type', [DeloadRuleTM, DeloadRule1RM]);

export const OverloadRuleTM = z.object({
  successful_sessions_trigger_number: z.number().nonnegative().int(),
  tm_overload_amount: z.number().nonnegative().int(),
  overload_type: z.literal('tm'),
});

export const OverloadRule1RM = z.object({
  successful_sessions_trigger_number: z.number().nonnegative().int(),
  overload_amount_1rm: z.number().nonnegative().int(),
  overload_type: z.literal('1rm'),
});

export const OverloadRule = z.discriminatedUnion('overload_type', [OverloadRuleTM, OverloadRule1RM]);

export const WorkoutExerciseType = z.enum(['weight', 'bodyweight_time', 'bodyweight_rep']);
const workoutExerciseUpdateBaseSchema = z.object({
  id: optionalId,
  index,
  workout_exercise_type: WorkoutExerciseType,
  exercise_id: z.number().nonnegative(),
});

const WeightWorkoutExerciseUpdateSchema = workoutExerciseUpdateBaseSchema.extend({
  workout_exercise_type: z.literal('weight'),
  intensity_weight_tm_percentage_exercise_id: z.number().nonnegative().nullish(),
  deload_rule: DeloadRule.nullish(),
  overload_rule: OverloadRule.nullish(),
  sets: z.array(SetWeight).nonempty({
    message: 'must include at least 1 set'
  })
});

const BodyweightTimeWorkoutExerciseUpdateSchema = workoutExerciseUpdateBaseSchema.extend({
  workout_exercise_type: z.literal('bodyweight_time'),
  sets: z.array(SetUpdateBodyweightTime).nonempty({
    message: 'must include at least 1 set'
  })
});

const BodyweightRepWorkoutExerciseUpdateSchema = workoutExerciseUpdateBaseSchema.extend({
  workout_exercise_type: z.literal('bodyweight_rep'),
  sets: z.array(SetUpdateBodyweightRep).nonempty({
    message: 'must include at least 1 set'
  })
});

export const WorkoutExerciseUpdateSchema = z.discriminatedUnion('workout_exercise_type', [WeightWorkoutExerciseUpdateSchema, BodyweightTimeWorkoutExerciseUpdateSchema, BodyweightRepWorkoutExerciseUpdateSchema]);

export const WorkoutUpdateSchema = z.object({
  id: optionalId,
  index,
  name: z.string().nonempty(),
  workoutExercises: z.array(WorkoutExerciseUpdateSchema).nonempty({
    message: 'must include at least 1 exercise'
  })
});

export const RoutineCreateSchema = z.object({
  name: z.string().nonempty(),
  description: z.string().nullish().transform(s => s || ''),
  tm_percent_from_1rm: z.number().nonnegative().int(),
  max_weeks: z.number().nonnegative().int(),
  workouts: z.array(WorkoutUpdateSchema).nonempty({
    message: 'must include at least 1 workout'
  })
});

export const RoutineUpdateSchema = RoutineCreateSchema.extend({
  id: z.number().nonnegative().int(),
});

export const RoutineStatusEnum = z.enum(['private', 'public']);
export const RoutineUpdateStatusSchema = z.object({
  status: RoutineStatusEnum
});

export type ZRoutineCreate = z.infer<typeof RoutineCreateSchema>;
export type ZRoutineUpdate = z.infer<typeof RoutineUpdateSchema>;
export type ZRoutineUpdateStatus = z.infer<typeof RoutineUpdateStatusSchema>;
export type ZRoutineStatusEnum = z.infer<typeof RoutineStatusEnum>;

export type ZWorkoutUpdate = z.infer<typeof WorkoutUpdateSchema>;

export type ZWeightWorkoutExerciseSchema = z.infer<typeof WeightWorkoutExerciseUpdateSchema>;
export type ZBodyweightTimeWorkoutExerciseSchema = z.infer<typeof BodyweightTimeWorkoutExerciseUpdateSchema>;
export type ZBodyweightRepWorkoutExerciseSchema = z.infer<typeof BodyweightRepWorkoutExerciseUpdateSchema>;

export type ZWorkoutExerciseUpdate = z.infer<typeof WorkoutExerciseUpdateSchema>;
export type ZWorkoutExerciseType = z.infer<typeof WorkoutExerciseType>;

export type ZDeloadRuleTM = z.infer<typeof DeloadRuleTM>;
export type ZDeloadRule1RM = z.infer<typeof DeloadRule1RM>;
export type ZDeloadRule = z.infer<typeof DeloadRule>;
export type ZOverloadRuleTM = z.infer<typeof OverloadRuleTM>;
export type ZOverloadRule1RM = z.infer<typeof OverloadRule1RM>;
export type ZOverloadRule = z.infer<typeof OverloadRule>;

export type ZWeightSetTypeEnum = z.infer<typeof WeightSetTypeEnum>;
export type ZSetWeight = z.infer<typeof SetWeight>;
export type ZSetWeightRpe = z.infer<typeof SetWeightRpe>;
export type ZSetWeightTm = z.infer<typeof SetWeightTm>;
export type ZSetWeight1Rm = z.infer<typeof SetWeight1Rm>;
export type ZSetWeightNoSuggestion = z.infer<typeof SetWeightNoSuggestion>;
export type ZSetUpdateBodyweightRep = z.infer<typeof SetUpdateBodyweightRep>;
export type ZSetUpdateBodyweightTime = z.infer<typeof SetUpdateBodyweightTime>;

export type ZAmrapOverloadRule = z.infer<typeof AmrapOverloadRuleSchema>;

// ERROR KEYS
export type ZRoutineErrorKeys = keyof ZRoutineUpdate;
export type ZWorkoutErrorKeys = keyof ZWorkoutUpdate;
export type ZWorkoutExerciseErrorKeys = keyof ZWeightWorkoutExerciseSchema | keyof ZBodyweightTimeWorkoutExerciseSchema | keyof ZBodyweightRepWorkoutExerciseSchema;
export type ZOverloadRuleErrorKeys = keyof ZOverloadRuleTM | keyof ZOverloadRule1RM;
export type ZDeloadRuleErrorKeys = keyof ZDeloadRuleTM | keyof ZDeloadRule1RM;
export type ZSetErrorKeys = keyof ZSetWeightRpe | keyof ZSetWeightTm | keyof ZSetWeight1Rm | keyof ZSetWeightNoSuggestion | keyof ZSetUpdateBodyweightRep | keyof ZSetUpdateBodyweightTime;
export type ZAmrapOverloadRulesErrorKeys = keyof ZAmrapOverloadRule;

// // For testing purposes
// // an exhaustive list of all error keys for RoutineUpdateSchema with 0 index for all the arrays
// export const RoutineUpdateSchemaErrorKeys = [
//   'id',
//   'name',
//   'description',
//   'tm_percent_from_1rm',
//   'max_weeks',
//   'workouts',
//   'workouts_0_name',
//   'workouts_0_workoutExercises',
//   'workouts_0_workoutExercises_0_id',
//   'workouts_0_workoutExercises_0_index',
//   'workouts_0_workoutExercises_0_workout_exercise_type',
//   'workouts_0_workoutExercises_0_exercise_id',
//   'workouts_0_workoutExercises_0_workout_exercise_type',
//   'workouts_0_workoutExercises_0_intensity_weight_tm_percentage_exercise_id',
//   'workouts_0_workoutExercises_0_deload_rule',
//   'workouts_0_workoutExercises_0_deload_rule_failed_sessions_trigger_number',
//   'workouts_0_workoutExercises_0_deload_rule_tm_deload_percentage',
//   'workouts_0_workoutExercises_0_deload_rule_deload_type',
//   'workouts_0_workoutExercises_0_overload_rule',
//   'workouts_0_workoutExercises_0_overload_rule_successful_sessions_trigger_number',
//   'workouts_0_workoutExercises_0_overload_rule_tm_overload_amount',
//   'workouts_0_workoutExercises_0_overload_rule_overload_type',
//   'workouts_0_workoutExercises_0_sets',
//   'workouts_0_workoutExercises_0_sets_0_id',
//   'workouts_0_workoutExercises_0_sets_0_index',
//   'workouts_0_workoutExercises_0_sets_0_intensity_repetitions',
//   'workouts_0_workoutExercises_0_sets_0_intensity_time',
//   'workouts_0_workoutExercises_0_sets_0_intensity_rpe',
//   'workouts_0_workoutExercises_0_sets_0_intensity_weight_tm_percentage',
//   'workouts_0_workoutExercises_0_sets_0_intensity_weight_1rm_percentage',
//   'workouts_0_workoutExercises_0_sets_0_weight_set_type',
//   'workouts_0_workoutExercises_0_sets_0_amrap_overload_rules',
//   'workouts_0_workoutExercises_0_sets_0_amrap_overload_rules_0_min',
//   'workouts_0_workoutExercises_0_sets_0_amrap_overload_rules_0_max',
//   'workouts_0_workoutExercises_0_sets_0_amrap_overload_rules_0_amount',
// ] as const;

// export const RoutineErrorKeyMap = RoutineUpdateSchemaErrorKeys.reduce((acc, key) => {
//   acc[key] = key;
//   return acc;
// }, {} as Record<typeof RoutineUpdateSchemaErrorKeys[number], typeof RoutineUpdateSchemaErrorKeys[number]>);

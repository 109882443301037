import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import { Check, Trash, X, List } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { WeightSetType, WeightTmPercentageWorkoutExerciseSetCreateData } from '../../../../../common/types';
import usePublicPrivateExercise from '../../../../lib/hooks/usePublicPrivateExercise';
import useRoutineEditorError from '../../../../lib/hooks/useRoutineEditorError';
import useUnits from '../../../../lib/hooks/useUnits';
import slice from '../../../../lib/slices/routine-editor';
import { RootState } from '../../../../lib/store';
import HardyInputComponent from '../../../form/HardyInput';
import HardyTooltip from '../../../ui/HardyTooltip';
import { getWeightStep } from '../../../weight/Weight';
import AmrapOverloadRules from '../row/AmrapOverloadRules';
import { SetAutoFocusInput } from './SetRowEditorSwitch';


interface SetRowEditorProps {
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  autoFocusElement: SetAutoFocusInput;
}

enum EditableKeys {
  INTENSITY_RPE = 'intensity_rpe',
  INTENSITY_WEIGHT_1RM_PERCENTAGE = 'intensity_weight_1rm_percentage',
  INTENSITY_WEIGHT_TM_PERCENTAGE = 'intensity_weight_tm_percentage',
  INTENSITY_REPETITIONS = 'intensity_repetitions',
  WEIGHT_SET_TYPE = 'weight_set_type',
  AMRAP = 'amrap',
  REST_TIME = 'rest_time'
}

function IntensityColumn({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps) {
  const set = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex]);

  const childProps = {
    workoutIndex, exerciseIndex, setIndex, autoFocusElement
  };

  if (set.weight_set_type === WeightSetType._1RM) {
    return (<_1RmPercentageSetCol {...childProps} />);
  }

  if (set.weight_set_type === WeightSetType.RPE) {
    return (<RpeSetCol {...childProps} />);
  }

  if (set.weight_set_type === WeightSetType.TM) {
    return (<TmPercentageSetCol {...childProps} />);
  }

  if (set.weight_set_type === WeightSetType.NO_SUGGESTIONS) {
    return (<NoSuggestionsSetCol />);
  }

  return null;
}

function NoSuggestionsSetCol() {
  return (
    <div>
      <span className="mr-3 text-muted">-</span>
    </div>
  );
}

function RpeSetCol({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps) {
  const dispatch = useDispatch();
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}`;
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const set = workoutExercise.sets[setIndex];

  return (
    <div className={set.intensity_rpe && set.intensity_rpe > 10 ? 'custom-input-group custom-input-group--intensity2' : 'custom-input-group custom-input-group--intensity1'}>
      <HardyInputComponent
        name={`${validationPrefix}_${EditableKeys.INTENSITY_RPE}`}
        errors={errors}
        hideError={true}
        formCtrl={{
          type: 'number',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value ? Number(e.target.value) : undefined;
            const updateData: Partial<WeightTmPercentageWorkoutExerciseSetCreateData> = {
              [EditableKeys.INTENSITY_RPE]: value
            };
            dispatch(slice.actions.updateSet({
              workoutIndex,
              exerciseIndex,
              setIndex,
              data: updateData
            }));
          },
          value: String(set.intensity_rpe || ''),
          autoFocus: autoFocusElement === SetAutoFocusInput.RPE,
          selectOnFocus: autoFocusElement === SetAutoFocusInput.RPE,
          min: 6,
          max: 10
        }}
      />
    </div>
  );
}

function _1RmPercentageSetCol({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps) {
  const dispatch = useDispatch();
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}`;
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const set = workoutExercise.sets[setIndex];

  return (
    <div className={set.intensity_weight_1rm_percentage && set.intensity_weight_1rm_percentage > 100 ? 'custom-input-group custom-input-group--intensity2' : 'custom-input-group custom-input-group--intensity1'}>
      <HardyInputComponent
        name={`${validationPrefix}_${EditableKeys.INTENSITY_WEIGHT_1RM_PERCENTAGE}`}
        errors={errors}
        hideError={true}
        formCtrl={{
          type: 'number',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value ? Number(e.target.value) : undefined;
            const updateData: Partial<WeightTmPercentageWorkoutExerciseSetCreateData> = {
              [EditableKeys.INTENSITY_WEIGHT_1RM_PERCENTAGE]: value
            };
            dispatch(slice.actions.updateSet({
              workoutIndex,
              exerciseIndex,
              setIndex,
              data: updateData
            }));
          },
          value: String(set.intensity_weight_1rm_percentage || ''),
          autoFocus: autoFocusElement === SetAutoFocusInput._1RM_PERCENTAGE,
          selectOnFocus: autoFocusElement === SetAutoFocusInput._1RM_PERCENTAGE,
          min: 0,
          max: 100
        }}
      />
      <span>
        %
      </span>
    </div>
  );
}

function TmPercentageSetCol({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps) {
  const dispatch = useDispatch();
  const errors = useRoutineEditorError();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}`;
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const set = workoutExercise.sets[setIndex];

  return (
    <div className={set.intensity_weight_tm_percentage && set.intensity_weight_tm_percentage > 110 ? 'custom-input-group custom-input-group--intensity2' : 'custom-input-group custom-input-group--intensity1'}>
      <HardyInputComponent
        name={`${validationPrefix}_${EditableKeys.INTENSITY_WEIGHT_TM_PERCENTAGE}`}
        errors={errors}
        hideError={true}
        formCtrl={{
          type: 'number',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value ? Number(e.target.value) : undefined;
            const updateData: Partial<WeightTmPercentageWorkoutExerciseSetCreateData> = {
              [EditableKeys.INTENSITY_WEIGHT_TM_PERCENTAGE]: value
            };
            if (!value && set.amrap_overload_rules) {
              updateData.amrap_overload_rules = undefined;
            }
            dispatch(slice.actions.updateSet({
              workoutIndex,
              exerciseIndex,
              setIndex,
              data: updateData
            }));
          },
          value: String(set.intensity_weight_tm_percentage ?? ''),
          autoFocus: autoFocusElement === SetAutoFocusInput.TM_PERCENTAGE,
          selectOnFocus: autoFocusElement === SetAutoFocusInput.TM_PERCENTAGE,
          min: 0,
          max: 999
        }}
      />
      <span>
        %
      </span>
    </div>
  );
}

const WeightSetRowEdit = ({ workoutIndex, exerciseIndex, setIndex, autoFocusElement }: SetRowEditorProps, ref: any) => {
  const dispatch = useDispatch();
  const errors = useRoutineEditorError();
  const units = useUnits();
  const validationPrefix = `workouts_${workoutIndex}_workoutExercises_${exerciseIndex}_sets_${setIndex}`;
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);
  const set = workoutExercise.sets[setIndex];

  function onSetChangeNumber(key: string) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(slice.actions.updateSet({
        workoutIndex,
        exerciseIndex,
        setIndex,
        data: {
          [key]: e.target.value ? Number(e.target.value): undefined
        }
      }));
    };
  }

  function onSetAmrapChange() {
    return (e: React.ChangeEvent<HTMLInputElement>) => {

      const amrap = e.target.checked;
      const newData = {
        amrap_overload_rules: set.amrap_overload_rules,
        amrap
      };

      if (!amrap) {
        newData.amrap_overload_rules = undefined;
      }

      dispatch(slice.actions.updateSet({
        workoutIndex,
        exerciseIndex,
        setIndex,
        data: newData
      }));
    };
  }

  const childProps = { workoutIndex, exerciseIndex, setIndex, autoFocusElement };
  return (
    <div className="routine-woe-sets-row-holder routine-woe-sets-row-holder--edit" ref={ref}>
      <div className="woe-cus-row routine-woe-sets-row">
        <div className="woe-cus-col-1">
          <List className="set-number-drag"/>
        </div>
        <div className="woe-cus-col-2 text-right">
          <IntensityColumn {...childProps} />
        </div>
        <div className="woe-cus-col-4">
          <div className={set.amrap && !!set.amrap_overload_rules ? 'po-highlight ml-0' : ''}>
            <div className={set.amrap ? (set.amrap_overload_rules ? 'amrap-highlight' : 'amrap-highlight ml-2') : ''}>
              <div className="woe-cus-col--reps-holder-1 text-right">
                <div className={set.amrap ? 'custom-input-group custom-input-group--reps-amrap' : 'custom-input-group custom-input-group--reps'}>
                  <HardyInputComponent
                    hideError={true}
                    name={`${validationPrefix}_${EditableKeys.INTENSITY_REPETITIONS}`}
                    errors={errors}
                    formCtrl={{
                      type: 'number',
                      onChange: onSetChangeNumber(EditableKeys.INTENSITY_REPETITIONS),
                      value: String(set.intensity_repetitions),
                      autoFocus: autoFocusElement === SetAutoFocusInput.REPETITIONS,
                      selectOnFocus: autoFocusElement === SetAutoFocusInput.REPETITIONS,
                      min: 0,
                      max: 99
                    }}
                  />
                  {
                    set.amrap ? (
                      <span>
                        +
                      </span>
                    ) : null
                  }
                </div>
              </div>
              <div className="woe-cus-col--reps-holder-2">
                <HardyTooltip message={set.amrap ? 'As Many Reps As Possible enabled' : 'Click to enable As Many Reps As Possible'}>
                  <label className="btn-tag btn-tag--amrap ml-2">
                    <input
                      type="checkbox"
                      id={`${validationPrefix}_${EditableKeys.AMRAP}`}
                      checked={set.amrap}
                      onChange={onSetAmrapChange()}
                    />
                    <div className="amrap-tag"><Check className="icon-check" /><X className="icon-x" /> amrap</div>
                  </label>
                </HardyTooltip>
              </div>
            </div>
            {set.amrap && (set.weight_set_type === WeightSetType.TM || set.weight_set_type === WeightSetType._1RM) ? (
              <div className="woe-cus-col--reps-holder-3">
                <HardyTooltip message='Suggested Progressive Overload rules based on this set reps'>
                  <label className="btn-tag btn-tag--po ml-2">
                    <input
                      type="checkbox"
                      id={validationPrefix}
                      checked={!!set.amrap_overload_rules && !!set.amrap_overload_rules.length}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          dispatch(slice.actions.updateSet({
                            workoutIndex,
                            exerciseIndex,
                            setIndex,
                            data: {
                              amrap_overload_rules: [
                                {
                                  min: 1,
                                  max: 2,
                                  amount: getWeightStep(units, targetExercise?.type)
                                },
                                {
                                  min: 3,
                                  max: undefined,
                                  amount: 2 * getWeightStep(units, targetExercise?.type)
                                }
                              ]
                            }
                          }));
                        } else {
                          dispatch(slice.actions.updateSet({
                            workoutIndex,
                            exerciseIndex,
                            setIndex,
                            data: { amrap_overload_rules: undefined }
                          }));
                        }
                      }}
                    />
                    <div className="po-tag"><Check className="icon-check" /><X className="icon-x" /> <span>Prog. </span>Overload</div>
                  </label>
                </HardyTooltip>
              </div>
            ) : null}
          </div>
        </div>
        <div className="woe-cus-col-5 text-right">
          <div className="custom-input-group custom-input-group--rest">
            <HardyInputComponent
              hideError={true}
              name={`${validationPrefix}_${EditableKeys.REST_TIME}`}
              errors={errors} formCtrl={{
                type: 'number',
                autoFocus: autoFocusElement === SetAutoFocusInput.REST_TIME,
                selectOnFocus: autoFocusElement === SetAutoFocusInput.REST_TIME,
                onChange: onSetChangeNumber(EditableKeys.REST_TIME),
                value: String(set.rest_time),
                min: 0
              }}
            />
            <span>
              sec
            </span>
          </div>
        </div>
        <div className="woe-cus-col-7 text-right">
          <Button className="btn-icon btn-delete" variant="outline-secondary" onClick={() => dispatch(
            slice.actions.removeSet({
              workoutIndex,
              exerciseIndex,
              setIndex
            })
          )}>
            <Trash />
          </Button>
        </div>
      </div>
      {set.amrap && !!set.amrap_overload_rules ? (
        <div className="woe-cus-row routine-woe-sets-row routine-woe-sets-row--amrap">
          <div className="woe-cus-col-4 woe-cus-col-4--amrap">
            <AmrapOverloadRules workoutIndex={workoutIndex} exerciseIndex={exerciseIndex} setIndex={setIndex} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default forwardRef(WeightSetRowEdit);

import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import { formatWeight } from '../../../weight/Weight';
import { Pencil } from 'react-bootstrap-icons';
import { RootState } from '../../../../lib/store';
import usePublicPrivateExercise from '../../../../lib/hooks/usePublicPrivateExercise';
import useUnits from '../../../../lib/hooks/useUnits';
import { ExerciseType, WeightSetType } from '../../../../../common/types';

interface AmrapOverloadRule {
  onClick: () => void;
  workoutIndex: number;
  exerciseIndex: number;
  setIndex: number;
  amrapRuleIndex: number;
}

function AmrapOverloadRule ({ workoutIndex, exerciseIndex, setIndex, amrapRuleIndex, onClick }: AmrapOverloadRule) {
  const units = useUnits();
  const workoutExercise = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex]);
  const workoutExerciseSet = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex]);
  const rule = useSelector((state: RootState) => state.routineEditorState.createData.workouts[workoutIndex].workoutExercises[exerciseIndex].sets[setIndex].amrap_overload_rules?.[amrapRuleIndex]);
  const exercise = usePublicPrivateExercise(workoutExercise.exercise_id || 1);
  const targetExercise = usePublicPrivateExercise(workoutExercise.intensity_weight_tm_percentage_exercise_id || 1);

  const tmRuleValid = targetExercise && workoutExerciseSet.weight_set_type === WeightSetType.TM;
  const _1RmRuleValid = exercise && workoutExerciseSet.weight_set_type === WeightSetType._1RM;
  const type = workoutExerciseSet.weight_set_type === WeightSetType.TM ? 'TM' : '1RM';

  if (!rule || (!tmRuleValid && !_1RmRuleValid)) {
    return null;
  }

  const exerciseType = (workoutExerciseSet.weight_set_type === WeightSetType.TM ? targetExercise?.type : exercise?.type) as ExerciseType;
  return (
    <Row className="routine-woe-sets-row routine-woe-sets-row--amrap routine-woe-sets-row--amrapclickable align-items-center" onClick={onClick}>
      <Col xs={11} className="pl-0 pr-0">
        <p className="p-0 mb-0">
          Extra <b>{rule.min}{rule.max ? `-${rule.max}`: '+'}</b> reps, increase {type} by <b>{formatWeight(rule.amount, exerciseType, {
            units: units,
            roundToUsableWeight: true,
            valueOnly: false
          })}</b>
        </p>
      </Col>
      <Col xs={1} className="text-right pl-0 pr-0">
        <Button className="btn-icon" variant="outline-secondary">
          <Pencil />
        </Button>
      </Col>
    </Row>
  );
}

export default AmrapOverloadRule;

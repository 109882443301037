import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

import RegistrationFlow from '../register/RegistrationFlow';
import socialMediaPreviewImg from '../../assets/hardy-screenshot.jpg';
import { publicUrl } from '../../lib/config';
const socialMediaFullUrl = process.env.NODE_ENV === 'production' ? `${publicUrl}${socialMediaPreviewImg}`: socialMediaPreviewImg;

function RegisterPageComponent () {
  return (
    <>
      <Helmet titleTemplate="Hardy - %s">
        <title>Register</title>
        <meta name={'description'} content={'Free platform for creating, discovering, and tracking workout routines with complex progression rules'} />
        <meta property="og:title" content="Hardy - Register" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hardy.app/register" />
        <meta property="og:image" content={socialMediaFullUrl} />
        <meta property="og:description" content="Create, discover, and track workout routines with complex progression rules" />
      </Helmet>
      
      <Container className="pt-4">
        <Row>
          <Col lg={{ span: 4, offset: 4 }}>
            <h1 className="text-center mt-5 caps-condensed">Register</h1>
            <div className="content-area mb-4">
              <RegistrationFlow />
              <Row>
                {/*<Col><Link to={'/forgot-password'}>Forgot password?</Link></Col>*/}
              </Row>
              
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RegisterPageComponent;
